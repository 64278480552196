import gql from "graphql-tag";

export const CREATE_CONVOCATION_ACTION = gql`
  mutation MyMutation(
    $companyId: Int!
    $userCompanyId: Int!
    $title: String!
    $description: String!
    $expirationDate: date!
    $addressId: Int!
    $selectedEmployees: jsonb!
    $selectedWorkdays: jsonb!
    $journeyConfigParams: jsonb!
    $pointRegisterRadius: Int
    $extraPointWorkplaces: jsonb!
  ) {
    create_convocation(
      companyId: $companyId
      userCompanyId: $userCompanyId
      title: $title
      description: $description
      expirationDate: $expirationDate
      addressId: $addressId
      selectedEmployees: $selectedEmployees
      selectedWorkdays: $selectedWorkdays
      journeyConfigParams: $journeyConfigParams
      pointRegisterRadius: $pointRegisterRadius
      extraPointWorkplaces: $extraPointWorkplaces
    ) {
      id
    }
  }
`;

export const UPDATE_CONVOCATION_GROUP_SOLICITATION_ACTION = gql`
  mutation MyMutation(
    $convocation: convocation_custom!
    $convocation_group: convocation_group_custom!
    $convocation_group_id: Int!
    $extraPointWorkplaces: jsonb
    $modificationReason: String!
    $workdays: [workday_custom!]!
  ) {
    update_convocation_request(
      convocation: $convocation
      convocation_group: $convocation_group
      convocation_group_id: $convocation_group_id
      modificationReason: $modificationReason
      workdays: $workdays
      extraPointWorkplaces: $extraPointWorkplaces
    ) {
      id
      message
    }
  }
`;

export const UPDATE_CONVOCATION_TITLE = gql`
  mutation UpdateConvocationTitle($id: Int!, $title: String!, $body: String!) {
    update_convocation_group_by_pk(
      pk_columns: { id: $id }
      _set: { title: $title, body: $body }
    ) {
      id
    }
    update_convocation(
      where: { convocation_group_id: { _eq: $id } }
      _set: { title: $title, body: $body }
    ) {
      affected_rows
    }
  }
`;

export const CHANGE_CONVOCATION_ACTION = gql`
  mutation ChangeConvocationAction(
    $old_convocation_group_id: Int!
    $new_convocation_group: convocation_group_custom!
    $new_workdays: [workday_custom!]!
    $new_extraPointWorkplaces: [extra_porint_register_workplaces!]
    $change_reason_id: Int!
    $expiration_date: date!
    $journey_config_params: jsonb!
  ) {
    change_convocation(
      old_convocation_group_id: $old_convocation_group_id
      new_convocation_group: $new_convocation_group
      new_workdays: $new_workdays
      new_extraPointWorkplaces: $new_extraPointWorkplaces
      change_reason_id: $change_reason_id
      expiration_date: $expiration_date
      journey_config_params: $journey_config_params
    ) {
      id
      message
    }
  }
`;

export const CHANGE_CONVOCATION_ACTION_BASIC = gql`
  mutation ChangeConvocationBasicAction(
    $body: String
    $extraPointWorkplaces: [extra_points_register_workplaces!]!
    $convocation_group_id: Int!
    $title: String
    $point_register_radius: Int!
  ) {
    change_convocation_basic(
      body: $body
      extraPointWorkplaces: $extraPointWorkplaces
      convocation_group_id: $convocation_group_id
      title: $title
      point_register_radius: $point_register_radius
    ) {
      id
      message
    }
  }
`;

export const LOAD_ALL_CONVOCATIONS = gql`
  query contracts($company_id: Int!) {
    contract(where: { company_id: { _eq: $company_id } }) {
      id
      status
      employee {
        id
        user {
          avatar
          name
          id
        }
      }
      occupation_contracts {
        id
        occupation {
          cbo
          id
          name
        }
      }
    }
  }
`;
export const LOAD_PENDING_CONVOCATION_BY_COMPANY = gql`
  query pending_convocation($company_id: Int!, $status: String!) {
    convocation_aggregate(
      where: { status: { _eq: $status }, company_id: { _eq: $company_id } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

export const LOAD_LAST_ACTIVE_WORKDAY = gql`
  query($company_id: Int!, $user_id: uuid!) {
    convocation(
      where: {
        _and: {
          employee: { user: { id: { _eq: $user_id } } }
          status: { _in: ["Aceita", "Enviada"] }
          company_id: { _eq: $company_id }
        }
      }
    ) {
      workdays_aggregate {
        aggregate {
          max {
            date
          }
        }
      }
      id
      status
    }
  }
`;

export const VALIDATE_POINT_REGISTER_BY_CONVOCATION = gql`
  query validate($user_id: uuid!, $company_id: Int!, $date: date!) {
    convocation(
      where: {
        _and: {
          employee: { id_user: { _eq: $user_id } }
          company_id: { _eq: $company_id }
          status: { _eq: "Aceita" }
          workdays: { date: { _eq: $date } }
        }
      }
    ) {
      id
      employee_id
      company_id
      status
      workdays {
        id
        start_hour
        end_hour
      }
    }
  }
`;

export const LOAD_LAST_CONVOCATIONS_BY_COMPANY = gql`
  query LoadConvocationsByCompany(
    $offset: Int!
    $limit: Int!
    $order_by: convocation_group_order_by!
    $where: convocation_group_bool_exp
  ) {
    convocation_group(
      offset: $offset
      limit: $limit
      order_by: [$order_by]
      where: $where
    ) {
      id
      title
      date_time_send
      user_company {
        id
        user {
          id
          name
          avatar
          __typename
        }
        __typename
      }
      total: convocations_aggregate {
        aggregate {
          count(distinct: true, columns: employee_id)
          without_distinct: count
        }
        __typename
      }
      accepted: convocations_aggregate(where: { status: { _eq: "Aceita" } }) {
        aggregate {
          count(distinct: true, columns: employee_id)
          __typename
        }
        __typename
      }
      rejected: convocations_aggregate(where: { status: { _eq: "Recusada" } }) {
        aggregate {
          count(distinct: true, columns: employee_id)
          __typename
        }
        __typename
      }
      cancelled: convocations_aggregate(
        where: {
          status: {
            _in: ["Cancelada pelo Profissional", "Cancelada pela Empresa"]
          }
        }
      ) {
        aggregate {
          count(distinct: true, columns: employee_id)
          without_distinct: count
        }
        __typename
      }
      convocations(
        distinct_on: employee_id
        order_by: { employee_id: asc, date_time_send: desc }
      ) {
        id
        date_time_send
        status
        employee_id
        convocation_absents {
          id
          __typename
        }
        workdays(order_by: { date: asc }) {
          id
          date
          start_hour
          end_hour
          __typename
        }
        workplace {
          id
          name
          __typename
        }
        employee {
          id
          user {
            id
            name
            avatar
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    convocation_group_aggregate(where: $where) {
      aggregate {
        count
        __typename
      }
      __typename
    }
  }
`;

export const GET_TOTAL_WORKDAYS_COUNT = gql`
  query getTotalConvocationCount(
    $company_id: Int!
    $search: String
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    convocation_group_aggregate(
      where: {
        company_id: { _eq: $company_id }
        date_time_send: { _gte: $start_date, _lte: $end_date }
        convocations: { status: { _eq: "Aceita" } }
        _or: [
          { title: { _ilike: $search } }
          { workplace: { name: { _ilike: $search } } }
          {
            convocations: { employee: { user: { name: { _ilike: $search } } } }
          }
        ]
      }
      order_by: { date_time_send: desc }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TOTAL_CONVOCATIONS_COUNT = gql`
  query getTotalConvocationCount(
    $company_id: Int!
    $search: String
    $start_date: timestamptz
    $end_date: timestamptz
    $status: [String!]
  ) {
    convocation_group_aggregate(
      where: {
        company_id: { _eq: $company_id }
        date_time_send: { _gte: $start_date, _lte: $end_date }
        _or: [
          { title: { _ilike: $search } }
          { workplace: { name: { _ilike: $search } } }
          {
            convocations: { employee: { user: { name: { _ilike: $search } } } }
          }
        ]
        convocations: { status: { _in: $status } }
      }
      order_by: { date_time_send: desc }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const LOAD_CONVOCATION_BY_COMPANY = gql`
  query LoadConvocationsByCompany(
    $company_id: Int!
    $start_date: timestamptz
    $end_date: timestamptz
    $limit: Int!
    $offset: Int!
    $order_by: convocation_group_order_by!
    $filters: convocation_bool_exp
  ) {
    company(where: { id: { _eq: $company_id } }) {
      convocation_groups(
        offset: $offset
        limit: $limit
        order_by: [$order_by]
        where: { convocations: $filters }
      ) {
        id
        title
        date_time_send
        total: convocations_aggregate {
          aggregate {
            count(distinct: true, columns: employee_id)
          }
        }
        accepted: convocations_aggregate(where: { status: { _eq: "Aceita" } }) {
          aggregate {
            count(distinct: true, columns: employee_id)
          }
        }
        rejected: convocations_aggregate(
          where: { status: { _eq: "Recusada" } }
        ) {
          aggregate {
            count(distinct: true, columns: employee_id)
          }
        }
        convocations {
          id
          date_time_send
          status
          employee_id
          convocation_absents {
            id
          }
          workdays(order_by: { date: asc }) {
            id
            date
            start_hour
            end_hour
          }
          workplace {
            id
            name
          }
          employee {
            id
            user {
              id
              name
              avatar
            }
            point_records(
              where: { date_time: { _gte: $start_date, _lte: $end_date } }
            ) {
              id
              date_time
            }
          }
        }
      }
      convocation_groups_aggregate(where: { convocations: $filters }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const COUNT_WORKING = gql`
  subscription countWorking(
    $company_id: Int!
    $employees: [Int!]!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    point_record_aggregate(
      where: {
        company_id: { _eq: $company_id }
        employee_id: { _in: $employees }
        date_time: { _lte: $endDate, _gte: $startDate }
      }
    ) {
      aggregate {
        count(distinct: true, columns: employee_id)
      }
    }
  }
`;

export const LOAD_WORKDAYS_BY_CONVOCATION = gql`
  query($convocation_id: Int!) {
    workday(where: { convocation_id: { _eq: $convocation_id } }) {
      id
      date
      start_hour
      start_interval
      end_interval
      end_hour
    }
  }
`;

export const GET_CONVOCATION_GROUP = gql`
  subscription GetConvocationGroup(
    $convocation_group_id: Int!
    $company_id: Int!
    $status: String
  ) {
    convocation_group(
      where: {
        id: { _eq: $convocation_group_id }
        company_id: { _eq: $company_id }
      }
    ) {
      id
      title
      body
      date_time_send
      company {
        id
        cnpj
        business_name
        contracts(
          where: {
            _or: [
              { status: { _eq: "Ativo" } }
              { status: { _eq: "Encerrado" } }
            ]
          }
        ) {
          id
          employee_id
          occupation_contracts(where: { next_id: { _eq: null } }) {
            id
            next_id
            occupation {
              id
              name
              cbo
            }
          }
        }
      }
      workplace {
        id
        name
        point_register_radius
        address {
          id
          city {
            id
            name
            state {
              id
              sigla
            }
          }
          neighborhood
          place
          number
          complement
          geographic_coordinate
        }
      }
      convocations {
        convocation_logs {
          id
          user_id
          event
          datetime
        }
        employee_id
        id
        status
        date_time_responde
        date_time_expiration
        date_time_send
        journey_config_params
        employee {
          id
          occupation
          user {
            id
            cpf_cnpj
            avatar
            name
          }
          contracts {
            id
            occupation_contracts(where: { next_id: { _eq: null } }) {
              id
              occupation {
                id
                name
                cbo
                occupation_additionals {
                  description
                  occupation_id
                  percentage
                  type
                  id
                }
              }
              hour_value
              active
            }
            contract_dependents {
              name
              irrf
              id
              family_salary
              contract_id
              birthday
            }
          }
        }
        workdays(order_by: { date: asc }) {
          id
          date
          start_hour
          end_hour
          start_interval
          end_interval
        }
      }
    }
  }
`;

// backup da convocation_group abaixo

/*export const GET_CONVOCATION_GROUP = gql`
  subscription GetConvocationGroup(
    $convocation_group_id: Int!
    $company_id: Int!
  ) {
    convocation_group(where: { 
      id: { _eq: $convocation_group_id },
      company_id: { _eq: $company_id }
    
    }) {
      id
      title
      body
      date_time_send
      company {
        id
        cnpj
        business_name
        contracts(where: { _or: [{ status: { _eq: "Ativo" } }, { status: { _eq: "Encerrado" } }] }) {
          id
          employee_id
          occupation_contracts(where: { next_id: { _eq: null } }) {
            id
            next_id
            occupation {
              id
              name
              cbo
            }
          }
        }
      }
      workplace {
        id
        name
        point_register_radius
        address {
          id
          city {
            id
            name
            state {
              id
              sigla
            }
          }
          neighborhood
          place
          number
          complement
          geographic_coordinate
        }
      }
      convocations {
        employee_id
        id
        status
        date_time_responde
        date_time_expiration
        date_time_send
        journey_config_params
        employee {
          id
          occupation
          user {
            id
            cpf_cnpj
            avatar
            name
          }
          contracts {
            id
            occupation_contracts(where: { next_id: { _eq: null } }) {
              id
              occupation {
                id
                name
                cbo
              }
            }
          }
        }
        workdays(order_by: { date: asc }) {
          id
          date
          start_hour
          end_hour
          start_interval
          end_interval
        }
      }
    }
  }
`;*/

export const GET_EMPLOYEES_BY_CONVOCATION_GROUP = gql`
  subscription GetConvocationGroup($convocation_group_id: Int!) {
    convocation_group(where: { id: { _eq: $convocation_group_id } }) {
      id
      convocations(where: { status: { _eq: "Aceita" } }) {
        id
        employee {
          id
          occupation
          user {
            avatar
            name
            id
          }
        }
      }
    }
  }
`;

export const GET_CONVOCATION_GROUP_WORKDAYS = gql`
  query GetConvocationWorkdays($convocation_group_id: Int!) {
    convocation_group(where: { id: { _eq: $convocation_group_id } }) {
      convocations(limit: 1) {
        id
        journey_config_params
        workdays(order_by: { date: asc }) {
          id
          date
          end_hour
          end_interval
          start_hour
          start_interval
          convocation {
            id
            workplace {
              id
              address {
                id
                city {
                  id
                  ibge
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CONVOCATION_GROUP_EMPLOYEE_POINTS = gql`
  subscription GetConvocationEmployeePoints(
    $convocation_group_id: Int!
    $company_id: Int!
    $start_date: timestamptz!
    $end_date: timestamptz!
    $employee: Int
    $convocation_start_date: date
  ) {
    convocation_group(where: { id: { _eq: $convocation_group_id } }) {
      id
      convocations(
        where: { status: { _eq: "Aceita" }, employee_id: { _eq: $employee } }
      ) {
        id
        date_time_send
        employee {
          id
          occupation
          point_records(
            where: {
              date_time: { _lte: $end_date, _gte: $start_date }
              employee_id: { _eq: $employee }
              company_id: { _eq: $company_id }
            }
            order_by: { date_time: asc }
          ) {
            id
            employee_id
            date_time
            image
            is_preset
          }
          user {
            id
            name
            avatar
          }
          contracts(
            where: {
              company_id: { _eq: $company_id }
              start_date: { _lte: $convocation_start_date }
              _or: [
                { end_date: { _gt: $convocation_start_date } }
                { end_date: { _is_null: true } }
              ]
              status: { _nin: ["Pendente", "Recusado", "Cancelado"] }
            }
          ) {
            id
            start_date
            contract_dependents {
              id
              birthday
              name
              irrf
              family_salary
            }
            occupation_contracts(where: { active: { _eq: true } }) {
              id
              hour_value
              occupation {
                id
                name
                cbo
                occupation_additionals(order_by: { id: desc }) {
                  id
                  description
                  company {
                    id
                    salary_additional {
                      id
                      percentage_extra_hour
                      percentage_night_additional
                    }
                  }
                  type
                  percentage
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CONVOCATIONS = gql`
  subscription GetConvocationGroup($convocation_group_id: Int!) {
    convocation_group(where: { id: { _eq: $convocation_group_id } }) {
      id
      title
      body
      company_id
      worplace_id
      date_time_send
      company {
        id
        cnpj
        business_name
        contracts(
          where: {
            _or: [
              { status: { _eq: "Ativo" } }
              { status: { _eq: "Encerrado" } }
            ]
          }
        ) {
          id
          employee_id
          occupation_contracts(where: { next_id: { _eq: null } }) {
            id
            next_id
            occupation {
              id
              name
              cbo
            }
          }
        }
      }
      workplace {
        id
        name
        address {
          id
          zipcode
          place
          neighborhood
          complement
          number
          city {
            id
            name
            state {
              id
              sigla
            }
          }
        }
      }
      logs: convocation_logs(order_by: { datetime: desc }) {
        id
        user_id
        event
        datetime
        convocation_id
        convocation_group_id
      }
      convocations(order_by: { id: asc }) {
        cancel_by_company_viewed
        employee_id
        id
        status
        date_time_responde
        date_time_expiration
        date_time_send
        journey_config_params
        employee {
          id
          occupation
          user {
            id
            cpf_cnpj
            avatar
            name
          }
          contracts {
            id
            occupation_contracts(where: { next_id: { _eq: null } }) {
              id
              occupation {
                id
                name
                cbo
              }
            }
          }
        }
      }
      days: convocations(limit: 1) {
        workdays {
          id
          date
          start_hour
          end_hour
          start_interval
          end_interval
        }
      }
    }
  }
`;

export const GET_CONVOCATION_PUSH_MESSAGE_STATUS = gql`
  subscription GetConvocationPushMessages($convocationObj: jsonb!) {
    push_message(
      where: {
        action_data: { _contains: $convocationObj }
        action_push_message: { action: { _eq: "convocation" } }
      }
    ) {
      push_message_users(order_by: { id: desc }) {
        id
        push_message_id
        read
        user_id
      }
    }
  }
`;

export const GET_CONVOCATION_INFOS = gql`
  subscription getConvocationInfos($convocation_group_id: Int!) {
    convocation_group(where: { id: { _eq: $convocation_group_id } }) {
      total: convocations_aggregate {
        aggregate {
          count(distinct: true, columns: employee_id)
        }
      }
      accepted: convocations_aggregate(where: { status: { _eq: "Aceita" } }) {
        aggregate {
          count(distinct: true, columns: employee_id)
        }
      }
      first_date: convocations_aggregate {
        aggregate {
          min {
            date_time_send
          }
        }
      }
      convocations(where: { status: { _nilike: "Cancelada" } }) {
        id
      }
    }
  }
`;

export const UPDATE_CONVOCATION_GROUP = gql`
  mutation updateConvocationGroup(
    $convocation_group_id: Int!
    $convocationGroup: convocation_group_set_input!
  ) {
    update_convocation_group(
      where: { id: { _eq: $convocation_group_id } }
      _set: $convocationGroup
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_CONVOCATION = gql`
  mutation updateConvocation(
    $convocation_id: Int!
    $convocation: convocation_set_input!
  ) {
    update_convocation(
      where: { id: { _eq: $convocation_id } }
      _set: $convocation
    ) {
      affected_rows
    }
  }
`;

export const LOAD_CONVOCATIONS_REPORT_DATA = gql`
  query loadConvocationsReportData(
    $status: [String!]
    $contract_status: [String!]
    $employees_cpfs: [String!]
    $user_company_login: String
    $workplaces_id: [Int!]
    $start_date: timestamptz
    $end_date: timestamptz
    $company_id: Int!
    $convocation_start_date: date
  ) {
    employee(
      where: {
        convocations: { company_id: { _eq: $company_id } }
        user: { cpf_cnpj: { _in: $employees_cpfs } }
      }
    ) {
      id
      user {
        cpf_cnpj
        id
        name
      }

      contracts(
        where: {
          company_id: { _eq: $company_id }
          status: {
            _nin: ["Pendente", "Recusado", "Cancelado"]
            _in: $contract_status
          }
        }
      ) {
        id
        status
        start_date
        end_date
        occupation_contracts(where: { active: { _eq: true } }) {
          id
          hour_value
          occupation {
            id
            name
            cbo
          }
        }

        convocations(
          order_by: { date_time_send: asc }
          where: {
            status: { _in: $status }
            workplace: { id: { _in: $workplaces_id } }
            date_time_send: { _gte: $start_date, _lte: $end_date }
          }
        ) {
          id
          title
          status
          date_time_send
          date_time_responde
          date_time_expiration
          workdays(order_by: { date: asc }) {
            id
            date
            start_hour
            end_hour
          }
          workplace {
            id
            name
          }
        }
      }
    }
  }
`;

export const RESEND_CONVOCATION_INVITE = gql`
  mutation resendConvocationInvite(
    $convocation_id: Int!
    $user_company_id: Int!
    $expiration_date: date!
  ) {
    resend_convocation_invite(
      convocation_id: $convocation_id
      user_company_id: $user_company_id
      expiration_date: $expiration_date
    ) {
      id
    }
  }
`;

export const ADD_CONVOCATION_INVITE = gql`
  mutation addConvocationInvite(
    $convocation_group_id: Int!
    $user_company_id: Int!
    $employee_id: Int!
    $contract_id: Int!
    $journey_config_params: jsonb!
    $userId: uuid!
    $expiration_date: date!
  ) {
    add_convocation_invite(
      convocation_group_id: $convocation_group_id
      user_company_id: $user_company_id
      employee_id: $employee_id
      contract_id: $contract_id
      journey_config_params: $journey_config_params
      userId: $userId
      expiration_date: $expiration_date
    ) {
      id
    }
  }
`;

// export const GET_CONVOCATION_CONFIGS = gql`
//   query getConvocationConfigs(
//     $company_id: Int!
//   ){
//     company_config(where: {company_id: {_eq: $company_id}, type: {_eq: JOURNEY}}) {
//       id
//       type
//       start_date
//       created_at
//       data
//     }

//     default_configs(where: {type: {_eq: "JOURNEY"}}) {
//       id
//       data
//       created_at
//       updated_at
//     }
//   }
// `
export const GET_CONVOCATION_CONFIGS = gql`
  query getJourneyConfig($company_id: Int!, $now: date!) {
    company_config(
      where: {
        company_id: { _eq: $company_id }
        type: { _eq: JOURNEY }
        start_date: { _lte: $now }
      }
    ) {
      id
      type
      start_date
      data
      created_at
      updated_at
    }

    default_configs(where: { type: { _eq: "JOURNEY" } }) {
      id
      data
      created_at
      updated_at
    }
  }
`;

export const LOAD_CONVOCATIONS = gql`
  query loadConvocationsTeste {
    convocation {
      id
      worplace_id
    }
  }
`;

export const INSERT_CONVOCATIONS_WORKPLACES = gql`
  mutation insertConvocationWorkpalces(
    $items: [convocation_workplaces_insert_input!]!
  ) {
    insert_convocation_workplaces(objects: $items) {
      affected_rows
    }
  }
`;

export const GET_EXTRA_POINT_WORKPLACES = gql`
  query getExtraPointWorkplaces($convocationIds: [Int!]!) {
    extra_point_register_workplaces(
      where: { convocation_id: { _in: $convocationIds } }
    ) {
      id
      workplace_id
      convocation_id
      point_register_radius
      workplace {
        id
        name
        point_register_radius
        address {
          id
          geographic_coordinate
          place
          number
          neighborhood
          zipcode
          complement
          city {
            id
            name
            state {
              id
              sigla
            }
          }
        }
      }
    }
  }
`;

export const GET_CONVOCATION_HISTORIC = gql`
  subscription GetConvocationHistoric($id: Int!) {
    convocation_group_by_pk(id: $id) {
      id
      date_time_send
      user_company {
        id
        user {
          id
          name
        }
      }
      firstConvocation: convocations(limit: 1) {
        id
        convocation_change_reason {
          id
          description
        }
        firstWorkday: workdays(order_by: { date: asc }, limit: 1) {
          id
          date
          start_hour
        }
        lastWorkday: workdays(order_by: { date: desc }, limit: 1) {
          id
          date
          end_hour
        }
      }
      convocation_group_old {
        id
        convocations(limit: 1) {
          id
          convocation_change_reason {
            id
            description
          }
        }
      }
      convocation_logs(
        order_by: { datetime: desc }
        where: {
          event: { _nin: ["CREATE_CONVOCATION_GROUP", "CREATE_CONVOCATION"] }
        }
      ) {
        id
        event
        datetime
        before
        after
        convocation_group_id
        user {
          id
          name
        }
        convocation {
          id
          convocation_cancel_reason {
            id
            description
          }
          employee {
            id
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CONVOCATION_CANCEL_REASON = gql`
  mutation MyMutation(
    $description: String!
    $type: String!
    $company_id: Int!
  ) {
    insert_convocation_cancel_reason_one(
      object: {
        description: $description
        type: $type
        company_id: $company_id
      }
    ) {
      description
      type
    }
  }
`;

export const GET_CONVOCATION_CANCEL_REASON_BY_COMPANY = gql`
  query convocation_cancel_reason($company_id: Int!) {
    reason: convocation_cancel_reason(
      where: { company_id: { _eq: $company_id }, enabled: { _eq: true } }
      order_by: { id: asc }
    ) {
      id
      description
      type
    }
  }
`;

export const CREATE_CANCEL_CONVOCATION_COMPANY = gql`
  mutation cancelConvocationByGroup(
    $reasonId: Int!
    $convocation_group_id: Int
    $convocations_ids: [Int!]
    $date: timestamptz!
  ) {
    cancel_convocation_company(
      reason_id: $reasonId
      convocation_group_id: $convocation_group_id
      convocations_ids: $convocations_ids
      date: $date
    ) {
      message
    }
  }
`;

export const UPDATE_CANCEL_CONVOCATION_REASON = gql`
  mutation MyMutation(
    $id: Int!
    $company_id: Int!
    $description: String!
    $type: String!
  ) {
    update_convocation_cancel_reason(
      where: { company_id: { _eq: $company_id }, id: { _eq: $id } }
      _set: { description: $description, type: $type }
    ) {
      affected_rows
    }
  }
`;

export const DISABLE_CANCEL_CONVOCATION_REASON = gql`
  mutation MyMutation($id: Int!, $company_id: Int!) {
    update_convocation_cancel_reason(
      where: { id: { _eq: $id }, company_id: { _eq: $company_id } }
      _set: { enabled: false }
    ) {
      affected_rows
    }
  }
`;

export const GET_CONVOCATION_CANCEL_REASON_TYPE = gql`
  query MyQuery {
    convocation_reason_type: convocation_cancel_reason_type {
      type
    }
  }
`;

export const GET_CONVOCATION_ABSENT_REASON = gql`
  query convocation_absent_reason($companyId: Int!) {
    reason: convocation_absent_reason(
      where: { company_id: { _eq: $companyId }, is_active: { _eq: true } }
      order_by: { id: asc }
    ) {
      description
      id
      is_active
    }
  }
`;

export const INSERT_CONVOCATION_ABSENT_REASON = gql`
  mutation insertConvocationAbsent(
    $object: convocation_absent_reason_insert_input!
  ) {
    insert_convocation_absent_reason_one(object: $object) {
      id
    }
  }
`;

export const INSERT_CONVOCATION_ABSENT = gql`
  mutation MyMutation($convocation_id: Int!, $reason_id: Int!) {
    report_convocation_absent(
      convocation_id: $convocation_id
      reason_id: $reason_id
    ) {
      id
      message
    }
  }
`;

export const UPDATE_CONVOCATION_ABSENT_REASON = gql`
  mutation MyMutation(
    $id: Int!
    $absent_reason: convocation_absent_reason_set_input!
  ) {
    update_convocation_absent_reason_by_pk(
      pk_columns: { id: $id }
      _set: $absent_reason
    ) {
      id
    }
  }
`;

export const GET_CONVOCATION_CANCEL_REASONS_BY_COMPANY_ID = gql`
  query getConvocationCancelReasonsByCompanyId($company_id: Int) {
    convocation_cancel_reason(
      where: { company_id: { _eq: $company_id }, enabled: { _eq: true } }
    ) {
      description
      id
    }
  }
`;

export const GET_CONVOCATION_CHANGE_REASON = gql`
  query convocation_change_reason($companyId: Int!) {
    reason: convocation_change_reason(
      where: { company_id: { _eq: $companyId }, enabled: { _eq: true } }
      order_by: { id: asc }
    ) {
      description
      id
      enabled
    }
  }
`;

export const UPDATE_CONVOCATION_CHANGE_REASON = gql`
  mutation updateConvocationChangeReason(
    $change_reason_id: Int!
    $change_reason_description: String
  ) {
    update_convocation_change_reason_by_pk(
      pk_columns: { id: $change_reason_id }
      _set: { description: $change_reason_description }
    ) {
      id
    }
  }
`;

export const INSERT_CONVOCATION_CHANGE_REASON = gql`
  mutation insertConvocationChange(
    $object: convocation_change_reason_insert_input!
  ) {
    insert_convocation_change_reason_one(object: $object) {
      id
    }
  }
`;

export const DELETE_CONVOCATION_CHANGE_REASON = gql`
  mutation softDelteChangeConvocationReason(
    $id: Int!
    $change_reason: convocation_change_reason_set_input!
  ) {
    update_convocation_change_reason_by_pk(
      pk_columns: { id: $id }
      _set: $change_reason
    ) {
      id
    }
  }
`;
