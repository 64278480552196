import { handleResponse } from '../helpers/handle-response';
import { BehaviorSubject } from 'rxjs';
import { URL_AUTH } from '../config/config';

const currentTokenSubject = new BehaviorSubject(localStorage.getItem('token'));

export const authService = {
    login,
    logout,
    recoveryPassword,
    currentToken: currentTokenSubject.asObservable(),
    isAuthenticated,
    get currentTokenValue() { return currentTokenSubject.value },
    get username() { return localStorage.getItem('username') }
};

function isAuthenticated() {
    return authService.currentTokenValue;
};

function login(username, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${URL_AUTH}/token`, requestOptions)
        .then(handleResponse)
        .then(token => {
            localStorage.setItem('username', username);
            localStorage.setItem('token', token);
            currentTokenSubject.next(token);

            return true;
        });

}

function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    currentTokenSubject.next(null);
}

function recoveryPassword(login) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ login })
    };

    return fetch(`${URL_AUTH}/reset-password`, requestOptions);

}