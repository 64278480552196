import {
    faAngleDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Button, DropdownItem, DropdownMenu, DropdownToggle,
    Nav, NavItem, NavLink,
    UncontrolledButtonDropdown
} from 'reactstrap';
import PermissionRouter from '../../../Components/Permission/PermissionRouter';
import UserInfoProvider, { UserInfoContext } from '../../../context/UserInfoProvider';
import { authService } from '../../../services/auth';
import { getImage } from '../../../util/downloadImageUtil';
import NavLinkPermissionWrapper from './NavItemsWrappers/NavLinkPermissionWrapper';

const onDeniedPropsConst = { showUpgradeButton: true }

function UserImage() {
    const userInfo = useContext(UserInfoContext);
    useEffect(() => { }, [userInfo])
    return (
        <UserInfoProvider>
            <img width={42} height={42} className="rounded-circle" src={getImage('avatar', userInfo.avatar)} alt="" />
        </UserInfoProvider>
    )
}


function UserBox() {

    const userInfo = useContext(UserInfoContext);
    const userName = userInfo.name ? userInfo.name.split(" ")[0] : "";
    // const [userImg, setUserImg] =  useState(getImage('avatar', userInfo.avatar))

    const onLogout = () => {
        authService.logout();
    }

    // console.log("REBUILDOU!!!", userInfo.avatar)


    return (
        <Fragment>
            <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">

                        <div className="widget-content-right  mr-3 header-user-info">
                            <div className="widget-heading">
                                {userName}
                            </div>
                            {/* <div className="widget-subheading">
                                    VP People Manager
                                </div> */}
                        </div>


                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0">
                                    <UserImage />
                                    <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
                                </DropdownToggle>
                                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                    <div className="dropdown-menu-header">
                                        <div className="dropdown-menu-header-inner bg-info">
                                            <div className="menu-header-image opacity-2"
                                            // style={{
                                            //     backgroundImage: 'url(' + city3 + ')'
                                            // }}
                                            />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3">
                                                            <img width={42} height={42} className="rounded-circle" src={getImage('avatar', userInfo.avatar)}
                                                                alt="" />
                                                        </div>
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                {userInfo.name}
                                                            </div>
                                                            {/* <div className="widget-subheading opacity-8">
                                                                A short profile description
                                                            </div> */}
                                                        </div>
                                                        {/* <div className="widget-content-right mr-2">
                                                            <Button className="btn-pill btn-shadow btn-shine"
                                                                    onClick={onLogout}
                                                                    color="focus">
                                                                Logout
                                                            </Button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="scroll-area-xs" style={{
                                        height: '360px'
                                    }}>
                                        <PerfectScrollbar>
                                            <Nav vertical>
                                                <NavItem className="nav-item-header">
                                                    Configurações
                                                </NavItem>
                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink href="#/minha-conta">
                                                            Minha conta
                                                    </NavLink>
                                                    </NavItem>
                                                </DropdownItem>
                                                    <PermissionRouter permission="system-config:read" classDisabled>
                                                        <DropdownItem>
                                                            <NavItem className="d-flex align-items-center">
                                                                <PermissionRouter permission="system-config:read" classDisabled>
                                                                    <NavLink href="#/configuracoes">
                                                                        Configurações do Sistema
                                                                    </NavLink>
                                                                </PermissionRouter>
                                                            </NavItem>  
                                                        </DropdownItem>
                                                    </PermissionRouter>
                                                <DropdownItem divider />
                                                <NavItem className="nav-item-header">
                                                    Permissões
                                                </NavItem>
                                                <PermissionRouter permission="user-company:read" classDisabled>
                                                    <DropdownItem>
                                                        <NavItem className="d-flex align-items-center">
                                                            <NavLinkPermissionWrapper resource="user:full" onDeniedProps={onDeniedPropsConst} disableOnDenied >
                                                                <PermissionRouter permission="user-company:read" classDisabled>
                                                                    <NavLink href="#/usuarios">
                                                                        Usuários
                                                                    </NavLink>
                                                                </PermissionRouter>
                                                            </NavLinkPermissionWrapper>
                                                        </NavItem>
                                                    </DropdownItem>
                                                </PermissionRouter>


                                                {/* <DropdownItem divider /> */}
                                                <PermissionRouter permission="point-device:read" classDisabled>
                                                    <DropdownItem>
                                                        <NavItem className="d-flex align-items-center">
                                                            <NavLinkPermissionWrapper onDeniedProps={onDeniedPropsConst} disableOnDenied resource="point_record:full" >
                                                                <PermissionRouter permission="point-device:read" classDisabled>
                                                                    <NavLink href="#/ponto">
                                                                        Leitores de ponto
                                                                    </NavLink>
                                                                </PermissionRouter>
                                                            </NavLinkPermissionWrapper>
                                                        </NavItem>
                                                    </DropdownItem>
                                                </PermissionRouter>

                                                <DropdownItem divider />
                                                <PermissionRouter permission="right-menu:adm">
                                                    <NavItem className="nav-item-header">
                                                        SYMEE
                                                </NavItem>
                                                    <DropdownItem>
                                                        <NavItem className="d-flex align-items-center" >
                                                            {/* <NavLinkPermissionWrapper resource="user-company:read" disableOnDenied onDeniedProps={onDeniedPropsConst} > */}
                                                            <NavLink href="#/adm-planos">
                                                                Planos
                                                            </NavLink>
                                                            {/* </NavLinkPermissionWrapper> */}
                                                        </NavItem>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <NavItem>
                                                            <NavLink href="#/notas">
                                                                Gerenciamento de Notas
                                                            </NavLink>
                                                        </NavItem>
                                                    </DropdownItem>
                                                    <PermissionRouter permission="group:read">
                                                    <DropdownItem>
                                                        <NavItem className="d-flex align-items-center">
                                                            {/* <NavLinkPermissionWrapper resource="group:read" disableOnDenied onDeniedProps={onDeniedPropsConst} > */}
                                                                <NavLink href="#/grupos">
                                                                    Grupos
                                                            </NavLink>
                                                            {/* </NavLinkPermissionWrapper> */}
                                                        </NavItem>
                                                    </DropdownItem>
                                                </PermissionRouter>
                                                    <DropdownItem divider />
                                                </PermissionRouter>


                                                <NavItem>
                                                    <div className="widget-content-left ml-3">
                                                        <Button className="btn-focus btn-shadow btn-shine"
                                                            onClick={onLogout}
                                                            color="focus">
                                                            Sair
                                                            </Button>
                                                    </div>
                                                </NavItem>
                                                {/* <DropdownItem divider /> */}

                                            </Nav>
                                        </PerfectScrollbar>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>


                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UserBox;