import React, { useState } from "react";
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { Alert, Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { authService } from '../../services/auth';


export default function RecoveryPassword(props) {

    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();


    function handleSendEmailClick() {

        if (success) {
            handleCloseClick();
            return;
        }

        setLoading(true);
        setError('');
        setSuccess('');

        authService.recoveryPassword(email).then(
            data => {
                if (data.status === 404) {
                    setLoading(false);
                    setError("Usuário não encontrado");
                } else {
                    setLoading(false);
                    data.json().then(obj => {
                        //setSuccess("Enviamos um e-mail de recuperação para o e-mail: " + obj.email)
                        setSuccess("E-mail enviado com sucesso");
                    });
                }
            },
            error => {
                setLoading(false);
                setError("Houve um erro ao enviar o e-mail. Por favor, tente novamente mais tarde");
                console.log(error);
            }
        );
    }

    function handleCloseClick() {
        setError('');
        setSuccess('');
        setEmail('');
        props.onCloseModal();
    }


    return (
        <Modal isOpen={props.open}>
            <BlockUi tag="div" blocking={loading} className="block-overlay-dark"
                loader={<Loader color="#ffffff" active type="ball-pulse" />}>
                <ModalHeader>Recuperar Conta</ModalHeader>
                <ModalBody className="m-3">
                    <Row>
                        <Col>
                            {
                                error &&
                                <Alert color="danger">
                                    {error}
                                </Alert>
                            }

                            {
                                success &&
                                <Alert color="success">
                                    {success}
                                </Alert>
                            }

                            <FormGroup>
                                <Label>E-mail</Label>
                                <Input
                                    type="email"
                                    onChange={e => setEmail(e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-link" color="link" onClick={handleCloseClick}>Cancelar</Button>
                    <Button
                        className="btn"
                        color="primary"
                        disabled={!email}
                        onClick={handleSendEmailClick}>{
                            !success ? "Enviar e-mail" : "Fechar"
                        }
                    </Button>
                </ModalFooter>
            </BlockUi>
        </Modal>
    );

}