import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import './assets/base.scss';
import { LoginRedirect } from './routes/routes'
import { authService } from './services/auth';

const rootElement = document.getElementById('root');

const renderApp = () => {
    ReactDOM.render(
        <LoginRedirect />,
        rootElement
    );

};

renderApp();

if (module.hot) {
    module.hot.accept('./pages/Main', () => {
        const NextApp = require('./pages/Main').default;
        renderApp(NextApp);
    });
}

authService.currentToken.subscribe(token =>{
    if(!token){
        renderApp();
    }
});

serviceWorker.unregister();