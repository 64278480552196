import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import React, { Fragment, memo } from "react";
import { Provider } from 'react-redux';
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { StoreContext } from 'redux-react-hook';
import './assets/global.css';
import createApolloClient from './config/configureApollo';
import configureStore from './config/configureStore';
import UserInfoContextProvider from "./context/UserInfoProvider";
import InvoiceNote from "./pages/InvoiceNote";
import Main from './pages/Main';
import PlanSelectionExternal from './pages/PlanSelection/PlanSelectionExternal';
import PurchaseSummaryExternal from './pages/PurchaseSummary/PurchaseSummaryExternal';
import { LOAD_USER_QUERY } from './queries/user';
import { authService } from './services/auth';
import ParametersProvider from './context/GeralProvider';

const store = configureStore();

const App = () => {

    const client = createApolloClient(authService.currentTokenValue);

    return (
        <Provider store={store}>
            <ApolloProvider client={client}>
                <StoreContext.Provider value={store}>
                    <UserInfoContextProvider>
                        <ParametersProvider>
                        <HashRouter>
                            <RoutesWrapper />
                        </HashRouter>
                        </ParametersProvider>
                    </UserInfoContextProvider>
                </StoreContext.Provider>
            </ApolloProvider>
        </Provider >
    );

}

const now = moment().format("YYYY-MM-DD");

function RoutesWrapper() {

    const { loading, error, data } = useQuery(LOAD_USER_QUERY, {
        variables: { login: authService.username, now }
    });

    if (loading) return 'Carregando...';

    if (error) return `Erro: ${error.message}`;

    const hasPlan = data.user[0].user_companies[0].company.actual_plan.length > 0 ? true : false;
    const selected_site_plan_id = data.user[0].user_companies[0].company.selected_site_plan_id

    if (hasPlan) {
        return (
            <Switch>
                <Route exact path="/fatura/:planCompanyId" component={() => <InvoiceNote />} />
                <Route path="/" component={() => <Main />} />
            </Switch>
        );
    }

    const WithoutPlanRoutes = (
        <Fragment>
            <Route exact path="/planos" component={() => <PlanSelectionExternal />} />
            <Route exact path="/resumo-compra/:planId" component={() => <PurchaseSummaryExternal />} />
        </Fragment>
    );

    if (selected_site_plan_id) {
        return (
            <Fragment>
                {WithoutPlanRoutes}
                <Redirect to={`/resumo-compra/${selected_site_plan_id}`} />
            </Fragment>
        );
    }

    return (
        <Fragment>
            {WithoutPlanRoutes}
            <Redirect to="/planos" />
        </Fragment>
    );

}

export default memo(App);