import gql from "graphql-tag";

export const GET_ALL_COMPANIES = gql`
subscription company {  
    company{
        id
        business_name
        cnpj
        email
        fantasy_name
        phone
        whatsapp
        logomarca
        legal_responsible_name
        legal_responsible_cpf
        address{
            id
            complement
            neighborhood
            number
            place
            zipcode
            city{
              id
              name
              state{
                id
                sigla
                name
                country{
                    id
                    name
                  }
              }
            }
            
          }
    }
}
`

export const FIND_COMPANY_BY_ID = gql`
    query company (
        $company_id: Int!
    ) {  
        company(
            where: {
                id: {
                    _eq: $company_id
                }
            }
        ) {
            id
            business_name
            cnpj
            email
            fantasy_name
            phone
            whatsapp
            logomarca
            legal_responsible_name
            legal_responsible_cpf
            address{
                id
                complement
                neighborhood
                number
                place
                zipcode
                city{
                  id
                  name
                  state{
                    id
                    sigla
                    name
                    country{
                        id
                        name
                      }
                  }
                }
                
              }
            user_companies{
                user{
                    id
                    cpf_cnpj
                    
                }
            }
            
        }
        city {
            id
            name
          }
    }
`

export const UPDATE_COMPANY_BY_ID = gql`
    mutation company(
            $company_id: Int!
            $company: company_set_input!
        ){
        
        update_company(where: {id: {_eq: $company_id}},
             _set: $company
                    
             ) {
        affected_rows
        }
  }
`

export const INSERT_COMPANY_ADDRESS = gql`
    mutation insert_company_address(
        $complement: String
        $neighborhood: String!
        $number: String!
        $place: String!
        $zipcode: String!
        $localidade: Int!
    ){
        insert_address(objects: {
            city_id: $localidade,
            complement: $complement,
            neighborhood: $neighborhood,
            number: $number,
            place: $place,
            zipcode: $zipcode
        }) {
            returning {
              id
            }
          }
    }
`

export const UPDATE_COMPANY_ADDRESS = gql`
    mutation company_address(
        $id_address: Int!
        $complement: String
        $neighborhood: String!
        $number: String!
        $place: String!
        $cep: String!
        $localidade: Int!
    ){
        
        update_address(where: {id: {_eq: $id_address}},
            _set: { 
                complement: $complement,
                neighborhood: $neighborhood,
                number: $number,
                place: $place,
                zipcode: $cep,
                city_id: $localidade
            }) {
        affected_rows
        }
  }
`

export const UPDATE_LOGO = gql`
    mutation updateLogo(
        $company_id: Int!, 
        $key: String!
    ) {
        update_company(
            where: {
                id: {
                    _eq: $company_id
                }
            },
            _set: {
                logomarca: $key
            }
        ) {
            affected_rows
        }
    }
`


export const LOAD_COMPANY_EMPLOYEE_INFOS = gql`
    query loadCompanyEmployeeInfos($company_id: Int!, $emplyee_id: Int!, $contractDate: date!) {
        company(where: { id: { _eq: $company_id } }) {
            id
            business_name
            cnpj
            fantasy_name
            salary_additional{
                id
                percentage_extra_hour
                percentage_night_additional
            }
        }
        employee(where: { id: { _eq: $emplyee_id } }) {
            contracts(
                where: {
                    _and: [
                    {start_date: {_lte: $contractDate}},
                    {company_id: {_eq: $company_id}}
                    ]
                }
            ){
            id
            status
            start_date
            occupation_contracts{
                id
                occupation_additional{
                    id
                    description
                }
                occupation{
                id
                name
                cbo
                
                }
            }
            }
            user {
                id
                name
                cpf_cnpj
            }
        }
    }
`;

export const GET_COMPANY_SALARY_ADDITIONAL = gql`
query getSalaryAdditional($companyId: Int!){
    company(where: {id: {_eq: $companyId}}){
      id
      salary_additional{
        id
        percentage_extra_hour
        percentage_night_additional
      }
    }
  }
`

export const DELETE_COMPANY_SELECTED_PLAN_ID = gql`
mutation deleteSiteSelectedPlan($company_id: Int!){
    update_company(
      where: {
        id: {_eq: $company_id}
      },
      _set: {
        selected_site_plan_id: null
      }
    ){
      affected_rows
    }
  }
`


export const GET_COMPANY_EMPLOYEES = gql`
query getCompanyEmployees(
    $company_id: Int!
){
    company(where: {
        id: { _eq: $company_id }
    }) {
      id
      contracts(
        distinct_on: employee_id,
        where :{
          status :{_in: ["Ativo", "Encerrado"]}
      }){
        id
        employee {
          id
          user {
            id
            name
            cpf_cnpj
          }
        }
      }
    }
  }
  
`