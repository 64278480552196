import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
// import { ApolloClient } from 'apollo-client';
// import { InMemoryCache } from "apollo-cache-inmemory";
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { URL_HASURA, WS_HASURA } from './config';
import { authService } from '../services/auth';
import { onError } from 'apollo-link-error';
import { ApolloClient, InMemoryCache } from '@apollo/client';

const createApolloClient = (token) => {
  const httpLink = new HttpLink({
    uri: URL_HASURA,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const wsLink = new WebSocketLink({
    uri: WS_HASURA,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {

    if (networkError) {
      if (networkError.extensions.code === 'validation-failed' || networkError.extensions.code === 'access-denied') {
        authService.logout();
        //window.location.href= `/error/networkError/${networkError.message}`;
      }
    }

    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err.extensions) {
          if (err.extensions.code === 'validation-failed' || err.extensions.code === 'access-denied') {
            //authService.logout();
            window.location.href= `/error/grapqhlError/${err.message}`;
          }
        }
      }
    }
    console.log(networkError, graphQLErrors);

    console.log(networkError, graphQLErrors);
  });

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );


  return new ApolloClient({
    link: errorLink.concat(link),
    
    cache: new InMemoryCache()
  });

}

export default createApolloClient;
