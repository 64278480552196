import gql from "graphql-tag";

export const LOAD_USERS = gql`
    query loadUsers($company_id: Int!){
        user(order_by: { register_date: desc }, where: {user_companies: {id_company: {_eq: $company_id}}}) {
            id
            name
            status
            register_date
            phone
            email
            avatar
            user_permissions {
                id
                action{
                    id
                    description
                }
            }
        }
    }
`;


export const CREATE_USER = gql`
  mutation createUser(
      $name: String!, $cpf_cnpj: String!, $email: String!, $phone: String!,
      $password: String!, $company_id: Int!, $avatar: String) {
    insert_user_company(objects: {user: {data: {
        name: $name,
        cpf_cnpj: $cpf_cnpj,
        email: $email,
        login: $email,
        phone: $phone,
        password: $password,
        temp_password: true,
        avatar: $avatar,
        status: "Ativo"
    }}, id_company: $company_id, responsible: false}){
        returning {
            user {
                id
            }
        }
    }
  }
`;

export const CREATE_USER_PERMISSION = gql`
  mutation createUserPermission($objects: [user_permission_insert_input!]!) {
    insert_user_permission(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
export const LOAD_USER_QUERY = gql`
    query loadUser(
        $login: String!
        $now: date!
    ){
        user(where: { login: { _eq: $login } }) {
            id
            user_companies {
                id
                company {
                    id
                    selected_site_plan_id
                    actual_plan: plan_companies(
                        where: {
                          _and: [
                            { type: { _eq: CONTRACTED } },
                            { validity_end: { _gte: $now } }
                          ]
                        }
                        order_by: {
                          created_date: desc
                        }
                        limit: 1
                    ){
                        id
                        plan_id
                        contract_count
                        contracted_contract_count
                    }
                }
            }
        }
    }
`;

export const LOAD_USER = gql`
    subscription loadUser(
        $login: String!
        $now: date!
    ){
        user(where: { login: { _eq: $login } }) {
            id
            avatar
            cpf_cnpj
            login
            name
            temp_password
            user_companies {
                id
                id_company
                company {
                    id
                    business_name
                    cnpj
                    id_address
                    id_plan
                    logomarca
                    phone
                    site
                    whatsapp
                    email
                    fantasy_name
                    legal_responsible_name
                    legal_responsible_cpf
                    selected_site_plan_id
                    contract_default_version
                    address {
                        id
                        place
                        number
                        neighborhood
                        zipcode
                        geographic_coordinate
                        complement
                        city {
                          id
                          name
                          state {
                            id
                            name
                            sigla
                          }
                        }
                      }
                    salary_additional{
                        id
                        percentage_extra_hour
                        percentage_night_additional
                        validated
                      }
                    plan_company_resources{
                        id
                        system_resource{
                            id
                            name
                        }
                    }
                    plan_convocations_sum: plan_companies_aggregate(where: {validity_end: {_gte: $now}}) {
                        aggregate {
                            sum {
                              convocation_count
                              contracted_convocation_count
                            }
                        }
                    }
                    actual_plan: plan_companies(
                        where: {
                          _and: [
                            { type: { _eq: CONTRACTED } },
                            { validity_end: { _gte: $now } }
                          ]
                        }
                        order_by: {
                          created_date: desc
                        }
                        limit: 1
                    ){
                        id
                        plan_id
                        
                    }
                    last_plan: plan_companies(
                        where: {
                          _and: [
                            { type: { _eq: CONTRACTED } }
                          ]
                        }
                        order_by: {
                          created_date: desc
                        }
                        limit: 1
                    ){
                        id
                        plan_id
                      
                    }
                }
            }
            user_permissions {
                id
                action {
                    id
                    name
                }
            }
        }
    }
`;


export const LOAD_USER_PLAN_CONTRACTED_COUNT = gql`
    query loadUser(
        $login: String!
        $now: date!
    ){
        user(where: { login: { _eq: $login } }) {
            id
            user_companies {
                id
                company {
                    id
                    actual_plan: plan_companies(
                        where: {
                          _and: [
                            { type: { _eq: CONTRACTED } },
                            { validity_end: { _gte: $now } }
                          ]
                        }
                        order_by: {
                          created_date: desc
                        }
                        limit: 1
                    ){
                        id
                        plan_id
                        contract_count
                        contracted_contract_count
                    }
                    last_plan: plan_companies(
                        where: {
                          _and: [
                            { type: { _eq: CONTRACTED } }
                          ]
                        }
                        order_by: {
                          created_date: desc
                        }
                        limit: 1
                    ){
                        id
                        plan_id
                        contract_count
                        contracted_contract_count
                    }
                }
            }
        }
    }
`;


export const GET_NAME_BY_CPFCNPJ = gql`
    query loadName($cpf_cnpj: String!){
        user(where: { login: {_eq: $cpf_cnpj}}){
            id
            name
        }
    }
`;

export const LOGIN_TO_REGISTER_POINT = gql`
    query loadUser(
        $login: String!,
    ){
        user(where: {login: {_eq: $login}}) {
            id
            avatar
            cpf_cnpj
            login
            name
            employees {
                id
            }
        }
    }
`;

export const LOGIN_TO_REGISTER_POINT_WITH_PASSWORD = gql`
    query loadUser(
        $login: String!,
        $password: String!
    ){
        user(where: {login: {_eq: $login}, _and: {password: {_eq: $password}}}) {
            id
            avatar
            cpf_cnpj
            login
            name
            employees {
                id
            }
        }
    }
`;

export const LOAD_USER_BY_ID = gql`
    query loadUser(
        $user_id: uuid!
    ){
        user(where: { id: { _eq: $user_id } }) {
            id
            avatar
            cpf_cnpj
            login
            name
                        data_nasc
                nationality
                phone
            sexo
        }
    }
`;


export const UPDATE_USER_BY_ID = gql`
    mutation updateUser(
            $user_id: uuid!
            $user: user_set_input!
        ) {
            update_user(where: {id: {_eq: $user_id}}, 
            _set: $user
        ) {
        affected_rows
        }
    }
`;


export const LOAD_USER_BY_ID_TO_EDIT = gql`
    query loadUser(
        $user_id: uuid!
    ){
        user(where: { id: { _eq: $user_id } }) {
            id
            name
            email
            cpf_cnpj
            phone
            status
            avatar
            user_permissions {
                action{
                    id
                    description
                }
            }
        }
    }
`;


export const UPDATE_USER_AND_PERMISSIONS = gql`
    mutation updateUser(
            $user_id: uuid!
            $user: user_set_input!,
            $user_permissions: [user_permission_insert_input!]!
        ) {

        update_user(where: {id: {_eq: $user_id}}, _set: $user) {
            affected_rows
        }
        delete_user_permission(where: { user_id: { _eq: $user_id } }) {
            affected_rows
        }
        insert_user_permission(objects: $user_permissions) {
            affected_rows
        }
    }
`;

export const UPDATE_AVATAR = gql`
    mutation updateAvatar(
        $user_id: uuid!,
        $key: String!
    ) {
        update_user(
            _set: { 
                avatar: $key 
            }, 
            where: { 
                id: { 
                    _eq: $user_id
                } 
            }
        ) {
            affected_rows
        }
    }
`;