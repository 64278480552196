import { GET_HOLYDAYS_BY_IBGE } from "../queries/holiday";
import directRequet from "../services/directRequest";
import { getYearsInInterval } from "./dateUtil";


export async function loadHolidaysByPeriod(ibge, startDate, endDate) {

    const years = getYearsInInterval(startDate, endDate);
    return await loadHolidaysByYears(ibge, years);

}

export async function loadHolidaysByYears(ibge, years) {

    let holidays = [];
    for (let i = 0; i < years.length; i++) {

        const result = await directRequet({
            query: GET_HOLYDAYS_BY_IBGE,
            variables: {
                year: years[i],
                ibge
            }
        });

        if (result.data) {
            holidays = [...holidays, ...result.data.holiday];
        }
    }

    return holidays.filter(hol => hol.type.toUpperCase().startsWith('FERIADO'));

};