import gql from "graphql-tag";

export const LOAD_SERVER_TIME = gql`
    query {
        time(br: true) {
            date
            time
        }
    }
`;

export const LOAD_SERVER_TIME_LOCALE_EN = gql`
    query {
        time(br: false) {
            date
            time
        }
    }
`;
