
import directRequest from "../../../services/directRequest";
import { GET_NAME_BY_CPFCNPJ } from "../../../queries/user";


export const getNameByCpfCnpj = (cpf_cnpj) => {
    return new Promise((resolve, reject) => {
        directRequest({
            query: GET_NAME_BY_CPFCNPJ,
            variables: {
                cpf_cnpj
            }
        }).then(resolve)
            .catch(reject);
    });
}