import { useQuery } from '@apollo/react-hooks';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { useHistory } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import UserInfoProvider, { UserInfoContext } from '../../context/UserInfoProvider';
import { LOAD_SERVER_TIME_LOCALE_EN } from '../../queries/others';
import { GET_PLANS_CARDS } from '../../queries/plan';
import PlanCard from '../Plans/components/PlanCard';
import { groupResources } from './helpers/group_resources_helper';
import { getImage } from '../../util/downloadImageUtil';
import ExternalHeader from '../../Components/ExternalHeader';
import directRequet from '../../services/directRequest';
import { LOAD_USER_PLAN_CONTRACTED_COUNT } from '../../queries/user';
import moment from 'moment';

export default function PlanSelectionExternal() {

    const { data: timeData, loading: timeLoading, error: timeError } = useQuery(LOAD_SERVER_TIME_LOCALE_EN);

    if (timeError) {
        console.error(timeError)
        return `Erro: ${timeError}`
    };
    if (timeLoading) return `Carregando...`;
    const serverTime = `${timeData.time.date} ${timeData.time.time}`;

    return (
        <PlansCardsLoader serverTime={serverTime} site_visible={true} system_visible={true} />
    );
}

function PlansCardsLoader({ serverTime, site_visible, system_visible }) {

    const history = useHistory();
    const userInfo = useContext(UserInfoContext);

    function contractPlan(planId) {
        return history.push(`/resumo-compra/${planId}`)
    }

    const { data, error, loading } = useQuery(GET_PLANS_CARDS, {
        variables: {
            plan_type: ["CONTRACTED", "CUSTOM"],
            serverTime,
            siteVisible: site_visible,
            systemVisible: system_visible
        }
    });

    if (loading) return `Carregando...`;
    if (error) return `Erro: ${error}`;
    
    const formattedResources = groupResources(data.plan, data.system_resource)
    const formattedPlans = data.plan.map(plan => {
        return {
            ...plan,
            resources: formattedResources.map(rs => {
                return {
                    ...rs,
                    visible: plan.plan_resources.some(prs=> prs.system_resource.id == rs.id && prs.visible),
                    planIncludes: plan.plan_resources.some(prs => prs.system_resource.id == rs.id)
                }
            })
        }
    })

    return (
        <Fragment>
            <ExternalHeader/>
            <Row className="ml-3 mr-3" style={{marginTop: "100px"}}>
                <Col className="text-center">
                    <img style={{ width: "40vh" }} src={require("../../assets/images/syme-logo.png")} />
                </Col>
            </Row>
            <Row className="ml-3 mr-3 mt-4 mb-4">
                <Col className="text-center">
                    <h2 className="text-uppercase text-center font-weight-bold" >
                        {
                            userInfo.last_plan.length > 0 ?
                            "Faça a renovação de seu plano"
                            :
                            "Escolha um plano para continuar"
                        } 
                    </h2>
                </Col>
            </Row>
            <Row className="ml-3 mr-3 mt-4">
                <PlansCards
                    plans={formattedPlans}
                    onContractClick={contractPlan} />
            </Row>
        </Fragment>
    )
}

function PlansCards({ plans, onContractClick }) {

    const userInfo = useContext(UserInfoContext);
    const [loading, setLoading] = useState(true);
    const [currentUserContractsCount, setCurrentUserContractsCount] = useState(null);

    useEffect(()=>{
        async function getContractCount(){
            const now = moment().format("YYYY-MM-DD");
            const { data, errors } = await directRequet({
                query: LOAD_USER_PLAN_CONTRACTED_COUNT,
                variables: {
                    login: userInfo.login,
                    now: now
                }
            })
    
        
            const hasPlan = data.user[0].user_companies[0].company.actual_plan.length > 0 ? true : false;
            let contract_count, contracted_contract_count;
    
            if (hasPlan) {
                contract_count = data.user[0].user_companies[0].company.actual_plan[0].contract_count;
                contracted_contract_count = data.user[0].user_companies[0].company.actual_plan[0].contracted_contract_count;
            }
            
            if (data.user[0].user_companies[0].company.last_plan.length > 0){
                contract_count = data.user[0].user_companies[0].company.last_plan[0].contract_count;
                contracted_contract_count = data.user[0].user_companies[0].company.last_plan[0].contracted_contract_count;
            }

            setCurrentUserContractsCount(contracted_contract_count - contract_count);
            setLoading(false);
        }

        getContractCount();
    }, [])

    if(loading) return "Carregando...";

    return (
        plans.map(plan => {
            return (
                <PlanCard
                    key={plan.id}
                    lg="3"
                    md="6"
                    sm="4"
                    xs="2"
                    plan={plan}
                    currentUserContractsCount = {currentUserContractsCount}
                    actualPlanId={userInfo.hasPlan ? userInfo.actual_plan.plan_id : null}
                    onContractClick={onContractClick} />
            )
        })
    )

}