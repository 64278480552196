import gql from "graphql-tag";

export const UPDATE_PLAN = gql`
mutation MyMutation($id: Int!, $data: plan_set_input!, $resources: [plan_resource_insert_input!]! ) {
  update_plan(where: {id:{_eq: $id}}, _set: $data){
    affected_rows
  }
  delete_plan_resource(where: {plan_id: {_eq: $id}}){
    affected_rows
  }
  insert_plan_resource(objects: $resources){
    affected_rows
  }
}

`

export const INSERT_PLAN = gql`
mutation insertPlan($data: [plan_insert_input!]!){
    insert_plan(objects: $data){
      returning{
        id
      }
    }
  }
`

export const GET_ALL_PLANS = gql`
subscription getPlans{
  plan{
    id
    name
    contract_count
    convocation_count
    validity_start
    validity_end
    month_count
    price
    price_description
    period_description
    site_visible
    system_visible
    type
  }
}
`


export const GET_PLAN_BY_ID = gql`
query getPlan($id: Int!){
  plan(where: {id:{_eq: $id}}){
    id
    name
    description
    contract_count
    convocation_count
    validity_start
    validity_end
    month_count
    price
    price_description
    period_description
    site_visible
    system_visible
    type
    plan_resources{
      id
      visible
      system_resource{
        id
        name
        description
        helper
      }
    }
  }
}`
  ;

export const GET_PLANS_CARDS = gql`
  query getPlansFIltered(
    $serverTime: date!, 
    $systemVisible: Boolean,
    $siteVisible: Boolean,
    $plan_type: [plan_type_enum!]!
    ) {
    system_resource ( order_by: { order: asc } ){
      id
      name
      description
      helper
    }
    plan(where: {
      _and: [
        { validity_start: { _lte: $serverTime } },
        { validity_end: { _gte: $serverTime } },
        { type: { _in: $plan_type } },
        {
          _or: [
            { system_visible: { _eq: $systemVisible } },
            { site_visible: { _eq: $siteVisible } },
          ]
        }
      ]
    } 
    order_by: { price: asc }
    ){
      id
      contract_count
      convocation_count
      name
      description
      validity_end
      validity_start
      month_count
      price
      price_description
      period_description
      type
      plan_resources{
        id
        visible
        system_resource{
          id
          name
          description
          helper
        }
      }
    }
  }
`

export const GET_PLANS = gql`
  query getPlansFIltered(
    $serverTime: date!,
    $systemVisible: Boolean,
    $siteVisible: Boolean,
    $plan_type: [plan_type_enum!]!
  ) {
    plan(where: {
      _and: [
        { validity_start: { _lte: $serverTime } },
        { validity_end: { _gte: $serverTime } },
        { type: { _in: $plan_type } },
        {
          _or: [
            { system_visible: { _eq: $systemVisible } },
            { site_visible: { _eq: $siteVisible } },
          ]
        }
      ]
    } 
    order_by: { price: asc }
    ){
      id
      contract_count
      convocation_count
      name
      description
      validity_end
      validity_start
      month_count
      price
      price_description
      period_description
      type
      plan_resources{
        id
        system_resource{
          id
          name
          description
          helper
        }
      }
    }
  }
`;

export const LOAD_PLAN_AGGREGATE = gql`
  query loadPlanCompany(
    $company_id: Int!, 
    $now: date
  ) {
    plan_company_aggregate(where: {company_id: {_eq: $company_id}, validity_end: {_gte: $now}})  {
      aggregate {
          sum {
            contract_count
            convocation_count
            contracted_contract_count
            contracted_convocation_count
          }
        }
    }
  }
`