
import directRequest from "../../../services/directRequest";
import { CREATE_PUSH_MESSAGE_REGISTERED_POINT } from '../../../queries/push_message';

export const sendMessage = async (user, date, company, callbackSuccess, contract) => {
    try {
        const result = await directRequest({
            query: CREATE_PUSH_MESSAGE_REGISTERED_POINT,
            variables: {
                user_id: user.id,
                title: `Registro de ponto`,
                body: `Ponto registrado às ${date}!`,
                action_data: {"contract_id": contract.id, "company": company}
            }
        });

        if (result.data.insert_push_message.affected_rows) {
            callbackSuccess();
        }
    } catch (err) {
        console.log(err);
        throw new Error("Erro ao enviar a notificação de registro de ponto, contate o administrador");
    }
}