export const OCCUPATION_ADDITIONAL_TYPES = {
    INSALUBRITY: "INSALUBRITY",
    DANGEROUSNESS: "DANGEROUSNESS"
}


export const INTERMITENT_NORMAL_MAX_WORKDAY_HOURS = 8;
export const MAX_JOURNEY_DAY_HOURS = 12;

export const DEFAULT_POINT_REGISTER_RADIUS = 25; //metros
export const MIN_POINT_REGISTER_RADIUS_METERS = 10;
export const MAX_POINT_REGISTER_RADIUS_METERS = 999;
export const MIN_POINT_REGISTER_RADIUS_KILOMETERS = 1;
export const MAX_POINT_REGISTER_RADIUS_KILOMETERS = 1000;