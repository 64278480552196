import { formatToDate } from "brazilian-values";
import moment from "moment";
import { loadHolidaysByPeriod } from "./holidayUtil";

const BUSINESS_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const MONTHS = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export async function getNextBusinessDay(ibge, day) {
  const holidays = await loadHolidaysByPeriod(ibge, day, moment(day).add(7, "days"));

  do {
    day = moment(day).add(1, "day");
  } while (
    BUSINESS_DAYS.some((weekday) => weekday !== moment(day).format("dddd")) &&
    holidays.some((holiday) => moment(day).isSame(holiday.date, "days"))
  );

  return day;
}

export function getDatesArray(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const dates = [];

  var loop = moment(start).clone();
  while (moment(loop).isSameOrBefore(end, "day")) {
    dates.push(moment(loop).toDate());
    loop.add(1, "day");
  }

  return dates;
}

export function splitDate(firstDay, lastDay, intervalInDays) {
  let dates = [];
  let date = firstDay;

  while (moment(date).isSameOrBefore(lastDay)) {
    date.add(intervalInDays, "days");

    if (moment(date).isAfter(lastDay)) {
      dates.push(moment(lastDay).clone());
    } else {
      dates.push(moment(date).clone());
    }
  }

  return dates;
}

//formata uma data no formato que vem do banco yyyy-mm-dd para o formato dd/mm/yyyy
export function formatDate(date) {
  return formatToDate(new Date(...date.split("-").map((item, index) => item - (index % 2))));
}

//Faz o inverso da de cima
export function formatDateReverse(date) {
  return `${date.substring(6)}-${date.substring(3, 5)}-${date.substring(0, 2)}`;
}

export function formatObjDateToYYYYMMDD(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function getHoursFromDate(date) {
  let newHours = date.split("T");
  let onlyHours = newHours[1].split("-");

  return onlyHours[0];
}

export function getMonthsInInterval(firstDate, lastDate) {
  const response = [];

  let actual = moment(firstDate);
  while (actual.isSameOrBefore(lastDate, "month")) {
    response.push({
      month: actual.month(),
      year: actual.year(),
    });

    actual.add(1, "month");
  }

  return response;
}

export function getYearsInInterval(firstDate, lastDate) {
  const response = [];

  let actual = moment(firstDate);
  while (actual.isSameOrBefore(lastDate, "year")) {
    response.push(actual.year());

    actual.add(1, "year");
  }

  return response;
}

export function isSameDay(startHour, endHour) {
  if (moment(startHour).get("hours") > moment(endHour).get("hours")) {
    return false;
  }
  return true;
}

export function getMonthString(month) {
  return MONTHS[month - 1];
}

export function getDateToMMYYYYString() {
  const date = new Date();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `${month < 10 ? `0${month}` : month}/${year}`;
}
