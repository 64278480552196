export const SET_FULL_CONVOCATION_LIST = "SET_FULL_CONVOCATION_LIST";
export const SET_CONVOCATION_LIST_FILTERED = "SET_CONVOCATION_LIST_FILTERED";
export const SET_STATUS_FILTERS = "SET_STATUS_FILTERS";
export const SET_CANCEL_REASONS_FILTERS = "SET_CANCEL_REASONS_FILTERS";
export const SET_CHANGE_REASONS_FILTERS = "SET_CHANGE_REASONS_FILTERS";

export const setFullConvocationList = (fullConvocationlist) => ({
  type: SET_FULL_CONVOCATION_LIST,
  convocationList: fullConvocationlist,
});

export const setConvocationListFiltered = (filtered) => ({
  type: SET_CONVOCATION_LIST_FILTERED,
  filtered,
});

export const setStatusFilters = (status) => ({
  type: SET_STATUS_FILTERS,
  status,
});
export const setCancelReasonsFilters = (cancelReasons) => ({
  type: SET_CANCEL_REASONS_FILTERS,
  cancelReasons,
});
export const setChangeReasonsFilters = (changeReasons) => ({
  type: SET_CHANGE_REASONS_FILTERS,
  changeReasons,
});
