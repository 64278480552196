const URL_SITE = process.env.REACT_APP_URL_SITE;
const URL_HASURA = process.env.REACT_APP_URL_HASURA;
const WS_HASURA = process.env.REACT_APP_WS_HASURA;
const URL_AUTH = process.env.REACT_APP_URL_AUTH;
const URL_FILE_SERVER = process.env.REACT_APP_URL_FILE_SERVER;
const URL_SISTEMA = process.env.REACT_APP_URL_SISTEMA;
const URL_REPORTS = process.env.REACT_APP_URL_REPORTS;
const ENVIRONMENT = URL_HASURA.search("hasura.dev") > -1 ? "DEVELOPMENT" : "PRODUCTION";
const USER_REPORTS = process.env.REACT_APP_USER_REPORTS;
const PASSWORD_REPORTS = process.env.REACT_APP_PASSWORD_REPORTS;  

export{
    URL_REPORTS,
    URL_SITE,
    URL_HASURA,
    WS_HASURA,
    URL_AUTH,
    URL_FILE_SERVER,
    URL_SISTEMA,
    ENVIRONMENT,
    USER_REPORTS,
    PASSWORD_REPORTS
}