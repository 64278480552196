import gql from "graphql-tag";

export const LOAD_ALL_CONTRACTS = gql`
query contracts($company_id: Int!) {
    contract(where: {company_id: {_eq: $company_id}}) {
      id
      start_date
      end_date
      status
      employee {
        id
        user {
          avatar
          name
          id
          cpf_cnpj
        }
      }
      occupation_contracts(where: {active: {_eq: true}}) {
        id
        hour_value
        occupation {
          cbo
          id
          name
        }
      }
    }
  }
`;

export const LOAD_ALL_CONTRACTS_BY_EMPLOYEE = gql`
query contracts(
  $company_id: Int!
  $employee_id: Int!
  ) {
    contract(where: {
      company_id: {_eq: $company_id},
      employee_id: {_eq: $employee_id}
    
    }) {
      id
      start_date
      end_date
      status
      employee {
        id
        user {
          avatar
          name
          id
          cpf_cnpj
        }
      }
      occupation_contracts(where: {active: {_eq: true}}) {
        id
        hour_value
        occupation {
          cbo
          id
          name
        }
      }
    }
  }
`;

export const LOAD_ARCHIVED_CONTRACTS = gql`
query contracts(
  $company_id: Int!
  $limit: Int!,
  $offset: Int!,
  $search: String
  $statusFilter: [String!]
  ) {
  contract(
    limit: $limit,
    offset: $offset,
    where: { 
    company_id: { _eq: $company_id },
    archived: { _eq: true },
    status: {_in: $statusFilter},
    _or: [
      {employee: {user: {name: {_ilike: $search}}}},
      {occupation_contracts: {occupation: {name: {_ilike: $search}}}}
    ]
  }) {
    id
    start_date
    end_date
    status
    archived
    employee {
      id
      user {
        avatar
        name
        id
        cpf_cnpj
      }
    }
    occupation_contracts(where: { active: { _eq: true } }) {
      id
      hour_value
      occupation {
        id
        name
        cbo
      }
      occupation_additional{
        id
        description
      }
    }
  }
}
`

export const SUBSCRIBE_CONTRACTS = gql`
  subscription contracts(
    $company_id: Int!,
    $limit: Int!,
    $offset: Int!,
    $search: String,
    ) {
    contract(
      offset: $offset,
      limit: $limit,
      where: { 
        company_id: { _eq: $company_id },
        archived: { _eq: false },
        employee: {user: {name: {_ilike: $search}}}
    }) {
      id
      start_date
      end_date
      status
      archived
      employee {
        id
        user {
          avatar
          name
          id
          cpf_cnpj
        }
      }
      occupation_contracts(where: { active: { _eq: true } }) {
        id
        hour_value
        occupation {
          id
          name
          cbo
        }
        occupation_additional{
          id
          description
        }
      }
    }
  }
`;

export const GET_COMPANY_CONTRACTS = gql`
  query contracts(
    $company_id: Int!,
    $limit: Int!,
    $offset: Int!,
    $search: String,
    $statusFilter: [String!]
    $order_by: contract_order_by!
    ) {
    contract(
      offset: $offset,
      limit: $limit,
      order_by:  [$order_by],
      where: { 
        company_id: { _eq: $company_id },
        archived: { _eq: false },
        status: {_in: $statusFilter},
        _or: [
          {employee: {user: {name: {_ilike: $search}}}},
          {occupation_contracts: {occupation: {name: {_ilike: $search}}}}
        ]
    }) {
      id
      start_date
      end_date
      status
      archived
      employee {
        id
        user {
          avatar
          name
          id
          cpf_cnpj
        }
      }
      occupation_contracts(where: { active: { _eq: true } }) {
        id
        hour_value
        occupation {
          id
          name
          cbo
        }
        occupation_additional{
          id
          description
        }
      }
    }
  }
`;

export const COUNT_COMPANY_CONTRACTS = gql`
  query counCompanyContracts(
    $company_id: Int!,
    $search: String,
    $statusFilter: [String!]
  ){
    contract_aggregate(
      where: { 
        status: {_in: $statusFilter},
        company_id: { _eq: $company_id },
        archived: { _eq: false },
        _or: [
          {employee: {user: {name: {_ilike: $search}}}},
          {occupation_contracts: {occupation: {name: {_ilike: $search}}}}
        ]
    }
    ){
      aggregate {
        count
      }
    }
  }
`

export const COUNT_ARCHIVED_COMPANY_CONTRACTS = gql`
  query counCompanyContracts(
    $company_id: Int!,
    $search: String,
    $statusFilter: [String!]
  ){
    contract_aggregate(
      where: { 
        status: {_in: $statusFilter},
        company_id: { _eq: $company_id },
        archived: { _eq: true },
        _or: [
          {employee: {user: {name: {_ilike: $search}}}},
          {occupation_contracts: {occupation: {name: {_ilike: $search}}}}
        ]
    }
    ){
      aggregate {
        count
      }
    }
  }
`

export const CREATE_CONTRACT = gql`
  mutation create_contract(
    $company_fantasy_name: String!,
    $company_id: Int!,
    $user_company_id: Int!,
    $user_id: uuid!,
    $employee_id: Int!,
    $hour_value: numeric!,
    $occupation_id: Int!,
    $occupation_additional_id: Int,
    $start_date: date!,
    $fields_to_replace: jsonb!,
    $dependents: jsonb!,
    $contract_bus_ticket: jsonb!,
    $version: String
  ){
    create_contract(
      user_company_id: $user_company_id, 
      user_id: $user_id,
      company_id: $company_id, 
      company_fantasy_name: $company_fantasy_name, 
      employee_id: $employee_id, 
      hour_value: $hour_value, 
      occupation_id: $occupation_id, 
      occupation_additional_id: $occupation_additional_id, 
      start_date: $start_date, 
      dependents: $dependents, 
      fields_to_replace: $fields_to_replace,
      contract_bus_ticket: $contract_bus_ticket,
      version: $version
    ) {
      id
    }
  }
`;



export const LOAD_CONTRACT_HISTORIC = gql`
query contract_historic($contract_id: Int!) {
  occupation_contract(where: {contract_id: {_eq: $contract_id}}, order_by: {id: desc}) {
    start_date
    id
    next_id
    occupation {
      name
      cbo
      id
    }
    contract {
      start_date
      end_date
      status
      id
      renounce_bus_ticket
      employee{
        id
        user{
          id
          name
          cpf_cnpj
          address{
            id
            place
            number
            complement
            neighborhood
            city{
              id
              name
              state{
                id
                sigla
              }
            }
          }
        }
      }
      company{
        id
        cnpj
        fantasy_name
        address{
          id
          place
          number
          complement
          neighborhood
          city{
            id
            name
            state{
              id
              sigla
            }
          }
        }
      }
    }
    occupation_additional {
      id
      description
    }
    hour_value
    contract_id
  }
  vacation(where: {contract_id: {_eq: $contract_id}}) {
    id
    start_date
    end_date
    company {
      id
      cnpj
      fantasy_name
      business_name
    }
    contract_id
    contract{
      id
      start_date
      occupation_contracts{
        id
        occupation{
          id
          cbo
          name
        }
        occupation_additional{
          id
          description
        }
      }
    }
    employee {
      id
      user {
        id
        name
        cpf_cnpj
      }
    }
    modification_date
    competence_year
  }

  contract_historic(
    where: {contract_id: {_eq: $contract_id}}
    order_by: { date: desc }
    ) {
    id
    contract_id
    data
    date
    type
  }
}

`;


export const LOAD_CONTRACT_HISTORIC_BY_EMPLOYEE_CPF = gql`
query contract_historic_by_employee_cpf(
  $cpf: String!,
  $company_id: Int!,
  $contract_send_date: timestamptz,
  $contract_end_date: timestamptz,
  $contract_id: Int!
  ) {
  occupation_contract(
    where: {contract: {employee: {user: {cpf_cnpj: {_eq: $cpf}}}, company_id: {_eq: $company_id}, status: {_eq: "Ativo"}}}
    ) {
    start_date
    id
    next_id
    occupation {
      cbo
      name
      id
    }
    contract {
      end_date
      status
      id
      employee {
        user {
          name
          cpf_cnpj
        }
        convocations(
          where: {
            company_id: {_eq: $company_id},
            date_time_send: {_gte: $contract_send_date, _lte: $contract_end_date}
          },
          order_by: { date_time_send: asc }
        ) {
          id
          status
          title
          date_time_send
          date_time_responde
          date_time_expiration
          workplace {
            id
            name
          }
          workdays {
            id
            start_hour
            start_interval
            end_hour
            date
            end_interval
          }
        }
      }
      contract_dependents {
        id
        name
        irrf
        birthday
        family_salary
      }
    }
    hour_value
    contract_id
  }

  contract_historic(
    where: {contract_id: {_eq: $contract_id}}
    order_by: { date: desc }
    ) {
    id
    contract_id
    data
    date
    type
  }
}`

export const LOAD_VACATIONS_BY_CONTRACT_ID = gql`
query getVacationsByContractId($contract_id: Int!) {
  vacation(where: {contract_id: {_eq: $contract_id}}) {
    contract_id
    end_date
    id
    modification_date
    start_date
    competence_year
  }
}`

export const UPDATE_CONTRACT = gql`
    mutation contract(
        $contract_id: Int!
        $contract: contract_set_input!
        $user_id: uuid!
        $notification_title: String!
        $notification_body: String!
        $action_data: jsonb
    ){
        update_contract(where: {id: {_eq: $contract_id}},
            _set: $contract
                   
            ) {
       affected_rows
       }
       insert_push_message(objects: 
        {
          action: contract,
          body: $notification_body
          title: $notification_title,
          action_data: $action_data
          
          push_message_users: 
            {
              data: {
                user_id: $user_id
              }}}) {
        affected_rows
      }
  }
`;


export const UPDATE_CONTRACT_ALTERNATIVE = gql`
        mutation updateContract(
          $contract_id: Int!
          $contract: contract_set_input!
        ) {
          update_contract(where: {id: {_eq: $contract_id}}, 
          _set: $contract
        ) {
        affected_rows
        }
        }
`;

export const COUNT_CONTRACT_ACTIVE_BY_COMPANY = gql`
    query COUNT_CONTRACT_ACTIVE_BY_COMPANY(
      $company_id: Int!,
      $status: String!
    ){
      contract_aggregate(
        where: {
          company_id: {_eq: $company_id}, 
          status: {_eq: $status}
        }) {
        aggregate {
          count(columns: id)
        }
      }
    }
`;

export const LOAD_CONTRACTS_INTO = gql`
  query loadContractsInfo(
    $company_id: Int!,
    $cpf_cnpj: String!
  ) {
    contract_aggregate(
      where: {
      _and: [
        {
          status: {
            _in: ["Reacusado", "Expirado", "Cancelado", "Encerrado"]
          }
        },
        {
          company_id: {
            _eq: $company_id
          }
        },
        {
          employee: {
            user: {
              cpf_cnpj: { _eq: $cpf_cnpj }
            }
          }
        }
      ]
    }
    ) {
      aggregate {
        max {
          end_date
        }
      }
    }
  }
`;

export const VALIDATE_POINT_REGISTER_BY_CONTRACT = gql`
  query VALIDATE_BY_STATUS_AND_DATE(
    $company_id: Int!,
    $currentDate: date!,
    $user_id: uuid!
  ) {
      contract(where:
        {
          employee: {id_user: {_eq: $user_id}},
          company_id: {_eq: $company_id},
          start_date: {_lte: $currentDate}
          status: {_eq: "Ativo"}
        }) {
          id
        }
    }
`;

export const INSERT_FIELDS_TO_REPLACE = gql`
mutation updateFieldsToReplace($data: jsonb!, $contract_id: Int!) {
  update_contract(where: {id :{_eq: $contract_id}}, _set: {fields_to_replace: $data}) {
    affected_rows
  }
}

`

export const LOAD_CONTRACT_TEMPLATE_AND_DATA = gql`
query LOAD_CONTRACT_TEMPLATE_AND_DATA($contract_id: Int) {
  contract(where: {id: {_eq: $contract_id}}) {
    id
    fields_to_replace
    contract_template {
      js_report_id
    }
  }
}

`

export const LOAD_REPORT_CONTRACT_LIST_DATA = gql`
query getContractListData($company_id: Int!, $status: [String!], $occupation: Int, $min_hour_value: numeric, $max_hour_value: numeric, $start_admission_date: date, $end_admission_date: date, $start_withdrawal_date: date, $end_withdrawal_date: date) {
  contract(where: {_and: [
   { status: { _in: $status }},
    {start_date: { _gte: $start_admission_date, _lte: $end_admission_date }},
    {end_date: { _gte: $start_withdrawal_date, _lte: $end_withdrawal_date }},
    {company_id: { _eq: $company_id }}
  ]}
  ){
    id
    status
    employee {
      id
      occupation
      user {
        id
        name
      }
    }
    start_date
    end_date
    occupation_contracts(
      order_by: {
        id: desc
      },
      where: {_and: [
      {hour_value: { _gte: $min_hour_value, _lte: $max_hour_value }},
      {occupation: { id: { _eq: $occupation } }
    }]}) {
      id
      occupation{
        id
        cbo
        name
      }
      occupation_additional{
        id
        occupation{
          id
          name
          cbo
        }
        description
      }
      hour_value
    }
  }
}

`

export const INSERT_CONTRACT_FILE = gql`
mutation file(
  $contract_id: Int!,
  $user_company_id: Int!,
  $name: String!,
  $path: String!,
  $size: numeric,
  $mimetype: String!,
  $extension: String!
){
  insert_contract_file(
    objects: {
      contract_id: $contract_id,
      name: $name,
      path: $path,
      size: $size,
      user_company_id: $user_company_id,
      mimetype: $mimetype,
      extension: $extension
    }) {
    affected_rows
  }
}

`

export const LOAD_ALL_CONTRACT_FILES = gql`
query contract_files(
  $contract_id: Int!,
  $user_company_id: Int!
){
  contract_file(where: {user_company_id: {_eq: $user_company_id}, contract_id: {_eq: $contract_id}}) {
    path
    name
    id
    size
    extension
    mimetype
  }
}

`

export const GET_CONTRACT_DEPENDENTS = gql`
  query getContractDependents($contract_id: Int!){
    contract_dependent(order_by: {id: asc}, where: {contract_id: {_eq: $contract_id}}){
      id
      name
      birthday
      family_salary
      irrf
    }
  }
`

export const UPDATE_CONTRACT_DEPENDENTS = gql`
  mutation updateContractDependents($contract_id: Int!, $dependents_array: [contract_dependent_insert_input!]!) {
    delete_contract_dependent(where:{contract_id: {_eq: $contract_id}}){
      affected_rows
    }
    insert_contract_dependent(objects: $dependents_array){
      affected_rows
    }
  }
`

export const DELETE_CONTRACT_DEPENDENT = gql`
  mutation deleteDependent($dependent_id: Int!) {
    delete_contract_dependent(where: {id: {_eq: $dependent_id}}) {
      affected_rows
    }
  }
`
export const INSERT_CONTRACT_DEPENDENT = gql`
  mutation insertDependents($dependent: [contract_dependent_insert_input!]!) {
    insert_contract_dependent(objects: $dependent) {
      returning {
        id
        name
        birthday
        contract_id
        irrf
        family_salary
      }
    }
  }
`

export const UPDATE_CONTRACT_DEPENDENT = gql`
  mutation updateDependent($dependent_id: Int!, $irrf: Boolean!, $family_salary: Boolean!) {
    update_contract_dependent(where: {id: {_eq: $dependent_id}}, _set: {irrf: $irrf, family_salary: $family_salary}){
      affected_rows
    }
  }
`

export const UPDATE_CONTRACT_RENOUNCE_AND_BUS_TICKETS = gql`
mutation updateContractRenounceAndBusTickets($contractId: Int!, $renounce: Boolean!, $busTickets: [contract_bus_ticket_insert_input!]!){
  update_contract(
    where: {
      id: {_eq: $contractId}
    },
    _set: {
      renounce_bus_ticket: $renounce,
    }
  ){
    affected_rows
  }
  delete_contract_bus_ticket(where: {contract_id: {_eq: $contractId}}){
    affected_rows
  }
  insert_contract_bus_ticket(
    objects: $busTickets
  ){
    affected_rows
  }
}
`


export const UPDATE_CONTRACT_RENOUNCE_BUS_TICKET = gql`
mutation updateContractRenounceAndBusTickets(
  $contractId: Int!,
  $renounce: Boolean!
  ){
  update_contract(
    where: {
      id: {_eq: $contractId}
    },
    _set: {
      renounce_bus_ticket: $renounce,
    }
  ){
    affected_rows
  }
 
}`


export const INSERT_CONTRACT_BUS_TICKET = gql`
  mutation insertContractBusTicket(
    $contract_id: Int!
    $start_date: date
    $choosen_option: Boolean!
  ){
    insert_contract_bus_ticket(objects: {
      contract_id: $contract_id,
      start_date: $start_date,
      choosen_option: $choosen_option
    }) {
      affected_rows
    }
  }
`


