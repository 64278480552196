import gql from "graphql-tag";

export const LOAD_NOTIFICATIONS = gql`
    subscription loadNotifications($company_id: Int!, $user_id: uuid!){
        notification(order_by: { date: desc },
            where: {
                _or: [
                    { company_id: {_eq: $company_id} },
                    { user_company: {id_user: {_eq: $user_id}} }
                ]
            },
            limit: 100){
                id
                title
                type
                data
                date
                description
            }
    }
`;

export const COUNT_NOTIFICATIONS = gql`
    subscription countNotifications($company_id: Int!, $user_id: uuid!){
        notification(
            where: {
                _or: [
                    { company_id: {_eq: $company_id} },
                    { user_company: {id_user: {_eq: $user_id}} }
                ]
            }){
                id
                date
            }
    }
`;

/**
 * , $startDate: timestamptz!, $endDate: timestamptz!, $type: String!,
        $company_id: Int!, $user_id: uuid!
 */

export const SEARCH_NOTIFICATIONS = gql`
   query($search: String, $startDate: timestamptz, $endDate: timestamptz, $types: [String!], $company_id: Int!, $user_id: uuid!) {
        notification(where: {
            _and: {
                _or: [
                    { title: { _ilike: $search } },
                    { description: { _ilike: $search } }
                ],
                _and: [
                    {date: {_gte: $startDate}},
                    {date: {_lte: $endDate}},
                    {
                    _or: [
                        { company_id: { _eq: $company_id } },
                        { user_company: { id_user: { _eq: $user_id } } }
                        ]
                    },
                    {type: {_in: $types}}
                ],
            }
        }) {
        id
        title
        type
        data
        date
        description
    }
}
`;

export const LOAD_LAST_SEEN = gql`
    query loadLastSeen($user_id: uuid!){
        notification_last_seen(where: {user_company: {id_user: {_eq: $user_id}}}) {
            id
            last_seen
        }
    }
`;

export const LOAD_LAST_SEEN_SUBS = gql`
    subscription loadLastSeen($user_id: uuid!){
        notification_last_seen(where: {user_company: {id_user: {_eq: $user_id}}}) {
            id
            last_seen
        }
    }
`;

export const UPSERT_LAST_SEEN = gql`
    mutation upsertLastSeen($user_company_id: Int!, $company_id: Int!){
        insert_notification_last_seen(objects: {
            company_id: $company_id,
            user_company_id: $user_company_id
        },
        on_conflict: {
            constraint: notification_last_seen_company_id_user_company_id_key,
            update_columns: last_seen
        }) {
            affected_rows
        }
    }
`;

