
import { SET_CONTRACT_INFO } from '../../actions/contract/contractAction';

const INITIAL_STATE = {
    contractID: null
}

function contractInfo(state = INITIAL_STATE, action){
    switch(action.type){
        case SET_CONTRACT_INFO:
            return{
                ...state,
                contractID: action.contractID
            }
        
        default:
            return state;
    }
}

export default contractInfo;


// import { SHOW_CONTRACT_INFO_TAB, LOAD_CONTRACT_INFO, SET_END_DATE } from '../../actions/contract/contractAction';

// const INITIAL_STATE = {
//     selectedEmployee: {},
//     detailTabHidden: true,
//     endDate: ''
// }

// function contractInfo(state = INITIAL_STATE, action) {

//     switch (action.type) {

//         case SHOW_CONTRACT_INFO_TAB:
//             return {
//                 ...state,
//                 detailTabHidden: false,
//                 selectedEmployee: action.selectedEmployee
//             };

//         case LOAD_CONTRACT_INFO:
//             return {
//                 ...state,
//                 selectedEmployee: action.selectedEmployee
//             };

//         case SET_END_DATE:
//             return {
//                 ...state,
//                 endDate: action.endDate
//             };


//         default:
//             return state;
//     }

// }


// export default contractInfo;
