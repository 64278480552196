import gql from "graphql-tag";

export const GET_POINT_REGISTER_VALUE = gql`
subscription getParameters($convocation_expires_type: String, $point_register: String, $company_id: Int!) {
  parameter(
    where: {
      company_id: {_eq: $company_id}, 
      _or: [
        {parameter: {_eq: $point_register}}, 
        {parameter: {_eq: $convocation_expires_type}}
      ]
    }) {
    value
  }
}
`

export const UPDATE_CONVOCATION_VALUE = gql`
mutation updateGeralParameterValueConvocation(
  $convocation_expires_type: String,
  $value_point: jsonb
) {
  update_parameter(
    where: { parameter: { _eq: $convocation_expires_type } }
    _set: { value: $value_point }
  ) {
    affected_rows
  }
}
`;

export const UPSERT_PARAMETERS_VALUE = gql`
mutation UpsertParamter(
  $company_id: Int!,
  $parameter: String!,
  $value: jsonb!
  ) {
  insert_parameter(objects: {company_id: $company_id, parameter: $parameter, value: $value},
					on_conflict: {constraint: parameter_pkey, update_columns: value}
			) {
    affected_rows
  }
}
`;

export const UPDATE_POINT_REGISTER_VALUE = gql`
mutation updateGeralParameterValue(
  $point_register: String,
  $value_point: jsonb
) {
  update_parameter(where: {parameter: {_eq: $point_register}}, _set: {parameter: $point_register, value: $value_point}) {
    affected_rows
  }
}
`