import moment from "moment";
import 'moment/locale/pt-br';
import { toast } from "react-toastify";
import { GET_COMPANY_FINANCIAL_INFO } from "../../../queries/plan_company";



export async function handleFreePaymentConfirm({ userInfo, setLoading, makePurchase, deleteSelectedPlanId, planId}){
    setLoading(true);
    try {
        const companyId = userInfo.company.id;
        const now = moment().format("YYYY-MM-DD");
        const paymentTransactionResquest = await makePurchase({
            variables: { 
                // user_id: userInfo.id, 
                plan_id: planId, 
                // company_id: userInfo.company.id 
            },
            refetchQueries: [
                {
                    query: GET_COMPANY_FINANCIAL_INFO,
                    variables: {
                        companyId: companyId,
                        now: now
                    }
                }
            ]
        });
        if (!paymentTransactionResquest.data) throw (paymentTransactionResquest.errors[0].message);
        const deleteSelectedPlanResponse = await deleteSelectedPlanId({
            variables: {
                company_id: userInfo.company.id
            }
        })
        window.location.href = "#/compra-sucesso";
    } catch (error) {
        console.log(error)
        toast['error']("Ocorreu um erro ao solicitar a transação.")
        setLoading(false);
    }
}