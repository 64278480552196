import gql from "graphql-tag";

export const LOAD_ALL_EMPLOYEES = gql`
  query employees {
    employee {
      id
      user {
        avatar
        name
        id
      }
      contracts {
        id
        occupation_contracts {
          id
          occupation {
            id
            name
          }
        }
      }
    }
  }
`;

export const FIND_EMPLOYEE_BY_CPF_CNPJ = gql`
  query findEmployeeByCpfCnpj(
    $cpf_cnpj: String!
    $company_id: Int!
  ) {
    employee(where: { user: { cpf_cnpj: { _eq: $cpf_cnpj } } }) {
      id
      contracts(where: {company_id: {_eq: $company_id}}) {
        id
        status
      }
      user {
        id
        name
        phone
        login
        email
        avatar
        cpf_cnpj
        address {
          id
          zipcode
          neighborhood
          complement
          number
          place
          city {
            id
            name
            state {
              id
              sigla
              country {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const LOAD_INFO = gql`
  subscription contracts(
    $contract_id: Int!
    $company_id: Int!
    ) {
    contract(where: {
      id: { _eq: $contract_id },
      company_id: { _eq: $company_id }
    },
      order_by: { start_date: desc }) {
      id
      start_date
      end_date
      status
      employee {
        id
        user{
          id
          name
          phone
          login
          email
          avatar
          cpf_cnpj
          address{
          id
          zipcode
          neighborhood 
          complement
          number
          place 
          city{
              id
              name
              state{
                  id
                  sigla
                  country{
                      id
                      name
                  }
              }
          }
      }
    }
      }
      contract_dependents {
        id
        birthday
        name
        irrf
        family_salary
      }
      contract_bus_tickets(
        order_by: { start_date: desc }
      ){
        id
        contract_id
        created_date
        start_date
        choosen_option
      }
      occupation_contracts(order_by: { active: desc }) {
        hour_value
        active
        next_id
        start_date
        id
        occupation {
          id
          cbo
          name
        }
        occupation_additional {
          id
          description
        }
      }
      company {
        id
        fantasy_name
        business_name
        cnpj
        user_companies{
          id
          user{
              id
              name
              cpf_cnpj
              
          }
        }
        address {
          id
          city {
            id
            name
            state {
              id
              name
            }
          }
          zipcode
          neighborhood
          number
          place
        }
        business_name
      }
    }
  }
`;

export const LOAD_EMPLOYEES_WITH_ACTIVE_CONTRACT_SUBSCRIPTION = gql`
  subscription loadEmployeesWithActiveContract($companyId: Int!) {
    employee(where: { contracts: { company_id: { _eq: $companyId }, contract_status: { status: { _eq: "Ativo" } } } }) {
      id
      user {
        id
        name
        avatar
      }
      contracts(
        where: {
          occupation_contracts: { active: { _eq: true } }
          contract_status: { status: { _eq: "Ativo" } }
          company_id: { _eq: $companyId }
        }
      ) {
        id
        start_date
        contract_dependents{
          id
          birthday
          name
          irrf
          family_salary
        }
        occupation_contracts(where: { active: { _eq: true } }) {
          id
          hour_value
          occupation_additional {
            id
            description
          }
          occupation {
            id
            name
            cbo
            occupation_additionals{
              id
              description
              company{
                id
                salary_additional{
                  id
                  percentage_extra_hour
                  percentage_night_additional
                }
              }
              type
              percentage
            }
          }
        }
      }
    }
  }
`;

export const LOAD_EMPLOYEES_WITH_ACTIVE_CONTRACT = gql`
  query loadEmployeesWithActiveContract($companyId: Int!) {
    employee(where: { contracts: { company_id: { _eq: $companyId }, contract_status: { status: { _eq: "Ativo" } } } }) {
      id
      user {
        id
        name
        avatar
      }
      contracts(
        where: {
          occupation_contracts: { active: { _eq: true } }
          contract_status: { status: { _eq: "Ativo" } }
          company_id: { _eq: $companyId }
        }
      ) {
        id
        occupation_contracts(where: { active: { _eq: true } }) {
          id
          occupation_additional {
            id
            description
          }
          occupation {
            id
            name
            cbo
          }
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_WORKDAYS = gql`
  query emplyeeWorkdays($company_id: Int!, $employee_id: Int!) {
    workday(
      where: {
        convocation: {
          employee_id: { _eq: $employee_id }
          status: { _in: ["Aceita", "Enviada"] }
          company_id: { _eq: $company_id }
        }
      }
    ) {
      id
      date
      start_hour
      end_hour
      convocation {
        id
        title
      }
    }
    vacation(
      where: {
        _and: [
          { company_id: { _eq: $company_id } }
          { employee_id: { _eq: $employee_id } }
          { contract: { status: { _eq: "Ativo" } } }
        ]
      }
    ) {
      id
      modification_date
      start_date
      end_date
      competence_year
      contract {
        id
        start_date
      }
    }
  }
`;

export const GET_EMPLOYEE_WORKDAYS_FOR_VACATION = gql`
  query emplyeeWorkdays($company_id: Int!, $employee_id: Int!, $start_date: date!, $end_date: date!) {
    acceptedWorkdays: workday(
      where: {
        convocation: {
          status: { _in: ["Aceita"] }
          employee_id: { _eq: $employee_id }
          company_id: { _eq: $company_id }
        }
        date: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      id
      date
      start_hour
      end_hour
      convocation {
        id
        title
      }
    }
    pendingWorkdays: workday(
      where: {
        convocation: {
          status: { _in: ["Aceita"] }
          employee_id: { _eq: $employee_id }
          company_id: { _eq: $company_id }
        }
        date: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      id
      date
      start_hour
      end_hour
      convocation {
        id
        title
      }
    }
  }
`;

export const GET_EMPLOYEE_WORKDAYS_BY_CPF = gql`
  query emplyeeWorkdaysByCpf(
    $company_id: Int!
    $employee_cpf: String!
    $start_date: date
    $end_date: date
    $workplace: String
  ) {
    workday(
      where: {
        convocation: {
          employee: { user: { cpf_cnpj: { _eq: $employee_cpf } } }
          status: { _in: ["Aceita", "Enviada"] }
          company_id: { _eq: $company_id }
          workplace: { name: { _eq: $workplace } }
        }
        date: { _gte: $start_date, _lte: $end_date }
      }
      order_by: { date: asc }
    ) {
      id
      date
      start_hour
      start_interval
      end_hour
      end_interval
      convocation {
        id
        journey_config_params
        workplace {
          id
          address {
            id
            city {
              id
              ibge
            }
          }
        }
      }
    }
    user(where: { login: { _eq: $employee_cpf } }) {
      id
      name
      cpf_cnpj
    }
    company(where: { id: { _eq: $company_id } }) {
      id
      business_name
      cnpj
      fantasy_name
      salary_additional {
        id
        percentage_extra_hour
        percentage_night_additional
      }
    }
  }
`;

export const GET_EMPLOYEE_POINTS_BY_WORKDAY = gql`
  subscription getEmployeeWorkdayPoints($start: timestamptz!, $end: timestamptz!, $employee_id: Int!) {
    point_record(where: { date_time: { _gt: $start, _lt: $end }, employee_id: { _eq: $employee_id } }) {
      date_time
      coordinates
      device_record_point_id
      employee_id
      id
      company_id
    }
  }
`;

export const GET_EMPLOYEE_POINTS_BY_WORKDAY_AND_CPF = gql`
  query getEmployeeWorkdayPoints($start: timestamptz!, $end: timestamptz!, $employee_cpf: String!, $company_id: Int!) {
    point_record(
      where: {
        date_time: { _gt: $start, _lt: $end }
        employee: { user: { cpf_cnpj: { _eq: $employee_cpf } } }
        company_id: { _eq: $company_id }
      }
      order_by: { date_time: asc }
    ) {
      date_time
      coordinates
      device_record_point_id
      employee_id
      id
      company_id
    }
  }
`;

export const GET_EMPLOYEE_VACATION_CALC = gql`
  query GetEmployeesVacationCalc($company_id: Int!) {
    vacation_calc(where: { vacation_days_available: { _gt: "0" }, company_id: { _eq: $company_id } }) {
      vacation_days_available
      vacation_days_overdue
      vacation_days_used
      employee {
        id
        user {
          id
          name
          cpf_cnpj
        }
      }
    }
  }
`;
