import { SET_JOURNEY_CONFIG } from '../../actions/convocation/convocationAction';


const INITIAL_STATE = {
    config_params: null
}


function journeyConfig(state = INITIAL_STATE, action){
    switch(action.type){
        case SET_JOURNEY_CONFIG:
        return {
            ...state,
            config_params: action.config
        }
    default:
        return state;
    }
}

export default journeyConfig;