import {
  SET_CANCEL_REASONS_FILTERS,
  SET_CONVOCATION_LIST_FILTERED,
  SET_FULL_CONVOCATION_LIST,
  SET_STATUS_FILTERS,
  SET_CHANGE_REASONS_FILTERS,
} from "../../actions/convocation/convocationListAction";

const INITIAL_STATE = {
  convocationList: [],
  filtered: [],
  status: [],
  cancelReasons: [],
  changeReasons: [],
};

function convocationListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FULL_CONVOCATION_LIST: {
      return {
        ...state,
        convocationList: action.convocationList,
      };
    }

    case SET_CONVOCATION_LIST_FILTERED: {
      return {
        ...state,
        filtered: action.filtered,
      };
    }

    case SET_STATUS_FILTERS: {
      return {
        ...state,
        status: action.status,
      };
    }
    case SET_CANCEL_REASONS_FILTERS: {
      return {
        ...state,
        cancelReasons: action.cancelReasons,
      };
    }
    case SET_CHANGE_REASONS_FILTERS: {
      return {
        ...state,
        changeReasons: action.changeReasons,
      };
    }

    default:
      return state;
  }
}

export default convocationListReducer;
