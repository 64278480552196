import React, { Fragment, lazy, Suspense } from 'react';
import Loader from 'react-loaders';
import { Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PermissionRouter from '../../Components/Permission/PermissionRouter';

const ValidateUser = lazy(() => import('../../pages/ValidateUser'));
const Contract = lazy(() => import('../../pages/Contract'));
const Settings = lazy(() => import('../../pages/Settings'));
const Convocation = lazy(() => import('../../pages/Convocation'));
const Users = lazy(() => import('../../pages/Users/index'));
const PunchClockDevices = lazy(() => import('../../pages/PunchClockDevices'));
const Groups = lazy(() => import('../../pages/Groups'));
const Notifications = lazy(() => import('../../pages/Notifications'));
const Workday = lazy(() => import('../../pages/Workday'));
const Dashboard = lazy(() => import('../../pages/Dashboard'));
const ContractHistoricReport = lazy(() => import('../../pages/Reports/ContractHistoricReport/Page'));
const ContractListReport = lazy(() => import('../../pages/Reports/ContractList/Page'))
const ConvocationsReport = lazy(() => import('../../pages/Reports/Convocations/Page'))
const FrequencyReport = lazy(() => import('../../pages/Reports/Frequecy/Page'));
const Plans = lazy(() => import('../../pages/Plans'))
const MyAccount = lazy(() => import('../../pages/MyAccount'));
const Invoice = lazy(() => import('../../pages/Invoice'));
const PlanSelectionInternal = lazy(() => import('../../pages/PlanSelection/PlanSelectionInternal'));
const PurchaseSummaryInternal = lazy(() => import('../../pages/PurchaseSummary/PurchaseSummaryInternal'));
const TransactionSuccessPage = lazy(() => import('../../pages/PurchaseSummary/TransactionSuccessPage'));
const AdditionalCreditSelection = lazy(() => import('../../pages/PlanSelection/AdditionalCreditSelection'));


function PageFallback() {
    return (
        <div className="loader-container">
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader type="ball-pulse-rise" />
                </div>
            </div>
        </div>
    );
}

const AppMain = () => {

    return (
        <Fragment>

            {/* Contract */}

            <Suspense fallback={<PageFallback/>}>
                <Route exact path="/contratos" component={Contract} />
                {/* <Route exact path="/contratos/:status" component={Contract} /> */}
                <Route exact path="/contratos/arquivados" component={Contract} />
                <Route path="/contratos/detalhes/:contractId" component={Contract} />
                <Route exact path="/contratos/novo" component={Contract} />
            </Suspense>

            {/* Settings */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/configuracoes" component={Settings} />
            </Suspense>

            {/* Planos */}

            <PermissionRouter permission="right-menu:adm" >
                <Suspense fallback={
                     <PageFallback/>
                }>

                    <Route path="/adm-planos" component={Plans} />
                </Suspense>
            </PermissionRouter>

            {/* Plan Selection */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/planos" component={() => <PlanSelectionInternal />} />
            </Suspense>


            {/* Compra de Créditos Adicionais*/}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/creditos-adicionais" component={() => <AdditionalCreditSelection />} />
            </Suspense>



            {/* RESUMO DE COMPRA */}
            <Suspense fallback={<PageFallback/>}>
                <Route exact path="/resumo-compra/:planId" component={() => <PurchaseSummaryInternal />} />
            </Suspense>


            {/* FEEDBACK COMPRA SUCESSO */}
            <Suspense fallback={<PageFallback/>}>
                <Route exact path="/compra-sucesso" component={() => <TransactionSuccessPage />} />
            </Suspense>


            {/* Notas */}
            <PermissionRouter permission="right-menu:adm" >
                <Suspense fallback={
                      <PageFallback/>
                }>

                    <Route path="/notas" component={Invoice} />
                </Suspense>
            </PermissionRouter>

            {/* Users */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/usuarios" component={Users} />
            </Suspense>


            {/* Pontos */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/ponto" component={PunchClockDevices} />
            </Suspense>

            {/* Chamadas */}

            <Suspense fallback={<PageFallback/>}>

                {/* <Route exact path="/convocacoes" component={Convocation} /> */}
                <Route exact path="/convocacoes" component={Convocation} />
                <Route exact path="/convocacoes/nova" component={Convocation} />
                <Route exact path="/convocacoes/detalhes/:convocationGroupId" component={Convocation} />
                <Route exact path="/convocacoes/atualizar/:convocationGroupId" component={Convocation} />
            </Suspense>

            {/* Jornadas */}

            <Suspense fallback={<PageFallback/>}>
                <Route exact path="/jornadas" component={Workday} />
                <Route exact path="/jornadas/detalhes/:workdayId" component={Workday} />
                <Route exact path="/jornadas/recibo/:workdayId/:employeeId" component={Workday} />
                {/* <Route exact path="/jornada/:workdayId" component={Workday} /> */}
            </Suspense>


            {/* Grupos */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/grupos" component={Groups} />
            </Suspense>


            {/* Notificações */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/notificacoes" component={Notifications} />
            </Suspense>


            {/* Validar Acesso */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/validar-acesso" component={ValidateUser} />
            </Suspense>

            {/* Dashboard */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/home" component={Dashboard} />
            </Suspense>

            {/* Relatório - Histórico de Contrato */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/relatorio-historico-contrato" component={ContractHistoricReport} />
            </Suspense>

            {/* Relatório - Lista de Contratos */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/relatorio-lista-contratos" component={ContractListReport} />
            </Suspense>

            {/* Relatório - Convocações */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/relatorio-convocacoes" component={ConvocationsReport} />
            </Suspense>

            {/* Relatório - Frequência */}

            <Suspense fallback={<PageFallback/>}>
                <Route path="/relatorio-frequencia" component={FrequencyReport} />
            </Suspense>

            {/* Minha Conta */}

            <Suspense fallback={<PageFallback/>}>

                <Route exact path="/minha-conta" component={MyAccount} />
            </Suspense>


            <Route exact path="/" render={() => (
                <Redirect to="/validar-acesso" />
            )} />

            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;