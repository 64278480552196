import gql from "graphql-tag";

export const GET_INVOICES = gql`
subscription getInvoices{
  invoice{
    id
    month_competence
    year_competence
    plan_company{
      id
      name
      description
      price
      price_description
      validity_end
      created_date
      month_count
      type
      price
      price_description
      paid
    }
    company{
      id
      business_name
      cnpj
      fantasy_name
    }
    file_upload{
      id
      extension
      mimetype
      name
      path
      size
    }
  }
}
`

export const GET_INVOICES_BY_COMPANY = gql`
    subscription getInvoices($companyId: Int!){
        invoice(where: {
          _and: [
            { company_id: {_eq: $companyId} },
            {file_upload: {_not: {_not: null}}}
          ]
        }){
          id
          month_competence
          year_competence
          created_at
          company_id
          company{
            id
            business_name
            cnpj
            fantasy_name
          }
          file_id
          file_upload{
            id
            extension
            mimetype
            name
            path
            size
          }
        }
      }
`

export const INSERT_INVOICE = gql`
mutation insertInvoiceNote($data: [invoice_insert_input!]!){
  insert_invoice(objects: $data){
    affected_rows
  }
}
`

export const GET_INVOICE_PLAN_COMPANY = gql`
query getPlanCompany($planCompanyId: Int!, $companyId: Int!){
  plan_company(where: {id: {_eq: $planCompanyId}, company_id: {_eq: $companyId}}){
   	id
    price
    price_description
    month_count
    validity_end
    created_date
    name
    description
    company{
      id
      cnpj
      business_name
      fantasy_name
      email
      address{
        id
        neighborhood
        zipcode
        place
        number
        city{
          id
          name
          state{
            id 
            name
          }
        }
      }
    }
  }
  constant(where: {key: {_in:[ 
  	"SYMEE_NAME",
    "SYMEE_CNPJ",
    "SYMEE_ADDRESS_ZIP_CODE",
    "SYMEE_ADDRESS_COUNTRY",
    "SYMEE_ADDRESS_STATE",
    "SYMEE_ADDRESS_CITY",
    "SYMEE_ADDRESS_STREET",
    "SYMEE_ADDRESS_NEIGHBORHOOD",
    "SYMEE_ADDRESS_NUMBER",
    "SYMEE_ADDRESS_COMPLEMENT"
  ]}}){
    id
    key
    value
  }
}
`