import React, { Fragment, useContext } from "react";
import { UncontrolledTooltip } from "reactstrap";


import { UserInfoContext } from "../../context/UserInfoProvider";

/* 
    resource:String
    hideOnDenied:boolean
    disableOnDenied:boolean
    onDeniedProps:Object
    textHelper: String
    helperDirection: String
    childrenId: String
*/

export default function PlanRouter(props) {

    const userInfo = useContext(UserInfoContext);

    let grant = false;

    
    userInfo.company.plan_company_resources.forEach(plan_company_resource => {
        let resource = plan_company_resource.system_resource.name;
        
        if (resource === props.resource) {
            grant = true;
            return;
        }

    });

    if (props.hideOnDenied) {
        return (
            <Fragment>
                {
                    grant ? props.children : null
                }
            </Fragment>
        )
    }

    if (props.disableOnDenied) {
        return (
            <Fragment>
                {
                    grant ? props.children : React.cloneElement(props.children, { ...props.onDeniedProps, disabled: true })
                }

                {
                    props.textHelper ?
                        <UncontrolledTooltip
                            placement={props.helperDirection ? props.helperDirection : "right"}
                            target={props.childrenId}>
                            {props.textHelper}
                        </UncontrolledTooltip>
                        : null
                }
            </Fragment>
        )
    }

    return (
        <Fragment>
            {
                grant ? props.children : React.cloneElement(props.children, props.onDeniedProps)
            }
            {
                props.textHelper ?
                    <UncontrolledTooltip
                        placement={props.helperDirection ? props.helperDirection : "right"}
                        target={props.childrenId}>
                        {props.textHelper}
                    </UncontrolledTooltip>
                    : null
            }
        </Fragment>
    )

};
