
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";
import 'moment/locale/pt-br';
import React, { useContext, useState } from "react";
import Cards from 'react-credit-cards';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { Alert, Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { UserInfoContext } from '../../../context/UserInfoProvider';
import { DELETE_COMPANY_SELECTED_PLAN_ID } from "../../../queries/company";
import { MAKE_PURCHASE, SAVE_CREDIT_CARD } from "../../../queries/payment";
import { GET_COMPANY_FINANCIAL_INFO } from "../../../queries/plan_company";

export default
    function PurchaseForm({ planId, setLoading }) {
    const userInfo = useContext(UserInfoContext)
    const [cvc, setCvc] = useState('');
    const [brand, setBrand] = useState('')
    const [expiry, setExpiry] = useState('');
    const [focus, setFocus] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [requestError, setRequestError] = useState(null)
    const [isCardValid, setIsCardValid] = useState(false)
    const [saveCreditCard] = useMutation(SAVE_CREDIT_CARD);
    const [makePurchase] = useMutation(MAKE_PURCHASE)
    const [deleteSelectedPlanId] = useMutation(DELETE_COMPANY_SELECTED_PLAN_ID)

    const { register, handleSubmit, errors } = useForm();

    function cardChangeCallback(type, isValid) {
        if (!isValid) return setIsCardValid(false)
        setBrand(type.issuer)
        setIsCardValid(true)
    }

    function formatBrand(brand) {
        switch (brand) {
            case "mastercard":
                return "Master"
            case "visa":
                return "Visa";
            case "amex":
                return "Amex";
            case "elo":
                return "Elo";
            case "jcb":
                return "JCB";
            case "dinersclub":
                return "Diners";
            case "discover":
                return "Discover"
            case "hipercard":
                return "Hipercard";
            default:
                return null;
        }
    }

   

    async function handlePaymentConfirm(form) {
        if (!isCardValid) return setRequestError("Insira um cartão válido.")

        setRequestError(null)
        setLoading(true);
        const card_brand = formatBrand(brand)
        if (!card_brand) return setRequestError("Bandeira não é válida para esta transação.")

        const requestBody = {
            card_number: form.number.split(" ").join(""),
            card_holder: form.name,
            card_expiration: form.expiry.slice(0, 3) + "20" + form.expiry.substring(3),
            card_security_code: form.cvc,
            card_brand: card_brand,
            // user_id: userInfo.id,
            user_name: userInfo.name,
            // company_id: userInfo.company.id
        }

        console.log("CARDTOKENIZATION REQUEST>>>>", requestBody)
        try {
            // const cardTokenizationResponse = await Axios.post(URL_PAYMENT_CONTROLLER+"/card-tokenization", requestBody, {headers: {"Content-Type": "application/json"}});  
            const cardTokenizationResponse = await saveCreditCard({
                variables: requestBody
            })
            console.log("SAVE CREDIT CARD", cardTokenizationResponse)
            if (!cardTokenizationResponse.data) return setRequestError(cardTokenizationResponse.errors[0].message);

            console.log("REQUEST BODY makae PUERCAHSE", { user_id: userInfo.id, plan_id: planId, company_id: userInfo.company.id })

            const companyId = userInfo.company.id;
            const now = moment().format("YYYY-MM-DD");
            const paymentTransactionResquest = await makePurchase({
                variables: {
                    // user_id: userInfo.id,
                    plan_id: planId, 
                    // company_id: userInfo.company.id 
                },
                refetchQueries: [
                    {
                        query: GET_COMPANY_FINANCIAL_INFO,
                        variables: {
                            companyId: companyId,
                            now: now
                        }
                    }
                ]
            });
            console.log("PAYMENT RESPONSE===>", paymentTransactionResquest)
            if (!paymentTransactionResquest.data) throw (paymentTransactionResquest.errors[0].message);

            const deleteSelectedPlanResponse = await deleteSelectedPlanId({
                variables: {
                    company_id: userInfo.company.id
                }
            })
            console.log(deleteSelectedPlanResponse)
            window.location.href = "#/compra-sucesso";

        } catch (error) {
            console.log("ERRR", error)
            setRequestError("Ocorreu um erro ao solicitar a transação.")
            setLoading(false);
        }
    }

    function isValidExpiryDate(value) {

        value = value.split('_').join('');

        if (value.length != 5) return false;

        if (!moment(`01/${value}`, 'DD/MM/YY').isValid) return true;

        return moment(`01/${value}`, 'DD/MM/YY').isSameOrAfter(moment(), 'months');

    }

    return (
        <Card>
            <CardBody>

                <div id="PaymentForm">

                    <Cards
                        cvc={cvc}
                        expiry={expiry}
                        focused={focus}
                        name={name}
                        number={number}
                        callback={cardChangeCallback}
                        placeholders={{ name: 'SEU NOME' }}
                        locale={{ valid: 'Validade' }} />
                    {
                        requestError && <Alert className="mt-4" color="danger" >{requestError}</Alert>
                    }
                    <FormGroup className="mt-4">
                        <Label>Nome no Cartão</Label>
                        <Input
                            className="form-control"
                            type="text"
                            name="name"
                            id="name"
                            onChange={e => setName(e.target.value)}
                            onFocus={e => setFocus(e.target.name)}
                            innerRef={register({ required: true })} />
                        {
                            errors.name &&
                            <FormFeedback>É necessário informar o nome</FormFeedback>
                        }
                    </FormGroup>

                    <FormGroup>
                        <Label>Número do Cartão</Label>
                        <ReactInputMask
                            className="form-control"
                            mask="9999 9999 9999 99999999"
                            maskPlaceholder=""
                            onChange={e => setNumber(e.target.value)}
                            onFocus={e => setFocus(e.target.name)}
                            innerRef={register({ required: true, minLength: 15 })}
                            invalid={errors.number && true} >
                            <Input
                                type="tel"
                                name="number"
                                id="number" />
                        </ReactInputMask>
                        {
                            errors.number && errors.number.type === "required" &&
                            <FormFeedback>É necessário informar o número do cartão</FormFeedback>
                        }
                        {
                            errors.number && errors.number.type !== "required" &&
                            <FormFeedback>O número do cartão parece inválido</FormFeedback>
                        }
                    </FormGroup>

                    <Row>
                        <Col md="5" >
                            <FormGroup>
                                <Label>Data de Expiração</Label>
                                <ReactInputMask
                                    className="form-control"
                                    mask="99/99"
                                    onChange={e => setExpiry(e.target.value)}
                                    onFocus={e => setFocus(e.target.name)}
                                    innerRef={register({
                                        required: true,
                                        minLength: 5,
                                        validate: {
                                            valid: value => isValidExpiryDate(value)
                                        }
                                    })}
                                    invalid={errors.expiry && true} >
                                    <Input
                                        type="tel"
                                        name="expiry"
                                        id="expiry" />
                                </ReactInputMask>
                                {
                                    errors.expiry && errors.expiry.type === "required" &&
                                    <FormFeedback>É necessário informar a data de expiração</FormFeedback>
                                }
                                {
                                    errors.expiry && errors.expiry.type !== "required" &&
                                    <FormFeedback>Data de vencimento inválida</FormFeedback>
                                }
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Código de Segurança (CVC/CVV)</Label>
                                <ReactInputMask
                                    className="form-control"
                                    mask="9999"
                                    maskPlaceholder=""
                                    onChange={e => setCvc(e.target.value)}
                                    onFocus={e => setFocus(e.target.name)}
                                    innerRef={register({ required: true })}
                                    invalid={errors.cvc && true} >
                                    <Input
                                        type="tel"
                                        name="cvc"
                                        id="cvc" />
                                </ReactInputMask>
                                {
                                    errors.cvc &&
                                    <FormFeedback>É necessário informar o código de segurança</FormFeedback>
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button style={{cursor: "pointer"}} className="w-100" color="primary" onClick={handleSubmit(handlePaymentConfirm)} >FINALIZAR COMPRA</Button>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>

    )
}