
import { faCheck, faTimes } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { UserInfoContext } from '../../../context/UserInfoProvider'
import SweetAlert from 'sweetalert-react';

export default function PlanCard({ plan, actualPlanId, md, sm, lg, onContractClick, currentUserContractsCount }) {

    const [showNotification, setShowNotification] = useState(false);
    const actualPlan = plan.id === actualPlanId;
    const resources = plan.resources || [];

    function handleContractButton() {
        if (plan.contract_count < currentUserContractsCount) {
            setShowNotification(true);
            return;
        }

        onContractClick(plan.id)
    }

    return (
        <>
            <SweetAlert
                title="Atenção!"
                confirmButtonColor=""
                show={showNotification}
                text={`Você não pode contratar este plano, pois atualmente sua empresa possui ${currentUserContractsCount} contratos ativos. O plano selecionado permite o controle de apenas ${plan.contract_count} empregados!`}
                type="warning"
                confirmButtonText="Ok"
                onConfirm={() => {
                    setShowNotification(false);
                }}
                // onCancel={() => {
                //     setShowNotification(false);
                // }}
            />
            <Col sm={sm ? sm : "8"} md={md ? md : "5"} lg={lg ? lg : "3"} className="mb-4">
                <Card className={actualPlan ? "mb-4 border border-dark" : ""}>
                    <CardBody style={{ textAlign: "center", minHeight: "60vh" }} className={`d-flex flex-column justify-content-start align-items-center`} >
                        <Row>
                            <Col>
                                <h6 className="font-weight-bold" > {plan.name.toUpperCase()} </h6>
                                <label dangerouslySetInnerHTML={{ __html: plan.description }} ></label>
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-end  mb-3 " >
                            <Col>
                                <spam
                                    style={{ fontSize: "2.0em", fontWeight: "600" }}
                                    dangerouslySetInnerHTML={{ __html: plan.price_description }} >
                                </spam>
                                <spam
                                    dangerouslySetInnerHTML={{ __html: plan.period_description }} >
                                </spam>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col>
                                {
                                    actualPlan ?
                                        <Button color="secondary mb-2 p-2" disabled style={{ width: "90%" }} >Plano Atual</Button>
                                        :

                                        plan.type === "CUSTOM" ?
                                            <CustomPlanContractButton />
                                            :
                                            <Button color="primary mb-2 p-2" style={{ width: "90%" }} onClick={handleContractButton} >
                                                Contratar
                                        </Button>
                                }
                            </Col>
                        </Row>

                        {
                            resources.filter(resource => resource.visible).map(rs => {
                                return (
                                    <Row key={rs.id} className="mt-2" style={{ width: "100%" }}>
                                        <Col>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <div style={{ backgroundColor: "#e2e2e2", width: "32px", padding: "6px", borderRadius: 50, }}>
                                                            {
                                                                rs.planIncludes ?
                                                                    <FontAwesomeIcon icon={faCheck} color="#14870c" style={{ fontSize: "16px" }} />
                                                                    :
                                                                    <FontAwesomeIcon icon={faTimes} color="#d12727" style={{ fontSize: "16px" }} />
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="m-0 ml-3 text-left" id={`res-${rs.id}`}> {rs.description} </p>
                                                        <UncontrolledTooltip placement="right" target={`res-${rs.id}`} >
                                                            {rs.helper}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>
                                            </table>
                                        </Col>
                                    </Row>
                                )
                            })
                        }

                    </CardBody>
                </Card>
            </Col>
        </>

    )
}

function CustomPlanContractButton() {
    const userInfo = useContext(UserInfoContext)
    return (
        <Button color="primary mb-2 p-2" style={{ width: "90%" }} onClick={() => window.open(`https://api.whatsapp.com/send?phone=554741084108&text=Olá, meu nome é ${userInfo.name}, da empresa ${userInfo.company.business_name}, e gostaria de mais informações sobre o plano personalizado do sistema da Symee. `)} >
            Contate-nos
        </Button>
    )
}