import gql from "graphql-tag";

export const GET_HOLYDAYS_BY_MONTH = gql`
  query GetHolydaysByMonth($year: Int!, $month: Int!, $city: String!) {
    holiday(year: $year, month: $month, city: $city) {
      date
      name
      type
    }
  }
`;

export const  GET_HOLYDAYS_BY_IBGE = gql`
  query GetHolydaysByMonth($year: Int!, $month: Int, $ibge: Int!) {
    holiday(year: $year, month: $month, ibge: $ibge) {
      date
      name
      type
    }
  }
`;
