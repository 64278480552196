import { ADD_EMPLOYEE, REMOVE_EMPLOYEE, ADD_ERROR, REMOVE_ERROR, CLEAR_ERRORS } from "../../actions/convocation/employeeAction";
import { RESET_CONVOCATION } from "../../actions/convocation/convocationAction";


const INITIAL_STATE = {
    selectedEmployees: [],
    fullCostPreview: 0,
    errors: []
};

function employeeReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case RESET_CONVOCATION: {
            return INITIAL_STATE;
        }

        case ADD_EMPLOYEE: {
            return {
                ...state,
                selectedEmployees: [...state.selectedEmployees, action.employee]
            };
        }

        case REMOVE_EMPLOYEE: {
            const newArray = state.selectedEmployees.filter(employee => employee.id !== action.id)
            const newErrors = state.errors.filter(err=> err.empId !== action.id)
        
            return {
                ...state,
                selectedEmployees: newArray,
                errors: newErrors
            }
        }

        case ADD_ERROR: {
            return {
                ...state,
                errors: [...state.errors, action.error]
            }
        }

        case REMOVE_ERROR: {
            const newErrors = state.errors.filter(error => error.index !== action.index);
            return {
                ...state,
                errors: newErrors
            }
        }

        case CLEAR_ERRORS:{
            const newErrors = [];
            return {
                ...state,
                errors: newErrors
            }
        }

        default:
            return state;
    }
};

export default employeeReducer;