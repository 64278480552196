export const RESET_CONVOCATION = 'RESET_CONVOCATION';
export const SET_JOURNEY_CONFIG = 'SET_JOURNEY_CONFIG';
export const SET_FULL_COST_PREVIEW = 'SET_FULL_COST_PREVIEW';

export const resetConvocation = () => ({
    type: RESET_CONVOCATION
});

export const setJourneyConfig = (config) => ({
    type: SET_JOURNEY_CONFIG,
    config
});

export const setFullCostPreview = (cost) => ({
    type: SET_FULL_COST_PREVIEW,
    cost
});