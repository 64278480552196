import gql from "graphql-tag";

export const SAVE_CREDIT_CARD = gql`
mutation saveCreditCard(
    $card_number: String!, 
    $card_holder: String!, 
    $card_expiration: String!, 
    $card_brand: String!, 
  	$card_security_code: String!, 
  
    $user_name: String!,
    
) {
    card_tokenization(
      card_number: $card_number, 
      card_holder: $card_holder, 
      card_expiration: $card_expiration, 
      card_brand: $card_brand, 
      card_security_code: $card_security_code, 

      user_name: $user_name, 

    ) {
      data
      error
      message
      success
    }
  
  }
`


export const MAKE_PURCHASE = gql`
mutation makePurchase($plan_id: Int!){
    make_purchase(
     
      plan_id: $plan_id

    ){
      data
      error
      success
      message
    }
  }
`

export const GET_PAYMENT_LOGS_BY_COMPANY = gql`
subscription getPaymentLogs($companyId: Int!){
  payment_log(
    where: {
      _and: [
        {company_id: {_eq: $companyId}},
        {plan: {_not: null}}
      ]
    }
  ){
    id
    message
    success
    error
    created_at
    plan{
      id
      contract_count
      convocation_count
      description
      month_count
      name
      validity_start
      validity_end
      type
      system_visible
      site_visible
      price
      price_description  
    }
    payment_query_links{
      id
      href
      method
      rel
    }
  }
}
`