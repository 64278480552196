import { useQuery } from '@apollo/react-hooks';
import { formatToBRL } from 'brazilian-values';
import moment from 'moment';
import React, { Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Card, Col, Row } from 'reactstrap';
import { UserInfoContext } from '../../context/UserInfoProvider';
import { GET_INVOICE_PLAN_COMPANY } from "../../queries/invoice";
import { formatDate } from '../../util/dateUtil';

export default function InvoiceNote() {

    const { planCompanyId } = useParams()
    const {company} = useContext(UserInfoContext)

    if (!planCompanyId) return <div>Não foi possível gerar Fatura</div>

    const { data, loading, error } = useQuery(GET_INVOICE_PLAN_COMPANY, {
        variables: {
            planCompanyId,
            companyId: company.id
        }
    })

    if (error) {
        console.log(error);
        return "Ocorreu um erro";
    }

    if (loading) {
        return "Carregando..."
    }
    console.log(data)
    const planCompany = data.plan_company[0];
    if (!planCompany) return "Fatura indiponível"
    
    const constants = data.constant;
    const address = planCompany.company.address ? planCompany.company.address : {}
    
    return (
        <div style={{ backgroundColor: "#F1F4F6", width: "100%", height: "100%" }} >

            <Col className="pt-4" >
                <Card className="p-3">
                    <Col>
                        <Row>
                            <Col>
                                <h2 style={{ fontWeight: 700 }} >Fatura #{planCompany.id}</h2>
                                <Badge color="success mb-2" style={{ fontSize: 14 }} > PAGA </Badge>

                                <P className="mb-1">Vencimento: {moment(planCompany.created_date).format("DD/MM/YYYY")}</P>
                                <P className="mb-1">Paga com: Cartão de crédito</P>
                                <P className="mb-3">Parcela 1 de 1</P>

                                <h5 style={{ fontWeight: 600 }} className="mt-3 mb-2">{planCompany.company.fantasy_name}</h5>
                                <P> {planCompany.company.cnpj} </P>
                                {
                                    address.place && (
                                        <Fragment>
                                            <P> {address.place} </P>
                                            <P> {address.zipcode}, {address.city ? address.city.name : ""}, {address.city ? address.city.state.name : ""} </P>
                                        </Fragment>
                                    )
                                }
                            </Col>
                            <Col className="text-right" >
                                {
                                    ///Dados da Symee
                                }
                                <img alt="" src={require("../../assets/images/syme-logo.png")} style={{ width: "150px" }} />
                                <h5 style={{ fontWeight: 600 }} className="mt-3 mb-2">{constants.find(c => c.key === 'SYMEE_NAME').value}</h5>
                                <P>{constants.find(c => c.key === 'SYMEE_CNPJ').value}</P>
                                <P>{constants.find(c => c.key === 'SYMEE_ADDRESS_STREET').value}, {constants.find(c => c.key === 'SYMEE_ADDRESS_NUMBER').value}, {constants.find(c => c.key === 'SYMEE_ADDRESS_COMPLEMENT').value}, {constants.find(c => c.key === 'SYMEE_ADDRESS_NEIGHBORHOOD').value}</P>
                                <P>{constants.find(c => c.key === 'SYMEE_ADDRESS_ZIP_CODE').value}, {constants.find(c => c.key === 'SYMEE_ADDRESS_CITY').value}, {constants.find(c => c.key === 'SYMEE_ADDRESS_STATE').value}, {constants.find(c => c.key === 'SYMEE_ADDRESS_COUNTRY').value}</P>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-2">
                            <div className="m-2 w-100" style={{ borderRadius: 8, border: "1px solid #d3d3d3" }} >
                                <Row className="p-2 m-0" style={{ backgroundColor: "#f1f4f6", borderRadius: "8px 8px 0 0", fontSize: 16, borderBottom: "2px solid #d3d3d3" }}>
                                    <Col>Itens</Col>
                                    <Col> Período </Col>
                                    <Col className="text-right" > Valores </Col>
                                </Row>
                                <Row className="p-2 pt-3 pb-3 m-0">
                                    <Col className="font-weight-bold" >{planCompany.name}</Col>
                                    <Col>{moment(planCompany.created_date).format("DD/MM/YYYY")} a {formatDate(planCompany.validity_end)}</Col>
                                    <Col className="text-right"> {formatToBRL(planCompany.price)} </Col>
                                </Row>
                                <Row className="p-2 m-0" style={{ backgroundColor: "#f1f4f6", borderRadius: "0 0 8px 8px", borderTop: "2px solid #d3d3d3" }}>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col className="text-right font-weight-bold"> Valor Bruto Total: {formatToBRL(planCompany.price)}  </Col>
                                </Row>
                            </div>

                        </Row>
                        <Row>
                            {
                                ///Valor Total
                            }
                            <Col className="text-right" >
                                <P>Impostos retidos: (-){formatToBRL(0)}</P>
                                <h5 style={{ fontWeight: 700 }} >Total a pagar: {formatToBRL(planCompany.price)} </h5>
                            </Col>
                        </Row>
                    </Col>
                </Card>
            </Col>

        </div>
    )
}

///Custom Label with no bottom margin
function P(props) {
    return <p {...props} className={`m-0 ${props.className}`} style={{ color: "#000", fontSize: 16, ...props.style }} >
        {props.children}
    </p>
}