import { authService } from "./auth"
import axios from 'axios'
import { URL_FILE_SERVER } from "../config/config"

const upload = async (type, file, userId) => {

    return await uploadWithExtraHeaders(type, file, {'userid': userId});

}

const uploadWithExtraHeaders = async (type, file, extraHeaders) => {

    if ( file.size > 15728640 ) {
        throw new Error('O arquivo excede o tamanho permitido')
    }
    const formData = new FormData();

    formData.append('file', file)
    return await axios.post(
        `${URL_FILE_SERVER}/storage/upload`,
        formData,
        {
            headers: {
                'Authorization': `Bearer ${authService.currentTokenValue}`,
                'Content-Type': 'multipart/form-data',
                'type': type,   
                ...extraHeaders         
            }
        }
    )

}

const unauthenticatedDownload = (key) => {
    return `${URL_FILE_SERVER}/storage/download/${ key }`
}

const authenticatedDownload = async (key) => {
    console.log(`${URL_FILE_SERVER}/storage/download/${ key }`);
    return await axios.get(
        `${URL_FILE_SERVER}/storage/download/${ key }`,
        {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${authService.currentTokenValue}`,
            }
        }
    )
}

export const fileUploadService = {
    upload,
    uploadWithExtraHeaders,
    unauthenticatedDownload,
    authenticatedDownload
}