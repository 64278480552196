import moment from 'moment'

import {
    ADD_ITEM,
    REMOVE_ITEM,
    REMOVE_ITEM_BY_INDEX,
    SET_START_TIME,
    SET_START_INTERVAL_TIME,
    SET_END_INTERVAL_TIME,
    SET_END_TIME,
    ADD_ERRO,
    CLEAR_ERRORS,
    SET_LINE_ERROR,
    ADD_WARNING,
    REMOVE_WARNING,
    CLEAR_DAY_ERRORS
} from "../../actions/convocation/workdayAction";
import { RESET_CONVOCATION } from "../../actions/convocation/convocationAction";

const INITIAL_STATE = {

    errors: [],
    days: [],
    warning: '',

};

const INITIAL_ITEM = {

    startTime: '',
    isStartTimeDefault: true,

    startIntervalTime: '',
    isStartIntervalTimeDefault: true,

    endIntervalTime: '',
    isEndIntervalTimeDefault: true,

    endTime: '',
    isEndTimeDefault: true,

    error: false,
    startTimeError: false,
    startIntervalTimeError: false,
    endIntervalTimeError: false,
    endTimeError: false,
    errorMsg: '',

};

function workDay(state = INITIAL_STATE, action) {

    switch (action.type) {

        case RESET_CONVOCATION: {
            return INITIAL_STATE;
        }

        case ADD_WARNING:{
            return {
                ...state,
                warning: action.warning
            }
        }

        case REMOVE_WARNING:{
            return {
                ...state,
                warning: ''
            }
        }


        case ADD_ITEM: {

            if (state.days.some(item => moment(item.date).isSame(action.date, 'day'))) {
                return state;
            }
            
            const workdayPayload = {
                ...INITIAL_ITEM, 
                date: action.date,
                startTime: state.days.length > 0 ? state.days[0].startTime : '',
                endTime: state.days.length > 0 ? state.days[0].endTime : '',
                startIntervalTime: state.days.length > 0 ? state.days[0].startIntervalTime : '',
                endIntervalTime: state.days.length > 0 ? state.days[0].endIntervalTime : '',
            }

            let newArray = [...state.days, workdayPayload];

            newArray = newArray.sort((a, b) => a.date - b.date)

            newArray.map((item, index) => item.index = index);

            return {
                ...state,
                days: newArray
            };
        }

        case REMOVE_ITEM: {

            const newArray = state.days.filter(obj => !moment(obj.date).isSame( action.date, "day"))
            newArray.map((item, index) => item.index = index);
            return {
                ...state,
                days: newArray
            }
        }

        case REMOVE_ITEM_BY_INDEX: {
            const newArray = state.days.filter(item => item.index !== action.index);
            newArray.map((item, index) => item.index = index);
            return {
                ...state,
                days: newArray
            }
        }

        case SET_START_TIME: {

            const newArray = state.days.map(item => {

                let result = { ...item }

                if (item.index === action.index) {
                    result.startTime = action.startTime
                    result.isStartTimeDefault = false;
                }

                if (action.index === 0 && result.isStartTimeDefault) {
                    result.startTime = action.startTime
                }

                return result;
            });

            return {
                ...state,
                days: newArray
            }

        }


        case SET_START_INTERVAL_TIME: {

            const newArray = state.days.map(item => {

                let result = { ...item }

                if (item.index === action.index) {
                    result.startIntervalTime = action.startIntervalTime
                    result.isStartIntervalTimeDefault = false;
                }

                if (action.index === 0 && result.isStartIntervalTimeDefault) {
                    result.startIntervalTime = action.startIntervalTime
                }

                return result;
            });

            return {
                ...state,
                days: newArray
            }

        }

        case SET_END_INTERVAL_TIME: {

            const newArray = state.days.map(item => {

                let result = { ...item }

                if (item.index === action.index) {
                    result.endIntervalTime = action.endIntervalTime
                    result.isEndIntervalTimeDefault = false;
                }

                if (action.index === 0 && result.isEndIntervalTimeDefault) {
                    result.endIntervalTime = action.endIntervalTime
                }

                return result;
            });

            return {
                ...state,
                days: newArray
            }

        }

        case SET_END_TIME: {

            const newArray = state.days.map(item => {

                let result = { ...item }

                if (item.index === action.index) {
                    result.endTime = action.endTime
                    result.isEndTimeDefault = false;
                }

                if (action.index === 0 && result.isEndTimeDefault) {
                    result.endTime = action.endTime
                }

                return result;
            });

            return {
                ...state,
                days: newArray
            }

        }

        case SET_LINE_ERROR: {

            const newArray = state.days.map(item => {

                let result = { ...item }

                if (item.index === action.index) {
                    result.error = true;
                    result.startTimeError = action.startTimeError;
                    result.startIntervalTimeError = action.startIntervalTimeError;
                    result.endIntervalTimeError = action.endIntervalTimeError;
                    result.endTimeError = action.endTimeError;
                    result.errorMsg = action.errorMsg;
                }

                return result;
            });

            return {
                ...state,
                days: newArray
            }

        }

        case ADD_ERRO: {
            return {
                ...state,
                errors: state.errors.indexOf(action.error) === -1 ? state.errors.concat(action.error) : state.errors
            }
        }

        case CLEAR_ERRORS: {

            const newArray = state.days.map(item => {

                let result = { ...item }

                result.error = false;
                result.startTimeError = false;
                result.startIntervalTimeError = false;
                result.endIntervalTimeError = false;
                result.endTimeError = false;
                result.errorMsg = '';

                return result;
            });

            return {
                ...state,
                days: newArray,
                errors: []
            }

        }

        case CLEAR_DAY_ERRORS: {
            const newArray = [...state.days];
            let day = newArray[action.index];

            day.error = false;
            day.startTimeError = false;
            day.startIntervalTimeError = false;
            day.endIntervalTimeError = false;
            day.endTimeError = false;
            day.errorMsg = '';

            return {
                ...state,
                days: newArray
            }
        }

        default:
            return state;
    }
};

export default workDay;