import React from 'react';
import PlanRouter from '../../../../Components/Permission/PlanRouter';
import UpgradeButtonWrapper from './UpgradeButtonWrapper';

export default function NavLinkPermissionWrapper({ resource, disableOnDenied, onDeniedProps, children }) {
    return (
        <PlanRouter resource={resource} onDeniedProps={onDeniedProps} disableOnDenied={disableOnDenied} >
            <UpgradeButtonWrapper>
                {children}
            </UpgradeButtonWrapper>
        </PlanRouter>
    )
}