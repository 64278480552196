import React, { Fragment } from 'react';

export default function UpgradeButtonWrapper(props) {
    return (
        <Fragment>
            {
                React.cloneElement(props.children, { disabled: props.disabled })
            }
            {
                props.showUpgradeButton && (
                    <button
                        onClick={() => window.location.href = "/#/planos"}
                        disabled={false}
                        style={{ cursor: "pointer", fontSize: "smaller", height: "80%", borderRadius: 4, border: "1px solid #c3e7f7", backgroundColor: "#e6f5fc" }}
                        color="primary"
                        size="sm" outline className="ml-2 font-weight-bold border-primary text-primary" >
                        UPGRADE
                    </button>
                )
            }
        </Fragment>
    )
}