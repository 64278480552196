import React, { Fragment, useState } from "react";
import { useForm } from 'react-hook-form';
import { Redirect } from "react-router-dom";
import Slider from "react-slick";
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import bg3 from '../../assets/utils/images/originals/graficos.jpeg';
import bg1 from '../../assets/utils/images/originals/sou_empresa.jpeg';
import bg2 from '../../assets/utils/images/originals/sou_profissional.jpeg';
import { authService } from '../../services/auth';
import RecoveryPassword from './RecoveryPassword';
import { URL_SITE, URL_SISTEMA } from "../../config/config";
import './assets/main.css';

export default function Login() {

    const { register, handleSubmit, control, errors } = useForm();

    const [userLogged, setUserLogged] = useState(false);
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [recoveryPasswordVisible, setRecoveryPasswordVisible] = useState(false);


    if (userLogged) {
        return (<Redirect to={{ pathname: "/" }} />);
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true

    };

    const onSubmit = formData => {
        authService.login(
            formData.email,
            formData.password
        ).then(
            userLoggedResponse => {
                setUserLogged(userLoggedResponse);
            },
            error => {
                console.log(error);
                setInvalidLogin(true);
            }
        );
    }
    function onDismiss() {
        setInvalidLogin(false);
    }

    return (

        <Fragment>
            <div className="h-100">
                <RecoveryPassword open={recoveryPasswordVisible} onCloseModal={() => setRecoveryPasswordVisible(false)} />
                <Row className="h-100 no-gutters">
                    <Col lg="4" className="d-none d-lg-block">
                        <div className="slider-light">
                            <Slider  {...settings}>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg1 + ')'
                                        }} />
                                    <div className="slider-content">
                                        <h3>Convocação para o trabalho</h3>
                                        <p>
                                            Você informa os profissionais sobre os próximos turnos de trabalho.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }} />
                                    <div className="slider-content">
                                        <h3>Registro de ponto</h3>
                                        <p>
                                            Registre e armazene o histórico do ponto de seus colaboradores.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                    <div className="slide-img-bg opacity-6"
                                        style={{
                                            backgroundImage: 'url(' + bg2 + ')'
                                        }} />
                                    <div className="slider-content">
                                        <h3>Garantias jurídicas</h3>
                                        <p>
                                            Observamos todas as questões legais atualizadas envolvidas nas suas contratações.
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                    <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                            <div className="app-logo" />
                            <h4 className="mb-0">
                                <div>Bem vindo,</div>
                                <span>Informe seus dados para acessar</span>
                            </h4>
                            <h6 className="mt-3">
                                Ainda não possui conta?{' '}
                                <a href={`${URL_SITE}/sou-empresa`} className="text-primary">Acesse nosso site e faça o cadastro!</a>
                            </h6>
                            <Row className="divider" />
                            <div>
                                <Form onSubmit={handleSubmit(onSubmit)} >
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="email">Email</Label>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Informe seu e-mail"
                                                    invalid={errors.email}
                                                    innerRef={register({ required: true })} />
                                                {errors.email &&
                                                    errors.email.type === "required" &&
                                                    <FormFeedback>O campo não pode estar vazio</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="password">Senha</Label>
                                                <Input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Informe sua senha"
                                                    invalid={errors.password}
                                                    innerRef={register({ required: true })}
                                                />
                                                {errors.password &&
                                                    errors.password.type === "required" &&
                                                    <FormFeedback>O campo não pode estar vazio</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {console.log(errors)}
                                    {/* {invalidLogin &&   <Alert color="danger" > Credenciais inválidas para login! </Alert>} */}
                                    <Alert color="danger" isOpen={invalidLogin} toggle={onDismiss}> Credenciais inválidas para login! </Alert>
                                    <Row className="divider" />

                                    <div className="d-flex align-items-center flex-wrap">
                                        <div className="registro-de-ponto-button-wrap">

                                            <Button onClick={(ev) => {
                                                ev.preventDefault()
                                                window.location.href = URL_SISTEMA + "/registrar-ponto"
                                            }} color="primary" outline>Registro de ponto</Button>

                                        </div>
                                        <div className="ml-auto">
                                            <a
                                                href="javascript:void(0);"
                                                className="btn-lg btn btn-link"
                                                onClick={() => setRecoveryPasswordVisible(true)}>
                                                Esqueci minha senha
                                                </a>{' '}{' '}
                                            <Button color="primary" type="submit" size="lg">Entrar</Button>
                                        </div>
                                    </div>
                                    <div className="registro-de-ponto-button-wrap-mobile">

                                        <Button onClick={(ev) => {
                                            ev.preventDefault()
                                            window.location.href = URL_SISTEMA + "/registrar-ponto"
                                        }} color="primary" outline>Registro de ponto</Button>

                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );

}