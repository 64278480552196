export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const addEmployee = (employee) => ({
    type: ADD_EMPLOYEE,
    employee
});

export const removeEmployee = (id) => ({
    type: REMOVE_EMPLOYEE,
    id
});

export const addError = (error)=> ({
    type: ADD_ERROR,
    error
})

export const removeError = (index) =>({
    type: REMOVE_ERROR,
    index
})

export const clearErrors = () =>({
    type: CLEAR_ERRORS
})
