import React from 'react'
import { Page, Document, StyleSheet, Text, View, Image, Font, pdf } from '@react-pdf/renderer'
import logoImg from '../assets/images/syme-logo.png'
import moment from 'moment'


//https://react-pdf.org/repl?example=text

export function ReportTemplate(props) {

    const templateStyles = StyleSheet.create({
        page: {
            borderSizing: 'border-box',
            display: 'block',
            padding: 25,
            flexDirection: 'column',
        },
        header: {
            textAlign: 'center',
            fontSize: 18,
            marginBottom: 15,
        },
        body: {
            flexGrow: 1,
        },
        footer: {
            flexDirection: 'row',
            alignItems: 'bottom',
            marginTop: 10,
        },
        footerLeft: {
            flexGrow: 1,
        },
        footerRight: {
            flexGrow: 1,
            alignItems: "end",
            fontSize: 12,
        },
        logo: {
            maxWidth: '18vw',
            maxHeight: '3vh'
        }
    });

    return (
        <Document>
            <Page size="A4" style={templateStyles.page}>
                <View style={templateStyles.header}>
                    <Text>{props.title}</Text>
                </View>
                <View style={templateStyles.body}>
                    {props.children}
                </View>
                <View style={templateStyles.footer}>
                    <View style={templateStyles.footerLeft}>
                        <Image style={templateStyles.logo} src={logoImg} ></Image>
                    </View>
                    <View style={templateStyles.footerRight}>
                        <Text>{moment().format('DD/MM/YYYY HH:mm:ss')}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );

}




