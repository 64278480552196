import React, { lazy } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import App from '../App';
import Login from '../pages/Login';
import ErrorPage from '../pages/Error';
import PunchClockRegister from '../pages/PunchClockRegister';
import { authService } from '../services/auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authService.isAuthenticated() ? (
                <Component {...props} />
            ) : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
        }
    />
);

export const LoginRedirect = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/login" component={() => <Login />} />
            <Route exact path="/registrar-ponto" component={() => <PunchClockRegister />} />
            <Route exact path="/error/:type/:message" component={() => <ErrorPage />} />
            <PrivateRoute path="/" component={() => <App />} />
        </Switch>
    </BrowserRouter>
);


