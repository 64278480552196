import gql from "graphql-tag";

export const CREATE_PUSH_MESSAGE = gql`
    mutation insert_push_message(
      $user_id: uuid!,
      $title: String!,
      $body: String,
      $action_data: jsonb!
    ) {
      insert_push_message(objects: {
        title: $title, 
        body: $body, 
        action: contract, 
        action_data: $action_data,
        push_message_users: {data:{
            user_id: $user_id
        }}
      }) {
        affected_rows
      }
    }
`;


export const CREATE_PUSH_MESSAGE_USER = gql`
  mutation insert_push_message_user(
    $push: [push_message_user_insert_input!]!
  ) {
    insert_push_message_user(objects: $push) {
      affected_rows
    }
  }
`;


export const CREATE_PUSH_MESSAGE_REGISTERED_POINT = gql`
    mutation insert_push_message(
      $user_id: uuid!,
      $title: String!,
      $body: String,
      $action_data: jsonb!,
    ) {
      insert_push_message(objects: {
        title: $title, 
        body: $body,
        action: point,
        action_data: $action_data
        push_message_users: {data:{
            user_id: $user_id
        }}
      }) {
        affected_rows
      }
    }
`;

export const CREATE_PUSH_MESSAGE_GENERIC = gql`
  mutation insert_push_message($push_messages: [push_message_insert_input!]!) {
    insert_push_message(objects: $push_messages) {
      affected_rows
    }
  }
`;