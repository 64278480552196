import { useMutation } from "@apollo/react-hooks";
import { formatToBRL } from 'brazilian-values';
import 'moment/locale/pt-br';
import React, { Fragment, useContext } from 'react';
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Row } from 'reactstrap';
import { UserInfoContext } from "../../../context/UserInfoProvider";
import { DELETE_COMPANY_SELECTED_PLAN_ID } from "../../../queries/company";
import { MAKE_PURCHASE } from "../../../queries/payment";
import { handleFreePaymentConfirm } from '../helpers/freePaymentFunction';


export default function PurchaseInfo({ plan, isFree, setLoading }) {
    const [makePurchase] = useMutation(MAKE_PURCHASE)
    const [deleteSelectedPlanId] = useMutation(DELETE_COMPANY_SELECTED_PLAN_ID)
    const userInfo = useContext(UserInfoContext);

    function handleCHangePlanClick() {
        window.location.href = "#/planos"
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <img className="mt-3" style={{ width: "25vh" }} src={require("../../../assets/images/syme-logo.png")} />
                            <h2 className="mb-4 mt-4 font-weight-bold" >Resumo de Compra</h2>
                            <Card style={{ border: "solid black 1px" }} className="p-0 shadow-none" >
                                <CardTitle style={{ borderBottom: "solid black 1px" }} className="pl-4 pt-3 pb-3" >
                                    <Row>
                                        <Col>
                                            Plano
                                        </Col>
                                        <Col>
                                            validade
                                        </Col>
                                        <Col>
                                            Preço
                                        </Col>
                                    </Row>
                                </CardTitle>
                                <CardBody className="p-0 pl-4 pb-3" >
                                    <Row>
                                        <Col className="d-flex flex-column justify-content-center" >
                                            <h5 className="m-0 font-weight-bold" > {plan.name} </h5>
                                            <div className="m-0 text-grey " dangerouslySetInnerHTML={{ __html: plan.description }} />
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center" >
                                            <h5> <b> {plan.month_count} </b> <small>{plan.month_count == 1 ? `mês` : `meses`}</small>  </h5>
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center">
                                            <h5 className="m-0"> {formatToBRL(plan.price)} </h5>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Row className="mt-4" >
                                <Col>
                                    <h3 className="font-weight-bold d-flex justify-content-end mr-3" >
                                        Valor Total:
                                        <p className="font-weight-normal ml-4" >  {formatToBRL(plan.price)} </p>
                                    </h3>

                                </Col>
                            </Row>
                            {
                                isFree &&
                                <Row  >
                                    <Col className="text-center">
                                        <Button size="lg" style={{ cursor: "pointer", maxWidth: "540px" }} className="w-100" color="primary" onClick={() => handleFreePaymentConfirm({ setLoading: setLoading, userInfo: userInfo, planId: plan.id, makePurchase: makePurchase, deleteSelectedPlanId: deleteSelectedPlanId })} >FINALIZAR COMPRA</Button>
                                    </Col>
                                </Row>
                            }

                        </CardBody>
                        <CardFooter>
                            <Button onClick={handleCHangePlanClick} color="link">
                                Escolher outro Plano
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}