import { SET_HOLIDAYS, RESET_HOLIDAYS, SET_COST } from "../../actions/convocation/confirmationAction";
import { RESET_CONVOCATION } from "../../actions/convocation/convocationAction";


const INITIAL_STATE = {
    holidays: [],
    cost: null
};

function confirmationReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case RESET_CONVOCATION: {
            return INITIAL_STATE;
        }

        case RESET_HOLIDAYS: {
            return INITIAL_STATE;
        }

        case SET_HOLIDAYS: {
            return {
                ...state,
                holidays: [...state.holidays, ...action.holidays]
            };
        }

        case SET_COST: {
            return {
                ...state,
                cost: action.cost
            };
        }

        default:
            return state;
    }
};

export default confirmationReducer;