import { isCPF } from 'brazilian-values';
import moment from "moment";
import 'moment/locale/pt-br';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import InputMask from "react-input-mask";
import Webcam from "react-webcam";
import { Alert, Button, Card, CardBody, CardFooter, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { LOAD_COMPANY_BY_HASH_DEVICE } from "../../queries/device_point_record";
import { LOAD_SERVER_TIME } from "../../queries/others";
import { CREATE_POINT_RECORD } from "../../queries/point_record";
import { LOGIN_TO_REGISTER_POINT, LOGIN_TO_REGISTER_POINT_WITH_PASSWORD } from "../../queries/user";
import directRequest from "../../services/directRequest";
import './style.css';
import { sendMessage } from './utils/sendMessage';
import { getNameByCpfCnpj } from './utils/user';
import { validatePointByContract, validatePointByConvocation } from './utils/validatePunchClockRegister';
import { getImage } from '../../util/downloadImageUtil'
import axios from 'axios'
import { toast } from 'react-toastify';
import { URL_SITE, URL_FILE_SERVER } from '../../config/config';
import { handleResponse } from '../../helpers/handle-response';
import { URL_AUTH } from '../../config/config';
function getHash() {
    return localStorage.getItem("hash-device-point");
}

function hourMinFormat(date) {
    const hours = (date.substr(date.indexOf("T") + 1, date.length)).split(":");
    return `${hours[0]}h${hours[1]}min`;
}

let timeout;

export default function PunchClockRegister() {

    const { register, handleSubmit, clearError, errors, reset } = useForm();
    const hash = getHash();
    const [company, setCompany] = useState({});
    const [userName, setUserName] = useState("");
    const [deviceId, setDeviceId] = useState(0);
    const [clickedOnSubmit, setClickedOnSubmit] = useState(false);
    const [camNotWorking, setCamNotWorking] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [hashInvalid, setHashInvalid] = useState(false);
    const [logo, setLogo] = useState(null);
    const webcamRef = useRef(null);
    const [componentsWidth, setcomponentsWidth] = useState('100%')

    useEffect(() => {
        if(window.innerHeight > window.innerWidth){
            if (document.querySelector('#cpf_point_input') === document.activeElement ||  document.querySelector('#password_point_input') === document.activeElement) {
                // window.scrollTo({ behavior: 'smooth', top: window.innerHeight * .6 });
                setcomponentsWidth("60%");
            } else {
                setcomponentsWidth("100%");
            }
        }
    }, [document.activeElement]);

    useEffect(() => {
        if (hash) {
            directRequest({
                query: LOAD_COMPANY_BY_HASH_DEVICE,
                variables: {
                    hash
                }
            }).then(result => {
                const returned = result.data.device_point_record;
                if (returned) {
                    setDeviceId(returned[0].id);
                    setCompany(returned[0].company);
                    setLogo(returned[0].company.logomarca)
                } else {
                    setHashInvalid(true);
                    setTimeout(() => {
                        window.location.href = URL_SITE;
                    }, 2500);
                }
            });
        } else {
            setHashInvalid(true);
            setTimeout(() => {
                window.location.href = URL_SITE;
            }, 2500);
        }
    }, []);

    useEffect(() => {
        if (!showFeedback) return;

        const time = showFeedback.type === 'danger' ? 7000 : 3500;

        setTimeout(
            () => {
                setShowFeedback(false);
            },
            time
        );
    }, [showFeedback]);

    const [dateTime, setDateTime] = useState();

    useEffect(() => {
       loadServerTime().then(now => {
            setDateTime(moment(now.data.time.date + ' ' + now.data.time.time, "DD/MM/YYYY hh:mm:ss"));
        });

        setInterval(()=>{
            loadServerTime().then(now=>{
                clearTimeout(timeout);
                setDateTime(moment(now.data.time.date + ' ' + now.data.time.time, "DD/MM/YYYY hh:mm:ss"));
                console.log(moment(now.data.time.date + ' ' + now.data.time.time, "DD/MM/YYYY hh:mm:ss").format('hh:mm:ss'))
            })
        }, 1000*60*10); 
    }, []);

    useEffect(() => {

        if (!dateTime) return;

        timeout = setTimeout(
            () => {
                setDateTime(moment(dateTime).add(1, 'seconds'));
            },
            1000
        );

    }, [dateTime]);


    function loadServerTime(){
        return directRequest({query: LOAD_SERVER_TIME});
    }

    function renderHeader() {

        return (
            <Fragment>
                <Row className="flex-center mb-1">
                    <Col sm="8" className="flex-center">
                        <img src={getImage('logo', logo)} style={{ maxWidth: '200px', maxHeight: '100px' }} />
                    </Col>
                    <Col sm="8" className="flex-center" style={{ alignItems: 'center' }}>
                        <span className="date">{dateTime ? dateTime.format('DD/MM/YYYY') : '-'}</span>
                        <span className="hour">{dateTime ? dateTime.format('HH:mm:ss') : '-'}</span>
                    </Col>
                </Row>
                {
                    showFeedback ?
                        <Row>
                            <Col>
                                <Alert color={showFeedback.type}>
                                    {showFeedback.message}
                                </Alert>
                            </Col>
                        </Row> :
                        <Row>
                            <Col className="text-center mb-3">
                                <span>Para registrar o ponto insira seu cpf,
                                <br />posicione seu rosto na câmera e clique no botão <b>Registrar Ponto</b></span>
                            </Col>
                        </Row>
                }
            </Fragment>
        )
    }

    const showUserName = async (cpf_cnpj) => {
        try {
            if (isCPF(cpf_cnpj)) {
                clearError("cpf_cnpj");
                const { data: { user } } = await getNameByCpfCnpj(cpf_cnpj);

                if (user.length > 0) { setUserName(user[0].name) }
                else { setUserName("") };
            } else {
                setUserName("");
            }
        } catch (err) {
            alert(err);
        }
    }

    const getUserInfoHtml = () => {
        return (<div className="mt-1" style={{ height: '15px' }}>{userName}</div>);
    }



    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });

        return blob;
    }

    const uploadImage = async (company_id, user_id) => {
        const formData = new FormData()

        formData.append('point', b64toBlob(webcamRef.current.getScreenshot().split(',')[1], 'image/webp'))
        const response = await axios.post(
            `${URL_FILE_SERVER}/storage/upload`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'type': 'point',
                    'companyId': company_id,
                    'userId': user_id

                }
            }
        )

        return response
    }



    const savePoint = async (user, contract) => {
        try {
            const employees = user.employees;
            const employee_id = employees[0].id;

            let image;

            if (!camNotWorking) {
                const response = await uploadImage(company.id, user.id)

                if (response.status != 200 || !response.data) {
                    toast['error']('Houve um erro ao salvar a imagem');
                    return
                }

                image = response.data.key;
            }

            const { data: { insert_point_record_one: { date_time } } } = await directRequest({
                query: CREATE_POINT_RECORD,
                variables: {
                    employee_id,
                    company_id: company.id,
                    device_record_point_id: deviceId,
                    image
                }
            });

            sendMessage(user, hourMinFormat(date_time), company, () => setShowFeedback({ type: 'success', message: "Ponto registrado com sucesso" }), contract);
            setUserName("");
        } catch (err) {
            setShowFeedback({ type: 'danger', message: err.toString() });
        }
    }

    const onSubmit = async ({ cpf_cnpj, password }) => {
        if (!clickedOnSubmit) {

            setClickedOnSubmit(true);
            try {
                let users = [];

                if (camNotWorking) {

                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ username: cpf_cnpj, password })
                    };

                    const response = await fetch(`${URL_AUTH}/token`, requestOptions);

                    if (response.status != 200) {
                        throw ("CPF ou senha incorretos");
                    }

                }

                users = await directRequest({
                    query: LOGIN_TO_REGISTER_POINT,
                    variables: {
                        login: cpf_cnpj
                    }
                });

                if (!users.data.user.length) throw ("CPF ou senha incorretos");
                const user = users.data.user[0];
                
                const { data: { contract } } = await validatePointByContract({ user_id: user.id, company_id: company.id });
                if (!contract.length) throw ("Não foi possível registrar o ponto! Você não tem contrato válido com esta empresa!");

                const { data: { convocation } } = await validatePointByConvocation({ user_id: user.id, company_id: company.id });
                if (!convocation.length) throw ("Não foi possível registrar o ponto! Você não tem convocação válida para este dia!");

                savePoint(user, contract[0]);
                document.querySelector('#cpf_point_input').value = "";
                if (camNotWorking) document.querySelector('#password_point_input').value = "";

            } catch (err) {
                reset();
                setUserName('');
                setShowFeedback({ type: 'danger', message: err.toString() });
            }

            setClickedOnSubmit(false);
        }
    }
    function renderForm() {


        return (
            <Fragment>
                <Form onSubmit={handleSubmit(onSubmit)} >
                    <Row>
                        <Col sm={camNotWorking ? { size: 6, offset: 0 } : { size: 6, offset: 3 }}>
                            <FormGroup>
                                <InputMask className="form-control" mask="999.999.999-99"
                                    invalid={errors.cpf_cnpj && true}
                                    onKeyUp={
                                        e => {
                                            showUserName(e.target.value);
                                        }
                                    }
                                    innerRef={register({
                                        required: true,
                                        validate: {
                                            invalid: value => isCPF(value)
                                        }
                                    })}>
                                    <Input
                                        id='cpf_point_input'
                                        placeholder="CPF"
                                        name="cpf_cnpj"
                                        type="tel"
                                    />
                                </InputMask>
                                {!isCPF(errors.cpf_cnpj) &&
                                    <FormFeedback>Cpf inválido</FormFeedback>}
                                {getUserInfoHtml()}
                            </FormGroup>
                        </Col>
                        {
                            camNotWorking &&
                            <Col sm="6">
                                <FormGroup>
                                    <Input id='password_point_input' type="password" placeholder="Senha"
                                        name="password"
                                        invalid={errors.password && true}
                                        innerRef={register({ required: camNotWorking })}
                                    />
                                    {errors.password &&
                                        errors.password.type === "required" &&
                                        <FormFeedback>O campo não pode estar vazio</FormFeedback>}
                                </FormGroup>
                            </Col>
                        }

                    </Row>

                    <Row className="mb-2" style={{ justifyContent: "center" }}>
                        <Col sm='12' className='d-flex align-items-center justify-content-center'>
                            <Button style={{ width: componentsWidth }} type="submit" disabled={clickedOnSubmit} color="primary" className="button-punch-clock">Registrar ponto</Button>
                        </Col>
                        <Col sm="12" className="col-webcam" >

                            <Webcam audio={false}
                                ref={webcamRef}
                                width={'100%'}
                                height={componentsWidth}
                                videoConstraints={{ facingMode: 'user' }}
                                mirrored={true} className="div-webcam" />
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        )
    }

    function renderFooter() {

        if (camNotWorking) {
            return (
                <div className="d-flex align-items-center w-100" style={{ justifyContent: 'space-between' }}>
                    <div className="text-center">
                        Se a câmera estiver funcionando
                    <br /><Button disabled={clickedOnSubmit} onClick={() => setCamNotWorking(false)} className="btn-link" color="link">clique aqui para registrar o ponto com foto</Button>
                    </div>
                    <div className="app-logo" style={{ backgroundSize: 'contain', width: '100px', height: '30px' }} />
                </div>
            )
        }

        return (
            <div className="d-flex align-items-center w-100" style={{ justifyContent: 'space-between' }}>
                <div className="text-center">
                    Se a câmera estiver com problema
                <br /><Button disabled={clickedOnSubmit} onClick={() => setCamNotWorking(true)} className="btn-link" color="link">clique aqui para registrar o ponto sem foto</Button>
                </div>
                <div className="app-logo" style={{ backgroundSize: 'contain', width: '100px', height: '30px' }} />
            </div>
        )
    }
    return (
        <Fragment>
            <Row className="no-gutters d-flex justify-content-center align-items-center w-100 h-100 point-register-container">
                {!hashInvalid ?
                    (
                        <Col md="10" sm="10" xs="12" className="main-container">
                            <Fragment>
                                <Card  >
                                    <CardBody style={{ paddingTop: 0 }}>
                                        {renderHeader()}
                                        {renderForm()}
                                    </CardBody>
                                    <CardFooter>
                                        {renderFooter()}
                                    </CardFooter>
                                </Card>
                            </Fragment>
                        </Col>
                    )
                    :

                    <Col md="11" sm="11" xs="11" className="text-center">
                        <h5>Esse dispositivo não está registrado, contate o administrador</h5>
                    </Col>
                }
            </Row>
        </Fragment>
    )
}
