import React, { useContext } from "react";
import { UserInfoContext } from "../../context/UserInfoProvider";
import { UncontrolledTooltip } from "reactstrap";


export default function PermissionRouter(props) {

    const userInfo = useContext(UserInfoContext);

    let grant = false;
    userInfo.permissions.forEach( permission => {

        let action = permission.action.name;
        
        if(action === props.permission){
            grant = true;
            return;
        }
    });

    return (
        <>
            {
                (grant) ? props.children :
                (props.message) ? props.message : //EDITAR A MENSAGEM PARA APARECER CENTRALIZADA 
                    (props.disabled) ? React.cloneElement(props.children, { disabled: true }) :
                    (props.classDisabled) ? React.cloneElement(props.children, { className: "disabled" }) : null
            }
        </>
    )

 
    // if ( grant ) {
    //     console.log( "Permission Grant",props )
    //     return (<> { props.children } </>)
    // } else if ( props.message ) {
    //     console.log( "Permission Message",props)
    //     return (<> { props.message } </>)
    // } else if ( props.disabled ) {
        
    //     return (<> { React.cloneElement(props.children, {  disabled: true}) }</>)
    // } 
    // else if(props.classDisabled){
    //     console.log("Permission disabled",props)
    //     return (<> 
    //         {React.cloneElement(props.children, { className: "disabled" , id: props.idTooltip },
            
    //         )}
       
    //         {
    //         <UncontrolledTooltip placement="right" target={props.idTooltip}>
    //             Sem acesso! 
    //         </UncontrolledTooltip>}
    //         </>)
    // } 
    // else {
    //     //console.log( "Permission Null",props )
    //     return (<> { null } </>)
    // }
};
