import ThemeOptions from './ThemeOptions';
import workdayReducer from './convocation/workdayReducer';
import contractInfoReducer from './contract/contractInfoReducer';
import placeReducer from './convocation/placeReducer';
import employeeReducer from './convocation/employeeReducer';
import confirmationReducer from './convocation/confirmationReducer';
import convocationListReducer from './convocation/convocationListReducer';
import workdayEmployeeReducer from './workday/workdayEmployeeReducer';
import journeyConfig from './convocation/journeyConfig';

export default {
    ThemeOptions,
    workdayReducer,
    contractInfoReducer,
    placeReducer,
    employeeReducer,
    confirmationReducer,
    convocationListReducer,
    workdayEmployeeReducer,
    journeyConfig
};