import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import ResizeDetector from 'react-resize-detector';

// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
// Theme Options
import ThemeOptions from '../../Layout/ThemeOptions';

import AppMain from '../../Layout/AppMain';
import { LoadScript } from '@react-google-maps/api';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false
        };

    }

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;


        return (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Fragment>
                        <div className={cx(
                            "app-container app-theme-" + colorScheme,
                            { 'fixed-header': enableFixedHeader },
                            { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                            { 'fixed-footer': enableFixedFooter },
                            { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                            { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                            { 'sidebar-mobile-open': enableMobileMenu },
                            { 'body-tabs-shadow-btn': enablePageTabsAlt },
                        )}>

                            <ThemeOptions />
                            <AppHeader openCheck={"jff"} />

                            <div className="app-main">
                                <LoadScript
                                    id="script-loader"
                                    googleMapsApiKey="AIzaSyC2HgBqs5Mu1tEd2YxBQTqaV67OiF0g3c8"
                                    language="pt-br"
                                    region="br"
                                    libraries={["places"]}>
                                    <AppSidebar />
                                    <AppMain />
                                </LoadScript>
                            </div>
                        </div>
                    </Fragment>
                )}
            />
        );

        // return (
        //     <ResizeDetector
        //         handleWidth
        //         render={({ width }) => (
        //             <Fragment>
        //                 <div className={cx(
        //                     "app-container app-theme-" + colorScheme,
        //                     {'fixed-header': enableFixedHeader},
        //                     {'fixed-sidebar': enableFixedSidebar || width < 1250},
        //                     {'fixed-footer': enableFixedFooter},
        //                     {'closed-sidebar': enableClosedSidebar || width < 1250},
        //                     {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
        //                     {'sidebar-mobile-open': enableMobileMenu},
        //                     {'body-tabs-shadow-btn': enablePageTabsAlt},
        //                 )}>
        //                     <AppMain/>
        //                 </div>
        //             </Fragment>
        //         )}
        //     />
        // )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

});

export default withRouter(connect(mapStateToProp)(Main));