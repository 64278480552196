export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_ITEM_BY_INDEX = 'REMOVE_ITEM_BY_INDEX';
export const SET_START_TIME = 'SET_START_TIME';
export const SET_START_INTERVAL_TIME = 'SET_START_INTERVAL_TIME';
export const SET_END_INTERVAL_TIME = 'SET_END_INTERVAL_TIME';
export const SET_END_TIME = 'SET_END_TIME';
export const SET_LINE_ERROR = 'SET_LINE_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const ADD_ERRO = 'ADD_ERRO';
export const ADD_WARNING = 'ADD_WARNING';
export const REMOVE_WARNING = 'REMOVE_WARNING';

export const CLEAR_DAY_ERRORS = 'CLEAR_DAY_ERRORS';

export const addWarning = (warn) => ({
    type: ADD_WARNING,
    warning: warn
})

export const removeWarning = () => ({
    type: REMOVE_WARNING,
})

export const addItem = (date) => ({
    type: ADD_ITEM,
    date
});

export const removeItem = (date) => ({
    type: REMOVE_ITEM,
    date
});

export const removeItemByIndex = (index) => ({
    type: REMOVE_ITEM_BY_INDEX,
    index
});

export const setStartTime = (index, value) => ({
    type: SET_START_TIME,
    index,
    startTime: value
});

export const setIntervalStartTime = (index, value) => ({
    type: SET_START_INTERVAL_TIME,
    index,
    startIntervalTime: value
});

export const setIntervalEndTime = (index, value) => ({
    type: SET_END_INTERVAL_TIME,
    index,
    endIntervalTime: value
});

export const setEndTime = (index, value) => ({
    type: SET_END_TIME,
    index,
    endTime: value
});

export const addError = (error) => ({
    type: ADD_ERRO,
    error
});

export const clearErrors = () => ({
    type: CLEAR_ERRORS
});


export const setLineError = (
    index,
    {
        startTimeError = false,
        startIntervalTimeError = false,
        endIntervalTimeError = false,
        endTimeError = false
    },
    errorMsg) => ({
        type: SET_LINE_ERROR,
        index,
        startTimeError,
        startIntervalTimeError,
        endIntervalTimeError,
        endTimeError,
        errorMsg
    });


export const clearDayErrors = (index) => ({
    type: CLEAR_DAY_ERRORS,
    index
});

