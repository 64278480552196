import gql from "graphql-tag";

export const LOAD_DEVICES = gql`
    query loadDevices($company_id: Int!){
        device_point_record(order_by: { id: desc }, where: {company_id: {_eq: $company_id}}) {
            device_name
            create_date
            id
            hash
            company_id
            status
        }
    }
`;


export const LOAD_COMPANY_BY_HASH_DEVICE = gql`
    query loadDevices($hash: String!){
        device_point_record(order_by: { id: desc }, where: {hash: {_eq: $hash}}) {
            id
            device_name
            create_date
            company_id
            company {
                fantasy_name
                business_name
                id
                logomarca
            }
        }
    }
`;



export const LOAD_DEVICE = gql`
    query loadDevice(
        $device_id: Int!
    ){
        device_point_record(where: { id: { _eq: $device_id } }) {
            device_name
            create_date
            id
            hash
            company_id
            status
        }
    }
`;


export const CREATE_DEVICE = gql`
    mutation insertDevicePointRecord($company_id: Int!, $device_name: String!, $hash: String!){
        insert_device_point_record(objects: {
            company_id: $company_id, 
            device_name: $device_name, 
            hash: $hash, 
            status: true
            }) {
            returning {
                id
            }
        }
    }
`;


export const UPDATE_DEVICE = gql`
    mutation updateDevice(
            $device_id: Int!
            $device: device_point_record_set_input!
        ) {
            update_device_point_record(where: {id: {_eq: $device_id}}, 
            _set: $device
        ) {
        affected_rows
        }
    }
`;
