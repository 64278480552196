
import gql from "graphql-tag";

export const CREATE_POINT_RECORD = gql`
  mutation createPointRecord(
    $company_id: Int!, 
    $employee_id: Int!, 
    $device_record_point_id: Int!
    $image: String
  ) {
    insert_point_record_one(
      object: {
        company_id: $company_id, 
        employee_id: $employee_id, 
        device_record_point_id: $device_record_point_id,
        image: $image
      }
    ) {
      date_time
    }
  }
`;

export const DELETE_POINT = gql`
  mutation deletePoint(
    $id: Int!,
    $pointHistoric: point_historic_insert_input!
  ) {
    delete_point_record_by_pk(id: $id) {
      id
    }
    insert_point_historic(objects: [$pointHistoric]) {
      returning {
        id
      }
    }
  }
`;

export const EDIT_POINT = gql`
  mutation editPoint(
    $id: Int!,
    $point: point_record_set_input!,
    $pointHistoric: point_historic_insert_input!
  ) {
    update_point_record_by_pk(pk_columns: { id: $id }, _set: $point){
      id
    }
    insert_point_historic(objects: [$pointHistoric]) {
      returning {
        id
      }
    }
  }
`;

export const ADD_POINT = gql`
  mutation addPoint(
    $point: point_record_insert_input!,
    $pointHistoric: point_historic_insert_input!
  ) {
    insert_point_record(objects: [$point]) {
      returning {
        id
      }
    }
    insert_point_historic(objects: [$pointHistoric]) {
      returning {
        id
      }
    }
  }
`;

export const ADD_PRESET_POINTS = gql`
  mutation addPoint(
    $points: [point_record_insert_input!]!,
  ) {
    insert_point_record(objects: $points) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_POINT_HISTORIC = gql`
  mutation createPointHistoric(
    $pointHistoric: point_historic_insert_input!
  ) {
    insert_point_historic(objects: [$pointHistoric]) {
      returning {
        id
      }
    }
  }
`;

export const LOAD_POINT_HISTORIC = gql`
  subscription loadPointHistoric($convocationGroupId: Int!) {
    point_historic(where: { 
      convocation_group_id: { _eq: $convocationGroupId } 
    }, order_by: {date: desc}) {
      id
      operation
      old_value
      new_value
      note
      date
    }
  }
`;

export const LOAD_POINT_HISTORIC_QUERY = gql`
  query loadPointHistoricQuery($convocationGroupId: Int!) {
    point_historic(where: { 
      convocation_group_id: { _eq: $convocationGroupId } 
    }, order_by: {date: desc}) {
      id
      operation
      old_value
      new_value
      note
      date
    }
  }
`;


