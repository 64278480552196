import { useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ExternalHeader from '../../Components/ExternalHeader';
import { GET_PLAN_BY_ID } from '../../queries/plan';
import PurchaseForm from './components/PurchaseForm';
import PurchaseInfo from './components/PurchaseInfo';

export default function PurchaseSummaryExternal() {

    const { planId } = useParams();
    const [loadingTransaction, setLoadingTransaction] = useState(false);

    const { data, error, loading } = useQuery(GET_PLAN_BY_ID, { variables: { id: planId } });
    if (loading) return `Carregando...`
    if (error) return `Ocorreu um erro: ${JSON.stringify(error)}`

    const isFree = data.plan[0].price == 0

    return (
        <BlockUi tag="div" blocking={loadingTransaction} className="block-overlay-dark"
            loader={<CustomLoader />} style={{ minHeight: "100vh" }} >
            <ExternalHeader/>
            <Container style={{ minHeight: "100vh", marginTop: "100px"}}>
                <Row style={{ minHeight: "100vh" }}>
                <Col md={data.plan[0].price == 0 ? "12" : "7"} className="mt-4 pr-4 pl-4">
                            <PurchaseInfo isFree={isFree} plan={data.plan[0]} setLoading={setLoadingTransaction} />
                        </Col>
                        {
                        !isFree &&
                            <Col className="mt-4 mb-5 pr-4 pl-4  " >
                                <PurchaseForm isFree={data.plan[0].price == 0} planId={planId} setLoading={setLoadingTransaction} />
                            </Col>
                        }
                </Row>

            </Container>
        </BlockUi>
    )

}


function CustomLoader() {

    return (
        <Row>
            <Col>
                <h5 className="text-uppercase font-weight-bold text-white">processando transação</h5>
                <Loader color="#ffffff" active type="ball-pulse" />
            </Col>
        </Row>
    )

}

