import { useQuery, useSubscription } from "@apollo/react-hooks";
import { pdf } from '@react-pdf/renderer';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Ionicon from 'react-ionicons';
import {
    Col, DropdownMenu, DropdownToggle,
    Nav, NavItem, NavLink, Row, Spinner, UncontrolledDropdown
} from 'reactstrap';
import PermissionRouter from '../../../Components/Permission/PermissionRouter';
import PlanRouter from '../../../Components/Permission/PlanRouter';
import { UserInfoContext } from "../../../context/UserInfoProvider";
import VacationCalcReport from '../../../pages/Reports/VacationCalc';
import { GET_EMPLOYEE_VACATION_CALC } from '../../../queries/employee';
import { COUNT_NOTIFICATIONS, LOAD_LAST_SEEN_SUBS } from "../../../queries/notification";
import directRequet from '../../../services/directRequest';
import NavLinkPermissionWrapper from "./NavItemsWrappers/NavLinkPermissionWrapper";
// Dropdown Tabs Content
import ChatExample from './TabsContent/ChatExample';
import SysErrEx from './TabsContent/SystemExample';
import TimelineEx from './TabsContent/TimelineExample';



const onDeniedPropsConst = { showUpgradeButton: true }

function Notifications() {

    const userInfo = useContext(UserInfoContext);
    const [count, setCount] = useState(0);
    const { loading, error, data } = useSubscription(COUNT_NOTIFICATIONS, {
        variables: {
            company_id: userInfo.companyId,
            user_id: userInfo.id
        }
    });

    const { loading: loadingLastSeen, error: errorLastSeen, data: lastSeen } = useSubscription(LOAD_LAST_SEEN_SUBS, {
        variables: {
            user_id: userInfo.id
        }
    });

    useEffect(() => {
        if (data && data.notification && lastSeen && lastSeen.notification_last_seen.length) {
            const lastSeenDateTime = lastSeen.notification_last_seen[0].last_seen;
            const notification = data.notification.filter(n => !compareDates(lastSeenDateTime, n.date));
            setCount(notification.length);
        }
    }, [data, lastSeen]);


    if (loading || loadingLastSeen) return null;
    if (error || errorLastSeen) return `Erro: ${(error || errorLastSeen).message}`;

    function compareDates(date1, date2) {
        return moment(date1) > moment(date2);
    }

    if (count) {
        return (
            <div className="badge badge-pill badge-primary" style={{ position: 'absolute', right: -11, padding: 5 }}>{count}</div>
        );
    } else {
        return <Fragment></Fragment>;
    }
}

const data = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
    { name: 'Page C', uv: 2000, pv: 6800, amt: 2290 },
    { name: 'Page D', uv: 4780, pv: 7908, amt: 2000 },
    { name: 'Page E', uv: 2890, pv: 9800, amt: 2181 },
    { name: 'Page F', uv: 1390, pv: 3800, amt: 1500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
];

const tabsContent = [
    {
        title: 'Messages',
        content: <ChatExample />
    },
    {
        title: 'Events',
        content: <TimelineEx />
    },
    {
        title: 'System Errors',
        content: <SysErrEx />
    },
];

function VacationCalcReportButton(props) {
    const { companyId, company } = useContext(UserInfoContext)
    const [clicked, setClicked] = useState(false)


    async function handleCLick() {
        setClicked(true)
        const { data } = await directRequet({
            query: GET_EMPLOYEE_VACATION_CALC,
            variables: {
                company_id: companyId
            }
        })
        console.log(data)

        const blob = await pdf(<VacationCalcReport data={data.vacation_calc} company={company} />).toBlob()
        const blobUrl = URL.createObjectURL(blob)
        setClicked(false)
        window.open(blobUrl, "_blank")
    }


    return (
        <>
        
        <span onClick={handleCLick}>
            {
                clicked ?
                    <Spinner size='sm' color='primary' />
                    :
                    `Férias Vencidas`
            }
        </span>
        </>
    )

    // return <PDFDownloadLink document={<VacationCalcReport data={data.vacation_calc} />} fileName={'relatório_férias_vencidas.pdf'} ></PDFDownloadLink>

}


function getTabs() {
    return tabsContent.map((tab, index) => ({
        title: tab.title,
        getContent: () => tab.content,
        key: index,
    }));
}

class HeaderDots extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };

    }

    render() {
        return (
            <Fragment>
                <div className="header-dots">
                    {/* <UncontrolledDropdown>
                        <DropdownToggle className="p-0 mr-2" color="link">
                            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                <div className="icon-wrapper-bg bg-primary"/>
                                <Ionicon color="#3f6ad8" fontSize="23px" icon="md-grid"/>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu right className="dropdown-menu-xl rm-pointers">
                            <div className="dropdown-menu-header">
                                <div className="dropdown-menu-header-inner bg-plum-plate">
                                    <div className="menu-header-image"
                                         style={{
                                             backgroundImage: 'url(' + bg4 + ')'
                                         }}
                                    />
                                    <div className="menu-header-content text-white">
                                        <h5 className="menu-header-title">Grid Dashboard</h5>
                                        <h6 className="menu-header-subtitle">Easy grid navigation inside dropdowns</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-menu grid-menu-xl grid-menu-3col">
                                <Row className="no-gutters">
                                    <Col xl="4" sm="6">
                                        <Button
                                            className="btn-icon-vertical btn-square btn-transition"
                                            outline color="link">
                                            <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                            Automation
                                        </Button>
                                    </Col>
                                    <Col xl="4" sm="6">
                                        <Button
                                            className="btn-icon-vertical btn-square btn-transition"
                                            outline color="link">
                                            <i className="pe-7s-piggy icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                            Reports
                                        </Button>
                                    </Col>
                                    <Col xl="4" sm="6">
                                        <Button
                                            className="btn-icon-vertical btn-square btn-transition"
                                            outline color="link">
                                            <i className="pe-7s-config icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                            Settings
                                        </Button>
                                    </Col>
                                    <Col xl="4" sm="6">
                                        <Button
                                            className="btn-icon-vertical btn-square btn-transition"
                                            outline color="link">
                                            <i className="pe-7s-browser icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                            Content
                                        </Button>
                                    </Col>
                                    <Col xl="4" sm="6">
                                        <Button
                                            className="btn-icon-vertical btn-square btn-transition"
                                            outline color="link">
                                            <i className="pe-7s-hourglass icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                            Activity
                                        </Button>
                                    </Col>
                                    <Col xl="4" sm="6">
                                        <Button
                                            className="btn-icon-vertical btn-square btn-transition"
                                            outline color="link">
                                            <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                            Contacts
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav vertical>
                                <NavItem className="nav-item-divider"/>
                                <NavItem className="nav-item-btn text-center">
                                    <Button size="sm" className="btn-shadow" color="primary">
                                        Follow-ups
                                    </Button>
                                </NavItem>
                            </Nav>
                        </DropdownMenu>
                    </UncontrolledDropdown> */}
                    {/* <PermissionRouter permission="report:read" disabled> */}
                        <UncontrolledDropdown>
                            <DropdownToggle className="p-0 mr-2" color="link">
                                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <div className="icon-wrapper-bg bg-secondary" />
                                    <i className="pe-7s-news-paper text-dark"> </i>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-xl rm-pointers">
                                <div className="dropdown-mega-menu">
                                    <Row className="no-gutters">
                                        <Col xl="4" sm="6">
                                            <Nav vertical>
                                                <NavItem className="nav-item-header">
                                                    Relatórios
                                                </NavItem>
                                                <NavItem className="d-flex align-items-center">
                                                    
                                                    <NavLinkPermissionWrapper resource="report:vacation" disableOnDenied onDeniedProps={onDeniedPropsConst} >
                                                    <PermissionRouter permission="report-vacation:print" classDisabled >
                                                        <NavLink href="javascript:void(0);">
                                                        
                                                            <i className="nav-link-icon pe-7s-plane"> </i>
                                                            
                                                            <VacationCalcReportButton />
        
                                                        </NavLink>
                                                        </PermissionRouter>
                                                    </NavLinkPermissionWrapper>
                                                    
                                                </NavItem>

                                                <NavItem className="d-flex align-items-center">
                                                    <NavLinkPermissionWrapper resource="report:convocation" onDeniedProps={onDeniedPropsConst} disableOnDenied >
                                                        <PermissionRouter permission="report-convocation:print" classDisabled>
                                                            <NavLink href="#/relatorio-convocacoes" >
                                                                <i className="nav-link-icon lnr-location"> </i>
                                                                <span>Convocações</span>
                                                            </NavLink>
                                                            </PermissionRouter>
                                                    </NavLinkPermissionWrapper>
                                                </NavItem>

                                                <NavItem className="d-flex align-items-center">
                                                    <NavLinkPermissionWrapper resource="report:frequency" disableOnDenied onDeniedProps={onDeniedPropsConst} >
                                                        <PermissionRouter permission="report-frequency:print" classDisabled>
                                                            <NavLink href="#/relatorio-frequencia">
                                                                <i className="nav-link-icon pe-7s-clock"> </i>
                                                                <span>Frequência</span>
                                                            </NavLink>
                                                        </PermissionRouter>
                                                    </NavLinkPermissionWrapper>
                                                </NavItem>

                                                <NavItem className="d-flex align-items-center">
                                                    <NavLinkPermissionWrapper resource="report:contract" onDeniedProps={onDeniedPropsConst} disableOnDenied >
                                                        <PermissionRouter permission="report-contracts:print" classDisabled>
                                                            <NavLink href="#/relatorio-lista-contratos">
                                                                <i className="nav-link-icon pe-7s-box1"> </i>
                                                                <span>Contratos</span>
                                                            </NavLink>
                                                        </PermissionRouter>
                                                    </NavLinkPermissionWrapper>
                                                </NavItem>

                                                {/* <NavItem className="d-flex align-items-center">
                                                    <NavLinkPermissionWrapper resource="report:contract_historic" onDeniedProps={onDeniedPropsConst} disableOnDenied>

                                                        <NavLink href="#/relatorio-historico-contrato">
                                                            <i className="nav-link-icon lnr-history"> </i>
                                                            <span>Histórico de Contrato</span>
                                                        </NavLink>
                                                    </NavLinkPermissionWrapper>
                                                </NavItem> */}

                                            </Nav>
                                        </Col>
                                    </Row>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    {/* </PermissionRouter> */}

                    <UncontrolledDropdown>
                        <DropdownToggle className="p-0 mr-2" color="link" href="#/notificacoes">
                            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                <div className="icon-wrapper-bg bg-info" />
                                <Ionicon beat={false} color="#30b1ff" fontSize="23px" icon="md-notifications-outline" />
                                <Notifications />
                            </div>
                        </DropdownToggle>
                    </UncontrolledDropdown>
                    {
                        /*
                         <UncontrolledDropdown>
                        <DropdownToggle className="p-0 mr-2" color="link">
                            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                <div className="icon-wrapper-bg bg-focus"/>
                                <div className="language-icon">
                                    <Flag className="mr-3 opacity-8" country="DE" size="40"/>
                                </div>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu right className="rm-pointers">
                            <div className="dropdown-menu-header">
                                <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                                    <div className="menu-header-image opacity-05"
                                         style={{
                                             backgroundImage: 'url(' + city2 + ')'
                                         }}
                                    />
                                    <div className="menu-header-content text-center text-white">
                                        <h6 className="menu-header-subtitle mt-0">Choose Language</h6>
                                    </div>
                                </div>
                            </div>
                            <DropdownItem header>Popular Languages</DropdownItem>
                            <DropdownItem>
                                <Flag className="mr-3 opacity-8" country="US"/>
                                USA
                            </DropdownItem>
                            <DropdownItem>
                                <Flag className="mr-3 opacity-8" country="CH"/>
                                Switzerland
                            </DropdownItem>
                            <DropdownItem>
                                <Flag className="mr-3 opacity-8" country="FR"/>
                                France
                            </DropdownItem>
                            <DropdownItem>
                                <Flag className="mr-3 opacity-8" country="ES"/>
                                Spain
                            </DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem header>Others</DropdownItem>
                            <DropdownItem active>
                                <Flag className="mr-3 opacity-8" country="DE"/>
                                Germany
                            </DropdownItem>
                            <DropdownItem>
                                <Flag className="mr-3 opacity-8" country="IT"/>
                                Italy
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                        <UncontrolledDropdown>
                            <DropdownToggle className="p-0" color="link">
                                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <div className="icon-wrapper-bg bg-success"/>
                                    <Ionicon color="#3ac47d" fontSize="23px" icon="ios-analytics"/>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-xl rm-pointers">
                                <div className="dropdown-menu-header">
                                    <div className="dropdown-menu-header-inner bg-premium-dark">
                                        <div className="menu-header-image"
                                            style={{
                                                backgroundImage: 'url(' + bg4 + ')'
                                            }}
                                        />
                                        <div className="menu-header-content text-white">
                                            <h5 className="menu-header-title">Users Online</h5>
                                            <h6 className="menu-header-subtitle">Recent Account Activity Overview</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-chart">
                                    <div className="widget-chart-content">
                                        <div className="icon-wrapper rounded-circle">
                                            <div className="icon-wrapper-bg opacity-9 bg-focus"/>
                                            <i className="lnr-users text-white"/>
                                        </div>
                                        <div className="widget-numbers">
                                            <CountUp start={0}
                                                    end={344}
                                                    separator=""
                                                    decimals={0}
                                                    decimal=","
                                                    prefix=""
                                                    useEasing={false}
                                                    suffix="k"
                                                    duration="15"/>
                                        </div>
                                        <div className="widget-subheading pt-2">
                                            Profile views since last login
                                        </div>
                                        <div className="widget-description text-danger">
                                            <span className="pr-1">
                                                <CountUp start={0}
                                                        end={176}
                                                        separator=","
                                                        delay={2}
                                                        decimals={0}
                                                        decimal=","
                                                        useEasing={false}
                                                        prefix=""
                                                        suffix="%"
                                                        duration="10"/>
                                            </span>
                                            <FontAwesomeIcon icon={faArrowLeft}/>
                                        </div>
                                    </div>
                                    <div className="widget-chart-wrapper">
                                        <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                            <AreaChart data={data}
                                                    margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                                <Area type='monotoneX' dataKey='uv' stroke='var(--warning)' fill='var(--warning)' fillOpacity='.5'/>
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                                <Nav vertical>
                                    <NavItem className="nav-item-divider mt-0"> </NavItem>
                                    <NavItem className="nav-item-btn text-center">
                                        <Button size="sm" className="btn-shine btn-wide btn-pill" color="warning">
                                            <FontAwesomeIcon className="mr-2"
                                                            icon={faCog}
                                                            spin
                                                            fixedWidth={false}
                                            />
                                            Refresh List
                                        </Button>
                                    </NavItem>
                                </Nav>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        */
                    }
                </div>
            </Fragment>
        )
    }
}

export default HeaderDots;




