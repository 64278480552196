export const RESET_HOLIDAYS = 'RESET_HOLIDAYS';
export const SET_HOLIDAYS = 'SET_HOLIDAYS';
export const SET_COST = 'SET_COST';


export const setHolidays = (holidays) => ({
    type: SET_HOLIDAYS,
    holidays
});

export const setCost = (cost) => ({
    type: SET_COST,
    cost
});


export const resetHolidays = () => ({
    type: RESET_HOLIDAYS
});