import React from 'react';
import { useParams } from 'react-router';
import { ENVIRONMENT } from '../../config/config';

export default function ErrorPage() {
    const { type, message } = useParams();


    // URL_HASURA.search("hasura.dev") > -1 ? "DEVELOPMENT" : "PRODUCTION";

    if(ENVIRONMENT === "DEVELOPMENT") return <DevelopmentErrorPage type={type} message={message}/>


    return <ProductionErrorPage type={type} message={message}/> 

}


function DevelopmentErrorPage({ type, message }) {
    return (
        <section className="p-3">
            <h2 className="text-danger">{type}</h2>
            <h5>{message}</h5>
        </section>
    );
}


function ProductionErrorPage() {
    return <section>
        <h1>Ocorreu um erro, se vira!</h1>
    </section>
}

