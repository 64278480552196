import { useMutation, useSubscription } from '@apollo/client'
import React, { createContext, useContext, useEffect } from 'react'
import { 
        GET_POINT_REGISTER_VALUE,
        UPSERT_PARAMETERS_VALUE
      } from '../queries/geral_parameters'
import { UserInfoContext } from './UserInfoProvider';

export const ParametersContext = createContext()

export default function ParametersProvider({ children }) {

  const userInfo = useContext(UserInfoContext);
  const [insertDefaultParameters] = useMutation(UPSERT_PARAMETERS_VALUE);

  const { loading, error, data } = useSubscription(GET_POINT_REGISTER_VALUE, {
    variables: {
      point_register: "point_register",
      convocation_expires_type: "convocation_expires_type",
      company_id: userInfo.companyId
    },
  }); 
  
  console.log("userInfo.companyId: " + userInfo.companyId)
  useEffect(() => {    
    if (data && data.parameter && data.parameter.length === 0)  {
      insertDefaultParameters({
        variables: { 
          company_id: userInfo.companyId,
          parameter: "point_register",
          value: true          
        }
      });

      insertDefaultParameters({
        variables: { 
          company_id: userInfo.companyId,
          parameter: "convocation_expires_type",
          value: "default"
        }
      });
    }
  }, [data, insertDefaultParameters, userInfo.companyId]);

  if (error) return `Erro: ${error.message}`;
  if (loading) return "Carregando...";
 

	let parametersOrdered = {}

	if (data) {
    const parameters = {
      parameterData: data.parameter
    }
		parametersOrdered = parameters
	}

	return <ParametersContext.Provider value={parametersOrdered}>{children}</ParametersContext.Provider>
}