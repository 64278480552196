
import directRequest from "../../../services/directRequest";
import { VALIDATE_POINT_REGISTER_BY_CONTRACT } from '../../../queries/contract';
import { VALIDATE_POINT_REGISTER_BY_CONVOCATION } from '../../../queries/convocation';
import { LOAD_SERVER_TIME_LOCALE_EN } from "../../../queries/others";

export const validatePointByContract = ({ user_id, company_id }) => {
    return new Promise((resolve, reject) => {
        directRequest({
            query: LOAD_SERVER_TIME_LOCALE_EN
        }).then(now => {
            const date = now.data.time.date;
            directRequest({
                query: VALIDATE_POINT_REGISTER_BY_CONTRACT,
                variables: {
                    user_id,
                    company_id,
                    currentDate: date
                }
            }).then(response => {
                if(response.errors && response.erros.length) throw(response);
                resolve(response);
            })
                .catch(e => reject("Erro ao validar o ponto pelo contrato"));
        }).catch(e => reject("Erro ao carregar horário do servidor"));
    });
}

export const validatePointByConvocation = ({ user_id, company_id }) => {
    return new Promise((resolve, reject) => {
        directRequest({
            query: LOAD_SERVER_TIME_LOCALE_EN
        }).then(now => {
            const date_time = now.data.time.date;
            directRequest({
                query: VALIDATE_POINT_REGISTER_BY_CONVOCATION,
                variables: {
                    user_id,
                    company_id,
                    date: date_time
                }
            }).then(response => {
                if(response.errors && response.erros.length) throw(response);
                resolve(response);
            })
            .catch(e => reject("Erro validar o ponto pela convocação"));
        }).catch(e => reject("Erro ao carregar horário do servidor"));
    });
}