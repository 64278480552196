
import {ADD_EMPLOYEE, ADD_WORKDAY} from '../../actions/workday/workdayEmployeeAction'

const INITIAL_STATE = {
    workdays: [],
    employees: []
};

function workdayEmployee(state = INITIAL_STATE, action){

    switch (action.type) {
        case ADD_EMPLOYEE:{
            return{
                ...state,
                employees: [...state.employees, action.employee]
            }
        }

        case ADD_WORKDAY: {
            return{
                ...state,
                workdays:  [...state.workdays, action.workday]
            }
        }
    
        default:{
            return state
        }
    }
}

export default workdayEmployee
