import { fileUploadService } from '../services/fileUpload'
import { URL_SITE } from '../config/config'

const DEFAULT_AVATAR = `/user_company_profile.jpg`
const DEFAULT_LOGO   = `${URL_SITE}/avatars/company_profile.png` 

export function getImage(type, key) {
    
    if (key) {
        return fileUploadService.unauthenticatedDownload(key)
    }

    switch (type) {
        case 'logo':
            return DEFAULT_LOGO
    
        case 'avatar': 
            return DEFAULT_AVATAR
    }

}

export function getAuthImage(type, key) {

    if (key) {
        return fileUploadService.authenticatedDownload(key)
    }

    switch (type) {
        case 'logo':
            return DEFAULT_LOGO
    
        case 'avatar': 
            return DEFAULT_AVATAR
    }

}