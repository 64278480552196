

export function groupResources(plans, system_resources){
    let formattedResources = new Set();

    for(let plan of plans){
        for(let system_resource of system_resources){
            let sameSystemResourse = plan.plan_resources.some(plan_resource => plan_resource.system_resource.id === system_resource.id && plan_resource.visible) 
            if(sameSystemResourse) formattedResources.add(system_resource)
        }
    }

    return Array.from(formattedResources)
}