
import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { ReportTemplate } from '../../util/reportGeneratorUtil'


const tableStyles = StyleSheet.create({

    table: {
        flexDirection: 'column',
        fontSize: 11,
        marginTop: 10
    },

    tableHeader: {
        flexDirection: 'row',
        borderBottom: '1 solid gray',
        fontFamily: 'Helvetica-Bold'
    },
    tableHeaderCell:{
        padding: '7 10 5 10',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1 solid gray',
    },
    tableContentCell: {
        padding: '7 10 5 10',
    },

});

const infoBoxStyles = StyleSheet.create({
    infoBox: {
        border: '1 solid gray',
        borderRadius: 4,
        padding: 10,
        fontSize: 11,
        flexDirection: 'row',
    },

    infoLeft: {
        flexGrow: 1,
    },

    infoRight: {
        flexGrow: 1,
    },
});


export default function VacationCalcReport({ data, company }) {

    return (
        <ReportTemplate title='Relatório - Férias Vencidas'>
            <View style={infoBoxStyles.infoBox}>
                <View style={infoBoxStyles.infoLeft}>
                    <Text>{company.fantasy_name}</Text>
                    <Text>{company.business_name}</Text>
                    <Text>CNPJ: {company.cnpj}</Text>
                </View>
            </View>

            <View style={tableStyles.table} >
                <View style={tableStyles.tableHeader} >
                    <View style={{...tableStyles.tableHeaderCell, width: 250}}>
                        <Text>Nome</Text>
                    </View>
                    <View  style={{...tableStyles.tableHeaderCell, width: 150}}>
                        <Text> CPF</Text>
                    </View>
                    <View  style={{...tableStyles.tableHeaderCell, border: 'none'}}>
                        <Text>Férias Vencidas</Text>
                    </View>
                </View>

                <View>
                    {data.map(vc =>
                        <View style={tableStyles.tableRow}>
                            <View style={{...tableStyles.tableContentCell, width: 250}}>
                                <Text>{vc.employee.user.name}</Text>
                            </View>
                            <View style={{...tableStyles.tableContentCell, width: 150}}>
                                <Text> {vc.employee.user.cpf_cnpj}</Text>
                            </View>
                            <View style={{...tableStyles.tableContentCell, border: 'none'}}>
                                <Text>{vc.vacation_days_available}</Text>
                            </View>
                        </View>
                    )}
                </View>
            </View>

        </ReportTemplate>
    )
}
