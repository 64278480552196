
export const SET_CONTRACT_INFO = "SET_CONTRACT_INFO";


export const setContractInfo = ( contractID ) =>({
    type: SET_CONTRACT_INFO,
    contractID
}); 


// export const SHOW_CONTRACT_INFO_TAB = "SHOW_CONTRACT_INFO_TAB";
// export const LOAD_CONTRACT_INFO = "LOAD_CONTRACT_INFO";
// export const SET_END_DATE = "SET_END_DATE";

// export const showContractInfoTab = (selectedEmployee) => ({
//     type: SHOW_CONTRACT_INFO_TAB,
//     selectedEmployee
// });

// export const loadContractInfo = (selectedEmployee) => ({
//     type: LOAD_CONTRACT_INFO,
//     selectedEmployee
// });

// export const setEndDate = (endDate) => ({
//     type: SET_END_DATE,
//     endDate
// });