import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import UserInfoProvider, { UserInfoContext } from '../../context/UserInfoProvider';
import { authService } from '../../services/auth';
import { getImage } from '../../util/downloadImageUtil';



export default function ExternalHeader() {

    const userInfo = useContext(UserInfoContext);
    const userName = userInfo.name ? userInfo.name.split(" ")[0] : "";

    const onLogout = () => {
        authService.logout();
    }

    return (
        <header style={{
            width: '100vw',
            backgroundColor: "#fafbfc",
            position: 'fixed',
            top: "0",
            zIndex: "1000",
            boxShadow: "0 .46875rem 2.1875rem rgba(0,0,0,.03),0 .9375rem 1.40625rem rgba(0,0,0,.03),0 .25rem .53125rem rgba(0,0,0,.05),0 .125rem .1875rem rgba(0,0,0,.03)",
            display: "flex",
            justifyContent: "center"
        }}>
            <div style={{
                width: "95%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}
                className="py-2"
            >

                <img style={{ maxHeight: "50px" }} src={getImage('logo', userInfo.company.logomarca)} />

                <div className="widget-content p-0">


                    <div className="widget-content-wrapper">
                        <div className="widget-content-right  mr-3 header-user-info">
                            <div className="widget-heading">
                                {userName}
                            </div>
                        </div>
                        <div className="widget-content-left">
                            <UserInfoProvider>
                                <img width={42} height={42} className="rounded-circle" src={getImage('avatar', userInfo.avatar)} alt="" />
                            </UserInfoProvider>
                        </div>
                        <div className="widget-content-left ml-3">
                            <Button onClick={onLogout} color="primary">Sair</Button>
                        </div>

                    </div>
                </div>
            </div>
        </header>
    );
}