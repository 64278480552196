
import gql from "graphql-tag";

export const GET_ALL_PLAN_COMPANIES = gql`
subscription getAllPlanCompanies{
  plan_company{
    id
    validity_end
    type
    price_description
    price
    paid
    name
    month_count
    contract_count
    contracted_contract_count
    contracted_convocation_count
    created_date
    description
    company{
      id
      business_name
      fantasy_name
      cnpj
    }
    plan_id
    type
  }
}
`

export const GET_COMPANY_FINANCIAL_INFO = gql`
  query getCompanyFinancialInfo($companyId: Int!, $now: date!){
    system_resource ( order_by: { order: asc } ){
      id
      name
      description
      helper
    }
    actual_plan: plan_company(
      where: {
        _and: [
          { company_id: { _eq: $companyId } },
          { type: { _eq: CONTRACTED } },
          { validity_end: { _gte: $now } }
        ]
      }
      order_by: {
        created_date: desc
      }
      limit: 1
    ){
      id
      paid
      contract_count
      convocation_count
      validity_end
      created_date
      price
      name
      month_count
      description
      price_description
      period_description
      plan{
        id
        plan_resources{
          id
          visible
          system_resource{
            description
            id
            name
            order
          }
        }
      }
      company{
        id
        automatic_renovation
      }
    }
    all_plans: plan_company(
      where: {
      _and: [
        { validity_end: { _gte: $now } },
        { company_id: { _eq: $companyId } },
        { convocation_count: { _gt: 0 } }
      ]
    }
    order_by: {
      validity_end: asc
    }
    ){
      id
      paid
      company{
        id
        automatic_renovation
      }
      contract_count
      convocation_count
      validity_end
      created_date
      price
      name
      month_count
      description
      price_description
    }
  }
 `;


export const UPDATE_AUTOMATIC_RENOVATION = gql`
mutation updateAutomaticRenovation($companyId: Int!, $automaticRenovation: Boolean!){
    update_company(
      where:{id: {_eq: $companyId}},
      _set: {
        automatic_renovation: $automaticRenovation
      }
    ){
      affected_rows
    }
  }
`



export const GET_FINANCIAL_TIMELINE_INFO = gql`
subscription getFinancialTimelineInfo($companyId: Int!){
  plan_company(where: {company_id: {_eq: $companyId}}){
    id
    company{
      id
      automatic_renovation
    }
    invoices{
      id
      month_competence
      year_competence
      file_upload{
        id
        extension
        mimetype
        name
        path
        size
      }
    }
    company_id
    contract_count
    convocation_count
    contracted_contract_count
    contracted_convocation_count
    created_date
    validity_end
    price
    price_description
    type
    name
    description
    
  }
}
`