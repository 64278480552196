export const SET_PLACE = 'SET_PLACE';
export const SET_POINT_REGISTER_RADIUS = 'SET_POINT_REGISTER_RADIUS'; 
export const SET_EXTRA_POINT_WORKPLACES = 'SET_EXTRA_POINT_WORKPLACES'; 

export const setPlace = (place) => ({
    type: SET_PLACE,
    place
});


export const setPointRegisterRadius = (radius) => ({
    type: SET_POINT_REGISTER_RADIUS,
    radius
});

export const setExtraPointWorkplaces = (extraPointWorkplaces) => ({
    type: SET_EXTRA_POINT_WORKPLACES,
    extraPointWorkplaces
});