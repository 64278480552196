import { authService } from "./auth";
import { print } from "graphql/language/printer";
import { URL_HASURA } from "../config/config";

export default function directRequet({query, variables}) {

    return fetch(URL_HASURA, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${authService.currentTokenValue}`,
            /*"x-hasura-admin-secret": "Sym33@serv"*/
        },
        body: JSON.stringify({
            query: print(query),
            variables
        })
    })
        .then(res => res.json())
        .catch(err=>{
            console.log(err)
        })

}