export const ADD_WORKDAY = 'ADD_WORKDAY'
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'

export const addWorkday = (workday) =>({
    type: ADD_WORKDAY,
    workday
})

export const addEmployee = (employee) =>({
    type: ADD_EMPLOYEE,
    employee
})
