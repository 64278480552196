import { SET_EXTRA_POINT_WORKPLACES, SET_PLACE, SET_POINT_REGISTER_RADIUS } from "../../actions/convocation/placeAction";
import { RESET_CONVOCATION } from "../../actions/convocation/convocationAction";
import { DEFAULT_POINT_REGISTER_RADIUS } from "../../config/contants";


const INITIAL_STATE = {
    selectedAddress: null,
    pointRegisterRadius: DEFAULT_POINT_REGISTER_RADIUS,
    extraPointWorkplaces: []
};

function placeReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case RESET_CONVOCATION: {
            return INITIAL_STATE;
        }

        case SET_PLACE: {
            return {
                ...state,
                selectedAddress: action.place
            };
        }

        case SET_POINT_REGISTER_RADIUS: {
            return {
                ...state,
                pointRegisterRadius: action.radius
            };
        }


        case SET_EXTRA_POINT_WORKPLACES: {
            return {
                ...state,
                extraPointWorkplaces: action.extraPointWorkplaces
            };
        }

        default:
            return state;
    }
};

export default placeReducer;