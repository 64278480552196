import React, { createContext, useState } from 'react'
import { authService } from '../services/auth';
import { useSubscription } from '@apollo/react-hooks';
import { LOAD_USER } from '../queries/user';
import moment from 'moment';


export const UserInfoContext = createContext();

const now = moment().format("YYYY-MM-DD");

export default function UserInfoProvider({ children }) {

  const [userImage, setUserImage] = useState()
  const { loading, error, data } = useSubscription(LOAD_USER, {
    variables: { login: authService.username, now }
  });

  if (loading) return 'Carregando...';
  if (error) return `Erro: ${error.message}`;


  
  let user = {
    id: data.user[0].id,
    avatar: userImage ? userImage : data.user[0].avatar,
    cpfCnpj: data.user[0].cpf_cnpj,
    login: data.user[0].login,
    name: data.user[0].name,
    userCompanyId: data.user[0].user_companies[0].id,
    companyId: data.user[0].user_companies[0].id_company,
    company: data.user[0].user_companies[0].company,
    permissions: data.user[0].user_permissions,
    temp_password: data.user[0].temp_password,
    hasPlan: data.user[0].user_companies[0].company.actual_plan.length > 0 ? true : false,
    company_resources: data.user[0].user_companies[0].company.plan_company_resources,
    last_plan: data.user[0].user_companies[0].company.last_plan,
    selected_site_plan_id: data.user[0].user_companies[0].company.selected_site_plan_id,
    convocation_count: 0,
    setUserImage
  };

  if (user.hasPlan) {
    user.actual_plan = data.user[0].user_companies[0].company.actual_plan[0];
    //user.contract_count = data.user[0].user_companies[0].company.actual_plan[0].contract_count;
    //user.contracted_contract_count = data.user[0].user_companies[0].company.actual_plan[0].contracted_contract_count;
    user.convocation_count = data.user[0].user_companies[0].company.plan_convocations_sum.aggregate.sum.convocation_count;
  }

  // if (user.last_plan.length > 0){
  //   user.contract_count = data.user[0].user_companies[0].company.last_plan[0].contract_count;
  //   user.contracted_contract_count = data.user[0].user_companies[0].company.last_plan[0].contracted_contract_count;
  // }

  return (
    <UserInfoContext.Provider value={user}>{children}</UserInfoContext.Provider>
  );

}