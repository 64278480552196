export const EmployeesNav = [
    {
        icon: 'pe-7s-home',
        label: 'Dashboard',
        to: '#/dashboard',
    },
    {
        icon: 'pe-7s-id',
        label: 'Contratos',
        to: '#/contratos',
    },
    {
        icon: 'pe-7s-speaker',
        label: 'Convocações',
        to: '#/convocacoes',
    },
    {
        icon: 'pe-7s-clock',
        label: 'Jornadas',
        to: '#/jornadas',
    }
];

export const MainNav = [];
